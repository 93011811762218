import React from "react";
import { FormMessage } from "@/components/ui/form";

import { FormControl } from "@/components/ui/form";

import { FormLabel } from "@/components/ui/form";

import { FormItem } from "@/components/ui/form";

import { FormField } from "@/components/ui/form";

import { Form } from "@/components/ui/form";

import { zodResolver } from "@hookform/resolvers/zod";

import { useForm } from "react-hook-form";

import {
  agent_form_schema_page_2,
  agent_form_schema_page_2_type,
} from "../_schema";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";

export const AgentSecondFormPage = ({
  handleSubmit,
}: {
  handleSubmit: (values: agent_form_schema_page_2_type) => void;
}) => {
  const form = useForm<agent_form_schema_page_2_type>({
    resolver: zodResolver(agent_form_schema_page_2),
    defaultValues: {
      nin: "",
      association: "",
    },
  });
  return (
    <Form {...form}>
      <form
        className="flex flex-col gap-4"
        onSubmit={form.handleSubmit(handleSubmit)}
      >
        <FormField
          control={form.control}
          name="association"
          render={({ field }) => (
            <FormItem className="w-full flex flex-col gap-2">
              <FormLabel>
                Association
              </FormLabel>
              <FormControl>
                <Input type="text" placeholder="Enter association" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="nin"
          render={({ field }) => (
            <FormItem className="w-full flex flex-col gap-2">
              <FormLabel>
                NIN <span className="text-destructive">*</span>
              </FormLabel>
              <FormControl>
                <Input type="number" placeholder="Enter NIN" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button
          className="w-full max-w-[164px]"
          disabled={!form.formState.isValid}
        >
          Next
        </Button>
      </form>
    </Form>
  );
};
