import React from "react";
import { Button } from "./button";
import { cn } from "@/lib/utils";

const CSVExport = ({
  data,
  fileName,
  disabled,
}: {
  data: { [key: string]: any }[];
  fileName: string;
  disabled?: boolean;
}) => {
  const convertToCSV = (array: { [key: string]: any }[]) => {
    if (array.length === 0) return "";

    // Extract headers from the first object
    const headers = Object.keys(array[0]);
    const csvRows = [headers.join(",")]; // Header row

    // Loop through each row of data
    array.forEach((row) => {
      const values = headers.map((header) => {
        const escaped = (row[header] || "").toString().replace(/"/g, '""');
        return `"${escaped}"`; // Escape double quotes
      });
      csvRows.push(values.join(","));
    });

    return csvRows.join("\r\n");
  };

  const downloadCSV = () => {
    const csvData = new Blob([convertToCSV(data)], { type: "text/csv" });
    const csvURL = URL.createObjectURL(csvData);
    const link = document.createElement("a");
    link.href = csvURL;
    link.download = `${fileName}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Button
      className={cn(
        "bg-transparent text-primary-700 border hover:text-primary-foreground flex gap-1 items-center"
      )}
      onClick={!disabled ? downloadCSV : () => {}}
      disabled={disabled}
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.66699 14.1667L10.0003 17.5M10.0003 17.5L13.3337 14.1667M10.0003 17.5V10M16.667 13.9524C17.6849 13.1117 18.3337 11.8399 18.3337 10.4167C18.3337 7.88536 16.2816 5.83333 13.7503 5.83333C13.5682 5.83333 13.3979 5.73833 13.3054 5.58145C12.2187 3.73736 10.2124 2.5 7.91699 2.5C4.46521 2.5 1.66699 5.29822 1.66699 8.75C1.66699 10.4718 2.3632 12.0309 3.48945 13.1613"
          stroke="currentColor"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      Export
    </Button>
  );
};

export default CSVExport;
