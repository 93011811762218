import RolesPermissionsTable from "@/components/tables/rolespermissionstable";
import { useStore } from "@/contexts/userStore";
import React from "react";

export default function RolesPermissions() {
  const SECTION_NAME = "Admin Panel";
  const MODULE_NAME = "Platform Administration";

  const user = useStore((state) => state.user);
  const permission_data = user.admin_role?.find(
    (role) => role.name === SECTION_NAME
  );

  const module_perm = permission_data?.permissions.find(
    (perm) => perm.module == MODULE_NAME
  );

  console.log(permission_data);

  // if (
  //   !permission_data ||
  //   !permission_data.isAllowed ||
  //   !module_perm?.isAllowed
  // ) {
  //   return (
  //     <div>
  //       <h1 className="text-2xl font-bold">
  //         You do not have access to this page
  //       </h1>
  //     </div>
  //   );
  // }
  return (
    <div>
      <RolesPermissionsTable />
    </div>
  );
}
