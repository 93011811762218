"use client";

import * as React from "react";
import { Check, ChevronsUpDown } from "lucide-react";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";

export function Combobox({
  values,
  onChange,
  disabled,
  children,
  value
}: {
  values?: { label: string; value: string }[];
  onChange?: (value: string) => void;
  disabled?: boolean;
  children?: React.ReactNode;
  value:string
}) {
  const [open, setOpen] = React.useState(false);
  const parentRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Check if the clicked element is outside the parent container
      if (
        parentRef.current &&
        !parentRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    };

    // Attach the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const activeValue = values?.find(
    (framework) => framework.value === value
  )?.label;

  return (
    <div className="" ref={parentRef}>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger
          disabled={disabled}
          className="disabled:cursor-not-allowed"
          asChild
        >
          <Button
            variant="outline"
            role="combobox"
            disabled={disabled}
            aria-expanded={open}
            className={cn(
              "w-full justify-between disabled:cursor-not-allowed",
              disabled && "cursor-not-allowed"
            )}
          >
            {value ? activeValue || "Select Entry..." : "Select Entry..."}
            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        {open && (
          <div className="relative z-[999999999999]">
            <div className="w-full  border rounded-lg z-[999999999999999999999] absolute top-2 p-0">
              <Command className="w-full relative z-[999999999999999999999999999999999999]">
                <CommandInput placeholder="Search framework..." />
                <CommandList className="relative bg-transparent w-full z-[999999999999999999999]">
                  <CommandEmpty>No entries found.</CommandEmpty>
                  <CommandGroup>
                    {values?.map((framework) => (
                      <CommandItem
                        key={framework.value}
                        value={framework.value}
                        onSelect={(currentValue) => {
                          onChange?.(currentValue);
                        //   setValue(currentValue === value ? "" : currentValue);
                          setOpen(false);
                        }}
                      >
                        <Check
                          className={cn(
                            "mr-2 h-4 w-4",
                            value === framework.value
                              ? "opacity-100"
                              : "opacity-0"
                          )}
                        />
                        {framework.label}
                      </CommandItem>
                    ))}
                  </CommandGroup>
                </CommandList>
              </Command>
              {/* <div className="">kk</div> */}
              {children}
            </div>
          </div>
        )}
      </Popover>
    </div>
  );
}
