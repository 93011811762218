import "./index.css";
import React from "react";
import "aos/dist/aos.css";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import { DashboardLayout } from "./components/layouts/DashboardLayout";
import { PublicLayout } from "./components/layouts/PublicLayout";
import Dashboard from "./pages/dashboard/home";
import SignUp from "./pages/auth/signup";
import SignIn from "./pages/auth/signin";
import { AuthLayout } from "./components/layouts/AuthLayout";
import ProfilePage from "./pages/dashboard/profile";
import ClientsPage from "./pages/dashboard/clients";
import AddClientsPage from "./pages/dashboard/clients/addClients";
import ClientsLayout from "./components/layouts/DashboardLayout/ClientsLayout";
import { Toaster } from "sonner";
import ApplicationsPage from "./pages/dashboard/applications";
import ApplicationsLayout from "./components/layouts/DashboardLayout/ApplicationsLayout";
import WaiversPage from "./pages/dashboard/waivers";
import WaiverApplicationPage from "./pages/dashboard/waivers/apply";
import PaymentsPage from "./pages/dashboard/payments";
import CertificatesPage from "./pages/dashboard/certificates";
import AdminLayout from "./components/layouts/AdminLayout";
import DetailsPage from "./pages/admin/profile-management/registrationsPage/detailsPage";
import VerifyCertificatePage from "./pages/verify-certificate";
import UserProfilingDetailsPage from "./pages/admin/profile-management/userProfilingPage/detailsPage";
import PaymentDetailsPage from "./pages/admin/profile-management/paymentPage/detailsPage";
import WaiverDetailsPage from "./pages/admin/accounting/waiver/detailsPage";
import GenerateWaiverPage from "./pages/admin/accounting/waiver/generateWaiver";
import GeneratedWaiverDetailsPage from "./pages/admin/accounting/waiver/generatedWaiverDetailsPage";
import GenerateInvoicePage from "./pages/admin/accounting/invoices/generateInvoicePage";
import InvoiceDetailsPage from "./pages/admin/accounting/invoices/detailsPage";
import GeneralPaymentDetailsPage from "./pages/admin/accounting/payments/detailsPage";
import ClearanceApplicationDetailsPage from "./pages/admin/clearance/clearanceApplicationPage/detailsPage";
import CertificatesDetailsPage from "./pages/admin/clearance/certificatesPage/detailsPage";
import FalsifiedRecordsDetailsPage from "./pages/admin/legal/falsifiedRecordsPage/detailsPage";
import { ProtectedLayout } from "./components/layouts/ProtectedLayout";
import SupportPage from "./pages/dashboard/support";
import SupportChatPage from "./pages/dashboard/support/chat";
import RootLayout from "./components/layouts/RootLayout";
import AddApplicationPage from "./pages/dashboard/applications/add-application-page";
import ImportClearanceDetailsPage from "./pages/dashboard/applications/import-clearance-details-page";
import AgentDetailsPage from "./pages/admin/profile-management/registrationsPage/agent-details-page";
import ApplyPage from "./pages/dashboard/applications/apply-page";
import AddItemPricingPage from "./pages/admin/clearance/pricing/add-item-page";
import SignInAdmin from "./pages/auth/signin-admin";
import SignInSuperAdmin from "./pages/auth/signin-super-admin";
import { DashboardNavigationRoutes } from "./lib/admin-permissions";
import ForgotPasswordPage from "./pages/auth/forgotPasswordUser";
import ForgotPasswordAdminPage from "./pages/auth/forgotPasswordAdmin";
import AdminSupportChatPage from "./pages/admin/support/chat";

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<RootLayout />}>
          <Route path="/" element={<PublicLayout />}>
            <Route index element={<Home />} />
            <Route
              path="verify-certificate"
              element={<VerifyCertificatePage />}
            />
            <Route element={<AuthLayout />}>
              <Route path="user/sign-up" element={<SignUp />} />
              <Route path="user/sign-in" element={<SignIn />} />
              <Route path="admin/sign-in" element={<SignInAdmin />} />
              <Route
                path="user/forgot-password"
                element={<ForgotPasswordPage />}
              />
              <Route
                path="admin/forgot-password"
                element={<ForgotPasswordAdminPage />}
              />
              <Route
                path="super-admin/sign-in"
                element={<SignInSuperAdmin />}
              />
            </Route>
          </Route>

          {/* Protected Routes */}
          <Route path="/" element={<ProtectedLayout />}>
            {/* Dashboard Routes */}
            <Route path="user" element={<DashboardLayout />}>
              <Route index element={<Dashboard />} />
              <Route path="profile" element={<ProfilePage />} />

              <Route path="application" element={<ApplicationsLayout />}>
                <Route index element={<ApplicationsPage />} />
                <Route
                  path="add-application"
                  element={<AddApplicationPage />}
                />
                <Route path="apply" element={<ApplyPage />} />

                <Route
                  path="import-clearance"
                  element={<ImportClearanceDetailsPage />}
                />
              </Route>

              <Route path="support" element={<SupportPage />} />
              <Route path="support/chat" element={<SupportChatPage />} />

              <Route path="clients" element={<ClientsLayout />}>
                <Route index element={<ClientsPage />} />
                <Route path="add-clients" element={<AddClientsPage />} />
              </Route>

              <Route path="waivers" element={<WaiversPage />} />
              <Route path="waivers/apply" element={<WaiverApplicationPage />} />

              <Route path="payments" element={<PaymentsPage />} />

              <Route path="certificates" element={<CertificatesPage />} />
            </Route>

            {/* Admin Routes */}
            <Route path="admin" element={<AdminLayout />}>
              {DashboardNavigationRoutes.map(({ Page, ...route }) => {
                let isDropdown = route.dropdownItems;
                let processedHref = route.href.split("/")[1];
                return !isDropdown ? (
                  <Route
                    key={`main${processedHref}`}
                    path={processedHref}
                    element={<Page />}
                  />
                ) : (
                  <Route
                    key={`mainDropdown${processedHref}`}
                    path={processedHref}
                  >
                    <Route index element={<Page />} />
                    {route.dropdownItems?.map(({ Page, ...item }) => {
                      let processedDropdownHref = item.href.split("/")[1];
                      return (
                        <Route
                          key={`mainDropdownItem${processedDropdownHref}`}
                          path={processedDropdownHref}
                          element={<Page />}
                        />
                      );
                    })}
                  </Route>
                );
              })}

              <Route
                path="clearance/pricing/add-item"
                element={<AddItemPricingPage />}
              />
              <Route
                path="support/support-chat"
                element={<AdminSupportChatPage />}
              />
              <Route
                path="support/admin-chat"
                element={<AdminSupportChatPage />}
              />

              <Route
                path="profile-management/registrations/details"
                element={<DetailsPage />}
              />
              <Route
                path="profile-management/registrations/agent-details"
                element={<AgentDetailsPage />}
              />
              <Route
                path="profile-management/registration-payments/details"
                element={<PaymentDetailsPage />}
              />
              <Route
                path="profile-management/user-profiling/details"
                element={<UserProfilingDetailsPage />}
              />
              <Route
                path="accounting/waiver/details"
                element={<WaiverDetailsPage />}
              />
              <Route
                path="accounting/waiver/generate-waiver"
                element={<GenerateWaiverPage />}
              />
              <Route
                path="accounting/waiver/generated-waiver-details"
                element={<GeneratedWaiverDetailsPage />}
              />
              <Route
                path="accounting/invoicing/generate-invoice"
                element={<GenerateInvoicePage />}
              />
              <Route
                path="accounting/invoicing/details"
                element={<InvoiceDetailsPage />}
              />
              <Route
                path="accounting/payments/details"
                element={<GeneralPaymentDetailsPage />}
              />
              <Route
                path="clearance/clearance-application/details"
                element={<ClearanceApplicationDetailsPage />}
              />
              <Route
                path="clearance/certificates/details"
                element={<CertificatesDetailsPage />}
              />
              <Route
                path="legal/falsified-records/details"
                element={<FalsifiedRecordsDetailsPage />}
              />
            </Route>
          </Route>

          {/* Not Found */}
          <Route path="*" element={<>NOT FOUND</>} />
        </Route>
      </Routes>
      <Toaster richColors />
    </BrowserRouter>
  </StrictMode>
);
