import { stateLabels } from "@/lib/site-data";
import { phoneNumberValidator } from "@/lib/utils";

import { z } from "zod";

export const government_form_schema_page_1 = z.object({
  institutionName: z.string().min(2, {
    message: "Institution Name must be at least 2 characters.",
  }),
  contactPerson: z.string().min(2, {
    message: "Contact Person must be at least 2 characters.",
  }),
  phoneNumber: z
    .string()
    .min(2, {
      message: "Phone Number must be at least 2 characters.",
    })
    .refine((val) => phoneNumberValidator(val), {
      message: "Invalid phone number format.",
    }),
  state: z.string().min(2, {
    message: "State must be at least 2 characters.",
  }),
  lga: z.string().min(2, {
    message: "LGA must be at least 2 characters.",
  }),
  street: z.string().min(2, {
    message: "Street must be at least 2 characters.",
  }),
  tin: z.string().min(2, {
    message: "TIN must be at least 2 characters.",
  }),
});

export const government_form_schema_page_2 = z.object({
  department: z.string().min(2, { message: "Department must be at least 2 characters." }),
  association: z.string().optional(),
});

export type government_form_schema_page_1_type = z.infer<
  typeof government_form_schema_page_1
>;
export type government_form_schema_page_2_type = z.infer<
  typeof government_form_schema_page_2
>;
