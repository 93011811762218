import { getBackendUrl } from "@/lib/utils";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

interface Ticket {
  id: number;
  sender: string;
  ticket_id: string;
  ticket_status: "OPEN" | "CLOSED";
  subject: string;
  status: string;
  created_at: string;
  updated_at: string;
  activities: string;
}

interface SupportAnalytics {
  totalTickets: number;
  openTickets: number;
  closedTickets: number;
  ticketsBySender: Record<string, number>;
}

const processTicketsData = (tickets: Ticket[]): SupportAnalytics => {
  const analytics: SupportAnalytics = {
    totalTickets: tickets.length,
    openTickets: 0,
    closedTickets: 0,
    ticketsBySender: {},
  };

  tickets.forEach((ticket) => {
    if (ticket.ticket_status === "OPEN") {
      analytics.openTickets += 1;
    } else if (ticket.ticket_status === "CLOSED") {
      analytics.closedTickets += 1;
    }

    // if (analytics.ticketsBySender[ticket.sender]) {
    //   analytics.ticketsBySender[ticket.sender] += 1;
    // } else {
    //   analytics.ticketsBySender[ticket.sender] = 1;
    // }
  });

  return analytics;
};

export const useSupportAnalytics = (keys?: string[]) => {
  const { data } = useQuery<SupportAnalytics>({
    queryKey: keys?.length
      ? ["support-analytics", ...keys]
      : ["support-analytics"],

    queryFn: async () => {
      const { data }: { data?: { ticketList: Ticket[] } } = await axios.get(
        `${getBackendUrl()}/fetch-all-ticket`
      );

      return processTicketsData(data?.ticketList || []);
    },
  });

  return data;
};
