import React from "react";
import ClearanceProcess from "./components/ClearanceProcess";
import ImageHero from "./components/ImageHero";
import VerificationProcess from "./components/VerificationProcess";
import SimplifyImportClearance from "./components/SimplifyImportClearance";
import Regulations from "./components/Regulations";
import Documents from "./components/Documents";

function Home() {
  return (
    <div className="w-full">
      <SimplifyImportClearance />
      <Regulations />
      <Documents />
      <ClearanceProcess />
      <VerificationProcess />
    </div>
  );
}

export default Home;
