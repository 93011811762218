import { userStoreInitialState, useStore } from "@/contexts/userStore";
import React, { useEffect } from "react";

import { Outlet, useNavigate } from "react-router-dom";

export const AuthLayout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const userCacheString = localStorage.getItem("nesrea_user");
    const user: userStoreInitialState["user"] | undefined = userCacheString
      ? JSON.parse(userCacheString)
      : undefined;
    if (user) {
      navigate(
        user.account_type == "admin" ? "/admin/dashboard" : "/user"
      );
      return;
    }
  },[]);
  return (
    <div>
      <Outlet />
    </div>
  );
};
