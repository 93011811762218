import React from "react";
import { ColumnDef } from "@tanstack/react-table";
import { ArrowUpDown, MoreHorizontal, Pencil, Trash } from "lucide-react";

import { Button, buttonVariants } from "@/components/ui/button";

import { cn, formatDate, getBackendUrl } from "@/lib/utils";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Checkbox } from "@/components/ui/checkbox";
import { agentStoreInitialState, useStore } from "@/contexts/agentStore";
import { ImportDocumentationData } from "@/lib/interfaces";
import { Link } from "react-router-dom";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import {
  Form,
  FormField,
  FormLabel,
  FormItem,
  FormMessage,
  FormControl,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { HSCodeFlattened, HSCodes } from "@/lib/dummy-data/hsCodes";
import { Badge } from "@/components/ui/badge";
import { ScrollArea } from "@/components/ui/scroll-area";
import { unitIds } from "@/lib/site-data";
import slugify from "slugify";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { toast } from "sonner";
import axios from "axios";

export const ImportDocumentationColumns: ColumnDef<HSCodeFlattened>[] = [
  {
    accessorKey: "categoryLabel",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Item Category
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
  },
  {
    accessorKey: "subCategoryLabel",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Sub Category
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
  },

  {
    accessorKey: "itemCategoryLabel",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Sub Category
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
  },

  {
    accessorKey: "itemCode",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          HSCodes
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
  },
  {
    accessorKey: "itemLabel",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Item
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
  },
  {
    accessorKey: "action",
    header: ({ column }) => {
      return (
        <div className="flex items-center">
          Action
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
    cell: ({ row }) => {
      const [isActionLoading, setIsActionLoading] = React.useState(false);
      const {
        itemLabel,
        itemCode,
        itemId,
        itemCost,
        itemCategoryLabel,
        subCategoryLabel,
        categoryLabel,
        itemCategoryId,
      } = row.original;
      const formSchema = z.object({
        itemId: z.string().min(1),
        itemLabel: z.string().min(1),
        itemCode: z.string().min(1),
        itemCost: z.record(z.enum(unitIds), z.string().optional()).optional(),
      });

      const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
          itemId,
          itemLabel,
          itemCode,
          itemCost,
        },
      });

      const { itemCost: formItemCost } = form.watch();
      const handleDelete = async () => {
        setIsActionLoading(true);
        try {
          await axios.post(`${getBackendUrl()}/delete-item`, {
            itemId,
          });
          setIsActionLoading(false);
          toast.success("deleted successfully");
          window.location.reload();
        } catch (error) {
          setIsActionLoading(false);
          toast.error("An Error Occurred");
        }
      };
      return (
        <div className="grid grid-cols-2 gap-4">
          <Dialog>
            <DialogTrigger className="underline text-primary-700 text-sm p-0">
              Edit
            </DialogTrigger>
            <DialogContent>
              <Form {...form}>
                <form
                  onSubmit={form.handleSubmit(
                    async (values: z.infer<typeof formSchema>) => {
                      setIsActionLoading(true);
                      try {
                        toast.success("Item added successfully");
                        const itemHSCode = itemCode;
                        const storedData = {
                          itemId: values.itemId
                            ? slugify(values.itemId.toLowerCase())
                            : "",
                          itemLabel: values.itemLabel || "",
                          itemCode: itemHSCode,
                          itemCost: JSON.stringify(values.itemCost) || "{}",
                          itemCategoryId: itemCategoryId || "",

                          categoryLabel: categoryLabel || "",
                          subCategoryLabel: subCategoryLabel || "",
                          itemCategoryLabel: itemCategoryLabel || "",
                        };
                        // Store Item
                        await axios.post(
                          `${getBackendUrl()}/store-items`,
                          storedData
                        );
                        toast.success("Item added successfully");
                        form.reset();
                        setIsActionLoading(false);
                        window.location.reload();
                      } catch (error) {
                        setIsActionLoading(false);
                        toast.error("Failed to add item");
                      }
                    }
                  )}
                >
                  <h1 className="w-full text-center text-2xl font-medium">
                    Edit Import Item
                  </h1>
                  <FormField
                    control={form.control}
                    name="itemLabel"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Item Name</FormLabel>
                        <FormControl className="flex flex-col gap-2">
                          <>
                            <Input
                              {...field}
                              onChange={(e) => {
                                if (e.target.value.length == 0) {
                                  form.setValue("itemCost", undefined);
                                }

                                form.setValue(
                                  "itemId",
                                  slugify(e.target.value.toLowerCase())
                                );
                                form.setValue("itemLabel", e.target.value);
                                form.setValue("itemCode", e.target.value);
                                form.setValue("itemCost", {});

                                field.onBlur();
                              }}
                              placeholder="Enter item name"
                            />

                            {formItemCost && (
                              <Accordion
                                className="w-full"
                                type="single"
                                collapsible
                              >
                                <AccordionItem
                                  value="item-1"
                                  className="w-full border-none"
                                >
                                  <AccordionTrigger className="w-full p-0 text-sm underline">
                                    <div className="flex gap-2">
                                      Edit Item Costing{" "}
                                      <em>(Click To View Details)</em>
                                    </div>
                                  </AccordionTrigger>
                                  <AccordionContent className="py-4">
                                    <div className="flex flex-col gap-4">
                                      <h1 className="">
                                        Select from the available metric options
                                        below:
                                      </h1>
                                      <div className="flex flex-wrap gap-3">
                                        {unitIds.map((unit) => {
                                          const itemCostKeys =
                                            Object.keys(formItemCost);
                                          const isAdded =
                                            itemCostKeys.includes(unit);
                                          return (
                                            <Badge
                                              key={`${unit} item`}
                                              variant={
                                                isAdded ? "default" : "outline"
                                              }
                                              className="capitalize cursor-pointer hover:bg-primary-500 hover:text-primary-foreground"
                                              onClick={() => {
                                                if (isAdded) {
                                                  console.log("ADDED");
                                                  const { [unit]: _, ...rest } =
                                                    itemCost;
                                                  form.setValue(
                                                    "itemCost",
                                                    rest
                                                  );
                                                } else {
                                                  console.log(
                                                    "NOT ADDED",
                                                    unit
                                                  );
                                                  form.setValue("itemCost", {
                                                    ...formItemCost,
                                                    [unit]: "0",
                                                  });
                                                }
                                              }}
                                            >
                                              {unit.split("-").join(" ")}
                                            </Badge>
                                          );
                                        })}
                                      </div>

                                      <div className="grid gap-3 w-full max-w-xl">
                                        {Object.keys(formItemCost).map(
                                          (unit: any) => {
                                            const unitValue: (typeof unitIds)[number] =
                                              unit;
                                            return (
                                              <div
                                                key={`${unitValue} item input`}
                                                className="flex items-center gap-3"
                                              >
                                                <h1 className="capitalize flex-[0.4]">
                                                  {unit.split("-").join(" ")}
                                                </h1>
                                                <Input
                                                  key={unit}
                                                  className="flex-1"
                                                  type="number"
                                                  min={0}
                                                  value={
                                                    formItemCost[unitValue] ||
                                                    ""
                                                  }
                                                  onChange={(e) => {
                                                    form.setValue(
                                                      "itemCost",
                                                      Object.fromEntries(
                                                        Object.entries(
                                                          formItemCost
                                                        ).map(([k, v]) => [
                                                          k,
                                                          k === unit
                                                            ? e.target.value
                                                            : v,
                                                        ])
                                                      )
                                                    );
                                                  }}
                                                  placeholder={`Enter Item Cost Per (${unit})`}
                                                />
                                                <Trash
                                                  className="cursor-pointer hover:text-destructive duration-300"
                                                  size={15}
                                                  onClick={() => {
                                                    const {
                                                      [unitValue]: _,
                                                      ...rest
                                                    } = formItemCost;
                                                    form.setValue(
                                                      "itemCost",
                                                      rest
                                                    );
                                                  }}
                                                />
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                      {/* <Button
                                  className="w-fit"
                                  disabled={
                                    itemCategoryData?.itemCategoryCost ==
                                      JSON.stringify(itemCategoryCost) ||
                                    isActionLoading
                                  }
                                  onClick={() => {
                                    const updatedItemCategory = {
                                      subCategoryId,
                                      itemCategoryId: itemCategoryId || "",
                                      itemCategoryLabel:
                                        itemCategoryLabel || "",
                                      itemCategoryCode:
                                        itemCategoryCode || "",
                                      itemCategoryCost:
                                        JSON.stringify(categoryCost),
                                    };
                                    addItemsCategoryMutation.mutate(
                                      updatedItemCategory
                                    );
                                  }}
                                >
                                  Update
                                </Button> */}
                                    </div>
                                  </AccordionContent>
                                </AccordionItem>
                              </Accordion>
                            )}
                          </>
                        </FormControl>
                      </FormItem>
                    )}
                  />
                  <Button
                    className="w-full mt-3"
                    type="submit"
                    disabled={isActionLoading}
                  >
                    Save {itemId}
                  </Button>
                </form>
              </Form>
            </DialogContent>
          </Dialog>
          <Dialog>
            <DialogTrigger className="underline text-destructive text-sm p-0">
              Delete
            </DialogTrigger>
            <DialogContent>
              <h3 className="text-primary-500 font-medium text-lg text-center">
                {"You're"} about to delete an import item. Do you want to
                proceed?
              </h3>
              <div className="flex items-center gap-4">
                <Button
                  className="w-full py-7"
                  onClick={handleDelete}
                  disabled={isActionLoading}
                >
                  Yes, proceed
                </Button>
                <Button
                  variant="outline"
                  disabled={isActionLoading}
                  className="w-full py-7 bg-transparent border-destructive text-destructive"
                >
                  No, cancel
                </Button>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      );
    },
  },
  // {
  //   accessorKey: "documentCode",
  //   header: ({ column }) => {
  //     return (
  //       <div
  //         className="flex items-center cursor-pointer hover:underline"
  //         onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
  //       >
  //         Document Code
  //         <ArrowUpDown className="ml-2 h-4 w-4" />
  //       </div>
  //     );
  //   },
  //   cell: ({ row }) => {
  //     return (
  //       <div className="grid gap-4">
  //         {row.original.documents.map((doc) => (
  //           <p key={doc.documentCode} className="text-sm">
  //             {doc.documentCode}
  //           </p>
  //         ))}
  //       </div>
  //     );
  //   },
  // },

  // {
  //   accessorKey: "action",
  //   header: ({ column }) => {
  //     return (
  //       <div className="flex items-center">
  //         Action
  //         <ArrowUpDown className="ml-2 h-4 w-4" />
  //       </div>
  //     );
  //   },
  //   cell: ({ row }) => {
  //     const formSchema = z.object({
  //       comment: z.string().min(2, { message: "" }),
  //     });
  //     type formSchemaType = z.infer<typeof formSchema>;
  //     const form = useForm<formSchemaType>({
  //       resolver: zodResolver(formSchema),
  //       defaultValues: {
  //         comment: "",
  //       },
  //     });
  //     return (
  //       <div className="grid gap-4">
  //         {row.original.documents.map((doc) => (
  //           <Dialog>
  //             <DialogTrigger className="underline text-primary-700 text-sm p-0">
  //               Delete
  //             </DialogTrigger>
  //             <DialogContent>
  //               <h3 className="text-primary-500 font-medium text-lg text-center">
  //                 {"You're"} about to delete a document. Do you want to
  //                 proceed?
  //               </h3>
  //               <Form {...form}>
  //                 <form
  //                   className="flex flex-col gap-7"
  //                   onSubmit={form.handleSubmit(async (data) => {})}
  //                 >
  //                   <FormField
  //                     control={form.control}
  //                     name="comment"
  //                     render={({ field }) => (
  //                       <FormItem>
  //                         <FormLabel className="text-primary-700 font-semibold text-lg">
  //                           Add comment/reason for deletion{" "}
  //                           <span className="text-red-500">*</span>
  //                         </FormLabel>
  //                         <FormControl>
  //                           <Input {...field} />
  //                         </FormControl>
  //                         <FormMessage />
  //                       </FormItem>
  //                     )}
  //                   />
  //                   <div className="flex w-full gap-5">
  //                     <Button type="submit" className="w-full py-7">
  //                       Yes, proceed
  //                     </Button>
  //                     <DialogClose asChild>
  //                       <Button
  //                         type="button"
  //                         variant="outline"
  //                         className="w-full py-7 bg-transparent border-destructive text-destructive"
  //                       >
  //                         No, cancel
  //                       </Button>
  //                     </DialogClose>
  //                   </div>
  //                 </form>
  //               </Form>
  //             </DialogContent>
  //           </Dialog>
  //         ))}
  //       </div>
  //     );
  //   },
  // },
];
