import React from "react";
import { TrendingUp } from "lucide-react";
import { CartesianGrid, Label, Line, LineChart, XAxis, YAxis } from "recharts";

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { usePaymentAnalytics } from "@/hooks/analytics/usePaymentAnalytics";
import LoaderWrapper from "@/components/ui/loader-wrapper";

export default function MonthlyRevenueTrends() {
  const data = usePaymentAnalytics();
  const chartConfig = {
    desktop: {
      label: "trend",
      color: "rgb(var(--primary))",
    },
  } satisfies ChartConfig;

  const [currentYear, setCurrentYear] = React.useState(
    new Date().getFullYear()
  );

  const tickData = { stroke: "black", strokeWidth: 0.25, fontSize: 11 };

  console.log(data?.charts?.all.monthly, "revenue trends");
  return (
    <LoaderWrapper page={false} isLoading={data?.utils.isLoading!}>
      <div className="pb-10">
        <ChartContainer config={chartConfig}>
          <LineChart
            accessibilityLayer
            data={data?.charts?.all.monthly[currentYear] || []}
            margin={{
              left: 12,
              right: 12,
              bottom: 30,
            }}
          >
            <CartesianGrid strokeWidth={0.25} />
            <XAxis
              dataKey="month"
              tickLine={false}
              tick={{ ...tickData }}
              axisLine={false}
              // tickMargin={8}
              label={{
                value: "Months",
                position: "insideBottom",
                offset: -20,
                stroke: "green",
                strokeWidth: 0.5,
              }}
              tickFormatter={(value) => value.slice(0, 3)}
              style={{
                fill: "black",
              }}
            ></XAxis>
            <YAxis
              tickLine={false}
              axisLine={false}
              tickMargin={0}
              tick={tickData}
              style={{
                fill: "black",
              }}
              tickFormatter={(value) =>
                `₦${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
              }
            />

            <ChartTooltip cursor={false} content={<ChartTooltipContent />} />
            <Line
              dataKey="trend"
              type="linear"
              stroke="rgb(var(--primary))"
              strokeWidth={2}
              dot={{
                fill: "rgb(var(--primary))",
              }}
            />
          </LineChart>
        </ChartContainer>
      </div>
    </LoaderWrapper>
  );
}
