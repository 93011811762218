import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import {
  emailValidator,
  getBackendUrl,
  phoneNumberValidator,
} from "@/lib/utils";
import { Label } from "@/components/ui/label";
import { useNavigate, useNavigation } from "react-router-dom";
import { useStore } from "@/contexts/agentStore";
import { useStore as useStore_ } from "@/contexts/userStore";
import axios from "axios";
import LoaderWrapper from "@/components/ui/loader-wrapper";
import { toast } from "sonner";
import { stateNamesMap } from "@/lib/site-data";
import { LGAs } from "@/lib/site-data";
import { states } from "@/lib/site-data";
import { STATES } from "@/lib/interfaces";
import { DocumentsForm } from "../DocumentsForm";
import { PaymentForm } from "@/components/ui/payment-section";

const CategoryFormSchema = z.object({
  category: z.enum(["company", "individual"], {
    message: "Please select a user category",
  }),
});
type CategoryFormType = z.infer<typeof CategoryFormSchema>;

const CompanyFormSchema = z.object({
  companyName: z.string().min(3, { message: "" }),
  companyAddress: z.string(),
  emailAddress: z.string().refine((val) => (val ? emailValidator(val) : true)),
  tin: z.string(),
});
type CompanyFormType = z.infer<typeof CompanyFormSchema>;

const IndividualFormSchema = z.object({
  fullName: z.string().min(3, { message: "" }),
  nin: z.string().min(11, { message: "" }).max(11, { message: "" }),
  residentialAddress: z.string().min(5, { message: "" }),
  phoneContact: z.string().refine((val) => phoneNumberValidator(val)),
  emailAddress: z.string().refine((val) => emailValidator(val)),
  state: z.string().min(2, {
    message: "State is required.",
  }),
  lga: z.string().min(2, {
    message: "LGA is required.",
  }),
  association: z.string().optional(),
});
type IndividualFormType = z.infer<typeof IndividualFormSchema>;

export default function ClientsForm() {
  const navigate = useNavigate();
  const [formPage, setFormPage] = useState(1);
  const agentStore = useStore((state) => state);
  const userStore = useStore_((state) => state.user);
  const [activeFormCategory, setActiveFormCategory] =
    useState<CategoryFormType["category"]>();

  const [companyFormData, setCompanyFormData] = useState<CompanyFormType>({
    companyName: "",
    companyAddress: "",
    emailAddress: "",
    tin: "",
  });
  const [individualFormData, setIndividualFormData] =
    useState<IndividualFormType>({
      fullName: "",
      nin: "",
      residentialAddress: "",
      emailAddress: "",
      phoneContact: "",
      state: "Anambra",
      lga: "",
      association: "",
    });

  const [isDocumentUpload, setIsDocumentUpload] = useState(false);
  const [isPayment, setIsPayment] = useState(false);

  const [documents, setDocuments] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const handlePaymentSuccess = async () => {
    setIsLoading(true);
    try {
      const documentsFormData = new FormData();

      documents.forEach((file) => {
        documentsFormData.append("files[]", file);
      });

      toast.success("Profile updated successfully");
      navigate("/user/clients");
    } catch (error) {
      setIsLoading(false);
      toast.error("An error occurred");
    }
  };
  return (
    <LoaderWrapper isLoading={isLoading}>
      <div className="w-full space-y-4">
        <CategorySelectForm
          isDisabled={activeFormCategory?.length != undefined}
          handleSubmit={(value) => {
            setFormPage((prev) => prev + 1);
            setActiveFormCategory(value.category);
          }}
        />

        {!isDocumentUpload && (
          <>
            {activeFormCategory == "company" && (
              <CompanyForm
                handleSubmit={(val) => {
                  setCompanyFormData(val);
                  setIsDocumentUpload(true);
                }}
              />
            )}
            {activeFormCategory == "individual" && (
              <IndividualForm
                handleSubmit={(val) => {
                  setIndividualFormData(val);
                  setIsDocumentUpload(true);
                }}
              />
            )}
          </>
        )}

        {isDocumentUpload && !isPayment && (
          <DocumentsForm
            handleSubmit={async (docs) => {
              if (docs.length === 0) {
                toast.error("No document selected");
                return;
              }
              let total_mb = 0;
              docs.forEach((file) => {
                total_mb += file.size / 1024 / 1024;
              });
              if (total_mb > 3) {
                toast.error("Total file size must not be more than 3MB");
                return;
              }
              setDocuments(docs);
              let today = new Date();
              // STORE DATA
              switch (activeFormCategory) {
                case "company":
                  try {
                    setIsLoading(true);
                    const docFormData = new FormData();
                    docFormData.append("account_type", "company");
                    docs.forEach((doc) => docFormData.append("files[]", doc));
                    docFormData.append("email", companyFormData.emailAddress);
                    const company_body = {
                      account_type: "company",
                      email: companyFormData.emailAddress,
                      importer_name: companyFormData.companyName,
                      street: companyFormData.companyAddress,
                      tin_cac: companyFormData.tin,
                      created_by: userStore.email,
                    };
                    await axios.post(
                      `${getBackendUrl()}/store-information`,
                      company_body
                    );
                    await axios.post(
                      `${getBackendUrl()}/upload-reg-document`,
                      docFormData
                    );
                    // navigate("/user/clients");
                  } catch (error) {
                    setIsLoading(false);
                    toast.error("An Error Occurred while creating the client");
                  }
                  break;
                case "individual":
                  try {
                    setIsLoading(true);
                    const docFormData = new FormData();
                    docFormData.append("account_type", "company");
                    docs.forEach((doc) => docFormData.append("files[]", doc));
                    docFormData.append(
                      "email",
                      individualFormData.emailAddress
                    );
                    const individual_body = {
                      account_type: "individual",
                      email: individualFormData.emailAddress,
                      importer_name: individualFormData.fullName,
                      phone_number: individualFormData.phoneContact,
                      state: individualFormData.state,
                      lga: individualFormData.lga,
                      association: individualFormData.association,
                      street: individualFormData.residentialAddress,
                      national_identity_number: Number(individualFormData.nin),
                      created_by: userStore.email,
                    };

                    await axios.post(
                      `${getBackendUrl()}/store-information`,
                      individual_body
                    );
                    await axios.post(
                      `${getBackendUrl()}/upload-reg-document`,
                      docFormData
                    );
                    // navigate("/user/clients");
                  } catch (error) {
                    setIsLoading(false);
                    toast.error("An Error Occurred while creating the client");
                  }
                  break;

                default:
                  break;
              }

              setIsPayment(true);
              setIsLoading(false);
            }}
          />
        )}

        {activeFormCategory == "company" && (
          <>
            {isPayment && (
              <PaymentForm
                amount={115000}
                email={companyFormData.emailAddress}
                onSuccess={handlePaymentSuccess}
              />
            )}
          </>
        )}

        {activeFormCategory == "individual" && (
          <>
            {isPayment && (
              <PaymentForm
                amount={115000}
                email={individualFormData.emailAddress}
                onSuccess={handlePaymentSuccess}
              />
            )}
          </>
        )}
      </div>
    </LoaderWrapper>
  );
}

const CategorySelectForm = ({
  handleSubmit,
  isDisabled,
}: {
  handleSubmit: (values: CategoryFormType) => void;
  isDisabled: boolean;
}) => {
  const form = useForm<CategoryFormType>({
    resolver: zodResolver(CategoryFormSchema),
    defaultValues: {},
  });

  return (
    <Form {...form}>
      <form
        className="flex flex-col gap-4"
        onSubmit={form.handleSubmit(handleSubmit)}
      >
        <FormField
          control={form.control}
          name="category"
          render={({ field }) => (
            <FormItem className="w-full flex flex-col gap-2">
              <FormLabel>
                Client Category <span className="text-destructive">*</span>
              </FormLabel>
              <FormControl>
                <Select
                  //   {...field}
                  value={field.value}
                  disabled={isDisabled}
                  name={field.name}
                  onValueChange={(val: "company" | "individual") => {
                    form.setValue("category", val);
                    field.onBlur();
                  }}
                >
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="Select Category" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="company">Company</SelectItem>
                    <SelectItem value="individual">Individual</SelectItem>
                  </SelectContent>
                </Select>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        {!isDisabled && (
          <Button
            type="submit"
            className="w-full max-w-[164px]"
            disabled={!form.formState.isValid}
          >
            Next
          </Button>
        )}
      </form>
    </Form>
  );
};

const CompanyForm = ({
  handleSubmit,
}: {
  handleSubmit: (values: CompanyFormType) => void;
}) => {
  const form = useForm<CompanyFormType>({
    resolver: zodResolver(CompanyFormSchema),
    defaultValues: {
      companyName: "",
      companyAddress: "",
      emailAddress: "",
      tin: "",
    },
  });
  return (
    <Form {...form}>
      <form
        className="flex flex-col gap-4"
        onSubmit={form.handleSubmit(handleSubmit)}
      >
        <FormField
          control={form.control}
          name="companyName"
          render={({ field }) => (
            <FormItem className="w-full flex flex-col gap-2">
              <FormLabel>
                Company Name <span className="text-destructive">*</span>
              </FormLabel>
              <FormControl>
                <Input {...field} placeholder="Enter Company Name" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="companyAddress"
          render={({ field }) => (
            <FormItem className="w-full flex flex-col gap-2">
              <FormLabel>
                Company Address <span className="text-destructive">*</span>
              </FormLabel>
              <FormControl>
                <Input {...field} placeholder="Enter company address" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="emailAddress"
          render={({ field }) => (
            <FormItem className="w-full flex flex-col gap-2">
              <FormLabel>
                Email Address <span className="text-destructive">*</span>
              </FormLabel>
              <FormControl>
                <Input {...field} placeholder="Enter email address" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="w-full py-3 border-b mb-2">
          <h3 className="font-medium">Government ID</h3>
          <p className="text-xs font-light text-neutral-600">
            Please enter your <strong>TIN</strong> for Identification.
          </p>
        </div>
        <FormField
          control={form.control}
          name="tin"
          render={({ field }) => (
            <FormItem className="w-full flex flex-col gap-2">
              <FormLabel>TIN</FormLabel>
              <FormControl>
                <Input {...field} type="number" placeholder="Enter TIN" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button className="w-fit px-8">Proceed to Document Upload</Button>
      </form>
    </Form>
  );
};
const IndividualForm = ({
  handleSubmit,
}: {
  handleSubmit: (values: IndividualFormType) => void;
}) => {
  const form = useForm<IndividualFormType>({
    resolver: zodResolver(IndividualFormSchema),
    defaultValues: {
      fullName: "",
      residentialAddress: "",
      emailAddress: "",
      phoneContact: "",
      state: "",
    },
  });
  return (
    <Form {...form}>
      <form
        className="flex flex-col gap-4"
        onSubmit={form.handleSubmit(handleSubmit)}
      >
        <FormField
          control={form.control}
          name="fullName"
          render={({ field }) => (
            <FormItem className="w-full flex flex-col gap-2">
              <FormLabel>
                Full Name <span className="text-destructive">*</span>
              </FormLabel>
              <FormControl>
                <Input {...field} placeholder="Enter Full Name" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="state"
          render={({ field }) => (
            <FormItem className="flex-1 min-w-[300px] flex-col gap-3 flex">
              <FormLabel>State</FormLabel>
              <FormControl>
                <Select
                  {...field}
                  onValueChange={(currentValue: any) => {
                    const val: IndividualFormType["state"] = currentValue;
                    form.setValue("state", val);
                    const label: any = states.filter(
                      (state) => state.value == val
                    )[0].label;
                    const myLabel: STATES = label;
                    form.setValue("lga", LGAs[myLabel][0]);
                    form.trigger("state");
                    form.trigger("lga");
                  }}
                >
                  <SelectTrigger className="!m-0">
                    <SelectValue placeholder="State" />
                  </SelectTrigger>
                  <SelectContent>
                    {states.map((state) => (
                      <SelectItem key={state.value} value={state.value}>
                        {state.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="lga"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Local Government Area (LGA)</FormLabel>
              <FormControl>
                <Select
                  onValueChange={(value) => {
                    form.setValue("lga", value);
                    form.trigger("lga");
                  }}
                  {...field}
                >
                  <SelectTrigger className="">
                    <SelectValue placeholder="Local Government Area" />
                  </SelectTrigger>
                  <SelectContent>
                    {LGAs[stateNamesMap[form.getValues().state]] ? (
                      LGAs[stateNamesMap[form.getValues().state]].map((lga) => (
                        <SelectItem key={lga} value={lga}>
                          {lga}
                        </SelectItem>
                      ))
                    ) : (
                      <SelectItem value="n/a">Please Select a State</SelectItem>
                    )}
                  </SelectContent>
                </Select>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="association"
          render={({ field }) => (
            <FormItem className="w-full flex flex-col gap-2">
              <FormLabel>Association</FormLabel>
              <FormControl>
                <Input type="text" placeholder="Enter association" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="nin"
          render={({ field }) => (
            <FormItem className="w-full flex flex-col gap-2">
              <FormLabel>
                NIN <span className="text-destructive">*</span>
              </FormLabel>
              <FormControl>
                <Input {...field} type="number" placeholder="Enter NIN" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="residentialAddress"
          render={({ field }) => (
            <FormItem className="w-full flex flex-col gap-2">
              <FormLabel>
                Residential Address <span className="text-destructive">*</span>
              </FormLabel>
              <FormControl>
                <Input {...field} placeholder="Enter residential address" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="emailAddress"
          render={({ field }) => (
            <FormItem className="w-full flex flex-col gap-2">
              <FormLabel>
                Email Address <span className="text-destructive">*</span>
              </FormLabel>
              <FormControl>
                <Input {...field} placeholder="Enter email address" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="phoneContact"
          render={({ field }) => (
            <FormItem className="w-full flex flex-col gap-2">
              <FormLabel>Phone Contact</FormLabel>
              <FormControl>
                <Input {...field} placeholder="Enter phone contact" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button className="w-fit px-8">Proceed to Document Upload</Button>
      </form>
    </Form>
  );
};
