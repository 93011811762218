import React from "react";
import { DataTable } from "./data-table";
import { ImportDocumentationColumns } from "./columns";
import { useStore } from "@/contexts/agentStore";
import paymentsData from "@/lib/dummy-data/paymentsData";
import certificatesData from "@/lib/dummy-data/certificatesData";
import { importDocumentationData } from "@/lib/dummy-data/importDocumentationData";
import {
  flattenHSCodeArray,
  HSCodeFlattened,
  HSCodes,
} from "@/lib/dummy-data/hsCodes";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { getBackendUrl } from "@/lib/utils";

export default function RegistrationPricingTable() {
  const agentStore = useStore((state) => state);
  const flattenedHSCodes = flattenHSCodeArray(HSCodes);
  const { data } = useQuery({
    queryKey: ["import-items"],
    queryFn: async () => {
      const { data }: { data?: { allItems: any[] } } = await axios.get(
        `${getBackendUrl()}/get-all-items`
      );
      const processedData: HSCodeFlattened[] =
        data?.allItems.map((item) => ({
          categoryId: "",
          categoryLabel: item.categoryLabel,
          categoryCode: "",
          subCategoryId: "",
          subCategoryLabel: item.subCategoryLabel,
          subCategoryCode: "",
          subCategoryCost: {},
          itemCategoryId: item.itemCategoryId,
          itemCategoryLabel: item.itemCategoryLabel,
          itemCategoryCode: "",
          itemCategoryCost: {},
          itemId: item.itemId,
          itemLabel: item.itemLabel,
          itemCode: item.itemCode,
          itemCost: JSON.parse(item.itemCost || "{}"),
        })) || [];

      return processedData;
    },
  });
  return (
    <div>
      <DataTable columns={ImportDocumentationColumns} data={data || []} />
    </div>
  );
}
