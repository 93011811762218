import { stateLabels, states } from "@/lib/site-data";
import { phoneNumberValidator } from "@/lib/utils";

import { z } from "zod";

export const agent_form_schema_page_1 = z.object({
  fullName: z.string().min(2, {
    message: "Full name must be at least 2 characters.",
  }),
  phoneNumber: z
    .string()
    .min(2, {
      message: "Phone number must be at least 2 characters.",
    })
    .refine((val) => phoneNumberValidator(val), {
      message: "Invalid phone number format.",
    }),
  state: z.string().min(2, {
    message: "State must be at least 2 characters.",
  }),
  lga: z.string().min(2, {
    message: "LGA must be at least 2 characters.",
  }),
  street: z.string().min(2, {
    message: "Street must be at least 2 characters.",
  }),
});

export const agent_form_schema_page_2 = z.object({
  association: z.string().optional(),
  nin: z
    .string()
    .min(11, {
      message: "NIN must be exactly 11 digits.",
    })
    .max(11, { message: "NIN must be exactly 11 digits." }),
});

export type agent_form_schema_page_1_type = z.infer<
  typeof agent_form_schema_page_1
>;
export type agent_form_schema_page_2_type = z.infer<
  typeof agent_form_schema_page_2
>;
