import { useQuery } from "@tanstack/react-query";
import { getBackendUrl } from "@/lib/utils";
import axios from "axios";

export const useUserAnalytics = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["user-analytics"],
    queryFn: async () => {
      const { data }: { data: { alluser: any[]; allagent: any[] } } =
        await axios.get(`${getBackendUrl()}/all-registered-account`);
      const processed_data = [...data?.allagent, ...data?.alluser];
      return processed_data;
    },
  });

  if ((data?.length || 0) > 0) {
    const {
      TOTAL_USERS,
      TOTAL_AGENTS,
      TOTAL_COMPANIES,
      TOTAL_INDIVIDUALS,
      TOTAL_GOVERNMENT,
    } = getUserCount(data);
    const { TOTAL_NEW_USERS, TOTAL_APPROVED_USERS, TOTAL_REJECTED_USERS } =
      getUserStatusCount(data);
    const {
      TOTAL_PENDING_AGENTS,
      TOTAL_PENDING_COMPANIES,
      TOTAL_PENDING_INDIVIDUALS,
      TOTAL_PENDING_GOVERNMENT,
    } = getPendingUserCount(data);

    return {
      users_count: {
        TOTAL_USERS,
        TOTAL_AGENTS,
        TOTAL_COMPANIES,
        TOTAL_INDIVIDUALS,
        TOTAL_GOVERNMENT,
      },
      registration_status: {
        TOTAL_NEW_USERS,
        TOTAL_APPROVED_USERS,
        TOTAL_REJECTED_USERS,
        TOTAL_PENDING_AGENTS,
        TOTAL_PENDING_COMPANIES,
        TOTAL_PENDING_INDIVIDUALS,
        TOTAL_PENDING_GOVERNMENT,
      },
    };
  }

  return null;
};

const getUserCount = (data: any) => {
  const TOTAL_USERS = data.length;
  const TOTAL_AGENTS = data.filter(
    (user: any) => user?.importer_category == "agent"
  ).length;
  const TOTAL_COMPANIES = data.filter(
    (user: any) => user.importer_category === "company"
  ).length;
  const TOTAL_INDIVIDUALS = data.filter(
    (user: any) => user.importer_category === "individual"
  ).length;
  const TOTAL_GOVERNMENT = data.filter(
    (user: any) => user.importer_category === "government"
  ).length;

  return {
    TOTAL_USERS,
    TOTAL_AGENTS,
    TOTAL_COMPANIES,
    TOTAL_INDIVIDUALS,
    TOTAL_GOVERNMENT,
  };
};

const getUserStatusCount = (data: any) => {
  const TOTAL_NEW_USERS = data.filter((user: any) => {
    return user.status.toLowerCase() == "New".toLowerCase();
  }).length;
  const TOTAL_APPROVED_USERS = data.filter(
    (user: any) => user.status.toLowerCase() == "Approved".toLowerCase()
  ).length;
  const TOTAL_REJECTED_USERS = data.filter(
    (user: any) => user.status.toLowerCase() == "Disapproved".toLowerCase()
  ).length;

  return {
    TOTAL_NEW_USERS,
    TOTAL_APPROVED_USERS,
    TOTAL_REJECTED_USERS,
  };
};

const getPendingUserCount = (data: any) => {
  const TOTAL_PENDING_AGENTS = data.filter(
    (user: any) =>
      user.importer_category === "agent" && user.status.toLowerCase() === "new"
  ).length;
  const TOTAL_PENDING_COMPANIES = data.filter(
    (user: any) =>
      user.importer_category === "company" && user.status.toLowerCase() === "new"
  ).length;
  const TOTAL_PENDING_INDIVIDUALS = data.filter(
    (user: any) =>
      user.importer_category === "individual" && user.status.toLowerCase() === "new"
  ).length;
  const TOTAL_PENDING_GOVERNMENT = data.filter(
    (user: any) =>
      user.importer_category === "government" && user.status.toLowerCase() === "new"
  ).length;

  return {
    TOTAL_PENDING_AGENTS,
    TOTAL_PENDING_COMPANIES,
    TOTAL_PENDING_INDIVIDUALS,
    TOTAL_PENDING_GOVERNMENT,
  };
};
