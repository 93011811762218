import { buttonVariants } from "@/components/ui/button";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { useClearanceAnalytics } from "@/hooks/analytics/useClearanceAnalytics";
import { cn } from "@/lib/utils";
import React from "react";
import LoaderWrapper from "@/components/ui/loader-wrapper";
import { FalsifiedRecordsChart } from "./FalsifiedRecordsChart";
import { useLegalAnalytics } from "@/hooks/analytics/useLegalAnalytics";

export default function FalsifiedRecordsReport() {
  const { data, isLoading } = useLegalAnalytics();
  const CardStats = [
    {
      title: "Total Number of Falsified Records",
      value: data?.totalCertificates || 0,
    },
  ];
  return (
    <LoaderWrapper page={false} isLoading={isLoading}>
      <div className="w-full shadow-[0_4px_20px_0px_#00000014] rounded-xl p-4 flex flex-col gap-5">
        <div className="flex items-center justify-between w-full">
          <h1 className="text-primary-700 text-xl font-medium">
            Falsified Records
          </h1>
        </div>

        {data && (
          <div className="w-full grid md:grid-cols-5">
            <div className="col-span-3 h-full flex items-center">
              <FalsifiedRecordsChart data={data} />
            </div>
            <div className="flex flex-col justify-end gap-5 min-w-[200px] col-span-2 w-full mx-auto">
              {CardStats.map(({ title, value }, index) => (
                <div
                  key={title}
                  className={cn(
                    "flex flex-col gap-3 p-5 rounded-xl flex-1 min-w-[200px] max-w-full h-fit justify-between bg-primary-700 text-primary-foreground"
                  )}
                >
                  <p className={cn("text-xs ")}>{title}</p>
                  <h4 className="font-bold text-2xl">{value}</h4>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </LoaderWrapper>
  );
}
