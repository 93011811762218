import SupportTable from "@/components/tables/supportTable";
import React from "react";
import { useStore } from "@/contexts/userStore";
import DashboardHeader from "@/components/ui/dashboard-header";

export default function SupportPage() {
  const userStore = useStore();
  const { registration_status } = userStore.user;
  return registration_status == "verified" ? (
    <div>
      <DashboardHeader
        title="Support"
        caption="Reach out to help desk with complaints"
      />
      <SupportTable />
    </div>
  ) : (
    <></>
  );
}
