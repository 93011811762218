import WaiverRequestForm from "@/components/forms/WaiverRequestForm";
import DashboardHeader from "@/components/ui/dashboard-header";
import React from "react";

export default function WaiverApplicationPage() {
  return (
    <div>
      <DashboardHeader
        title="Waiver Request Application"
        caption="Apply for a fee waiver."
      />
      <WaiverRequestForm />
    </div>
  );
}
