import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { cn } from "@/lib/utils";
import React, { useState } from "react";

export const DocumentsForm = ({
  handleSubmit,
  buttonText,
  isDestructive,
  isButtonFull,
  isDisabled
}: {
  handleSubmit: (documents: File[]) => void;
  buttonText?: string;
  isDestructive?: boolean;
  isButtonFull?: boolean;
  isDisabled?:boolean
}) => {
  const [files, setFiles] = useState<File[]>([]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    if (selectedFiles) {
      const fileArray = Array.from(selectedFiles);
      setFiles((prevFiles) => [...prevFiles, ...fileArray]);
    }
  };

  const handleDeleteFile = (fileName: string) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="w-full flex flex-col gap-2">
        <h5 className="text-neutral-400">
          File format: PDF files only{" "}
          <strong className="">({files.length})</strong>
        </h5>
        <Label
          htmlFor="files"
          className="w-full h-[280px] rounded-2xl bg-neutral-200/70 hover:bg-neutral-200 duration-300 cursor-pointer flex flex-col items-center justify-center gap-3"
        >
          <ul className="mt-3 space-y-2 w-fit max-w-sm">
            {files.map((file, index) => (
              <li
                key={index}
                className="flex items-center gap-3 justify-between bg-neutral-100 p-2 rounded-md"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <mask
                    id="mask0_3146_4450"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="24"
                    height="24"
                  >
                    <rect width="24" height="24" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_3146_4450)">
                    <path
                      d="M9 12.5H10V10.5H11C11.2833 10.5 11.5208 10.4042 11.7125 10.2125C11.9042 10.0208 12 9.78333 12 9.5V8.5C12 8.21667 11.9042 7.97917 11.7125 7.7875C11.5208 7.59583 11.2833 7.5 11 7.5H9V12.5ZM10 9.5V8.5H11V9.5H10ZM13 12.5H15C15.2833 12.5 15.5208 12.4042 15.7125 12.2125C15.9042 12.0208 16 11.7833 16 11.5V8.5C16 8.21667 15.9042 7.97917 15.7125 7.7875C15.5208 7.59583 15.2833 7.5 15 7.5H13V12.5ZM14 11.5V8.5H15V11.5H14ZM17 12.5H18V10.5H19V9.5H18V8.5H19V7.5H17V12.5ZM8 18C7.45 18 6.97917 17.8042 6.5875 17.4125C6.19583 17.0208 6 16.55 6 16V4C6 3.45 6.19583 2.97917 6.5875 2.5875C6.97917 2.19583 7.45 2 8 2H20C20.55 2 21.0208 2.19583 21.4125 2.5875C21.8042 2.97917 22 3.45 22 4V16C22 16.55 21.8042 17.0208 21.4125 17.4125C21.0208 17.8042 20.55 18 20 18H8ZM8 16H20V4H8V16ZM4 22C3.45 22 2.97917 21.8042 2.5875 21.4125C2.19583 21.0208 2 20.55 2 20V6H4V20H18V22H4Z"
                      fill="#1C1B1F"
                    />
                  </g>
                </svg>

                <span className="text-neutral-600">{file.name}</span>
                <button
                  onClick={() => handleDeleteFile(file.name)}
                  className="text-red-600 hover:text-red-800 font-semibold"
                >
                  X
                </button>
              </li>
            ))}
          </ul>
          <span className="flex flex-col gap-3 w-full items-center justify-center">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 12V3.85L4.4 6.45L3 5L8 0L13 5L11.6 6.45L9 3.85V12H7ZM2 16C1.45 16 0.979167 15.8042 0.5875 15.4125C0.195833 15.0208 0 14.55 0 14V11H2V14H14V11H16V14C16 14.55 15.8042 15.0208 15.4125 15.4125C15.0208 15.8042 14.55 16 14 16H2Z"
                fill="#1C1B1F"
              />
            </svg>
            Click to upload or drag and drop
          </span>
          <input
            id="files"
            type="file"
            className="hidden"
            accept=".pdf,.doc"
            multiple
            onChange={handleFileChange}
          />
        </Label>
      </div>
      <Button
        disabled={isDisabled}
        className={cn("w-full max-w-[150px]", isButtonFull&&"max-w-full")}
        variant={isDestructive ? "destructive" : "default"}
        onClick={() => handleSubmit(files)}
      >
        {buttonText || "Proceed"}
      </Button>
    </div>
  );
};
