import React from "react";
import { DataTable } from "./data-table";
import { WaiverImportsColumns } from "./columns";
import { useStore } from "@/contexts/agentStore";
import { ImportItem } from "@/lib/interfaces";

export default function WaiverImportsTable({import_items}:{import_items:ImportItem[]}) {
  const agentStore = useStore((state) => state);
  return (
    <div>
      <DataTable
        columns={WaiverImportsColumns}
        data={import_items}
      />
    </div>
  );
}
