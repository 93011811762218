import React from "react";
import { DataTable } from "./data-table";
import { UserAdministrationColumns } from "./columns";
import { useStore } from "@/contexts/agentStore";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { getBackendUrl } from "@/lib/utils";

interface Department {
  id: number;
  department_id: string;
  department_name: string;
  department_description: string;
  department_roles: string;
  created_date: string;
  created_by: string;
  created_at: string;
  updated_at: string;
}

export default function DepartmentsTable() {
  const { data, isLoading } = useQuery<Department[]>({
    queryKey: ["all-departments"],
    queryFn: async () => {
      const { data }: { data?: { departments: Department[] } } =
        await axios.get(`${getBackendUrl()}/get-all-department`);

      return data?.departments || [];
    },
  });
  return (
    <div>
      <DataTable columns={UserAdministrationColumns} data={data || []} />
    </div>
  );
}
