import React, { useState } from "react";
import { DataTable } from "./data-table";
import { GeneratedWaiversColumns } from "./columns";
import { useStore } from "@/contexts/agentStore";
import { waiverRequests } from "@/lib/dummy-data/waiverRequests";
import { generatedWaivers } from "@/lib/dummy-data/generatedWaivers";
import { Switch } from "@/components/ui/switch";
import LoaderWrapper from "@/components/ui/loader-wrapper";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { getBackendUrl } from "@/lib/utils";

export default function GeneratedWaiversTable() {
  const [showApplied, setShowApplied] = useState(false);
  const agentStore = useStore((state) => state);
  const { data, isLoading } = useQuery({
    queryKey: ["generated-waivers"],
    queryFn: async () => {
      try {
        const { data } = await axios.get(
          `${getBackendUrl()}/get-all-generated-waiver-code`
        );
        const generated_waivers = data?.allwaivercode.map((item: any) => ({
          id: item.waiver_id,
          waiverValue: item.waiver_value,
          generatedDate: item.created_at,
          generatedBy: item.created_by,
          status: item.usage_status,
          waiverType: "Import Clearance Application",
        }));
        return generated_waivers || [];
      } catch (error) {}
    },
  });
  const filteredGeneratedWaivers = data?.filter((waiver: any) =>
    !showApplied ? waiver.status === null : waiver.status !== null
  );
  return (
    <LoaderWrapper isLoading={isLoading}>
      <div className="flex flex-col mt-10">
        <DataTable
          columns={GeneratedWaiversColumns}
          data={filteredGeneratedWaivers || []}
        />
        <div className="flex items-center mb-4">
          <Switch checked={showApplied} onCheckedChange={setShowApplied} />
          <span className="ml-2">
            {showApplied
              ? "Show Only Applied Waivers"
              : "Show Only Unapplied Waivers"}
          </span>
        </div>
      </div>
    </LoaderWrapper>
  );
}
