import { stateLabels } from "@/lib/site-data";
import { phoneNumberValidator } from "@/lib/utils";
import { z } from "zod";

export const IndividualUpdateForm = z.object({
  fullName: z.string().min(2, {
    message: "Username must be at least 2 characters.",
  }),
  phoneNumber: z
    .string()
    .min(2, {
      message: "Phone Number is required.",
    })
    .refine((val) => phoneNumberValidator(val)),
  state: z.string().min(2, { message: "State Is Required." }),
  lga: z.string().min(2, {
    message: "LGA is required.",
  }),
  street: z.string().min(2, {
    message: "Street is required.",
  }),
  association: z.string().min(1),
});

export type IndividualUpdateFormType = z.infer<typeof IndividualUpdateForm>;
