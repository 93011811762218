import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function phoneNumberValidator(phoneNumber: string) {
  let regex = /^(?:\+234|234|0)(7[0-9]|8[0-9]|9[0-9])[0-9]{8}$/;
  return regex.test(phoneNumber);
}

export function emailValidator(email: string) {
  let regex = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;
  return regex.test(email);
}

export function getPercentage(val: number, total: number) {
  return ((val / total) * 100).toFixed(2);
}

export function generateBreadcrumbs(
  path: string
): { label: string; href: string }[] {
  const segments = path?.split("/").filter(Boolean); // Split by "/" and remove empty segments
  let currentPath = "";
  const breadcrumbs: { label: string; href: string }[] = [];

  segments.forEach((segment) => {
    currentPath += `/${segment}`;
    breadcrumbs.push({
      label:
        segment.charAt(0).toUpperCase() + segment.slice(1).replace(/-/g, " "), // Capitalize and replace hyphens with spaces
      href: currentPath,
    });
  });

  return breadcrumbs;
}

export function getBackendUrl() {
  return import.meta.env.PROD
    ? "https://navishub.net/backend/api"
    : "https://navishub.net/backend/api";
}

export function formatDate(dateString: string): string {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [year, month, day] = dateString.split("-").map(Number);

  // Get the correct suffix for the day
  const daySuffix = (day: number): string => {
    if (day % 10 === 1 && day !== 11) return "st";
    if (day % 10 === 2 && day !== 12) return "nd";
    if (day % 10 === 3 && day !== 13) return "rd";
    return "th";
  };

  const formattedDay = `${day}${daySuffix(day)}`;
  const formattedMonth = months[month - 1];

  return `${formattedDay}, ${formattedMonth}, ${year}`;
}

export function arrayToCSV(data: any) {
  if (data.length === 0) return "";

  // Extract headers from the keys of the first object
  const headers = Object.keys(data[0]);

  // Create CSV header row
  const csvRows = [headers.join(",")];

  // Loop through each row of data
  data.forEach((row: any) => {
    const values = headers.map((header) => JSON.stringify(row[header] || ""));
    csvRows.push(values.join(","));
  });

  // Join all rows with new line characters
  return csvRows.join("\n");
}

export function filterByDate(
  dateStr: string,
  filterType:
    | "All Time"
    | "A day ago"
    | "A week ago"
    | "A month ago"
    | "Past 6 months"
): boolean {
  const dateParts = dateStr.split(", ");
  const [day, month, year] = [
    parseInt(dateParts[0]),
    dateParts[1],
    parseInt(dateParts[2]),
  ];
  const monthIndex = new Date(Date.parse(month + " 1")).getMonth(); // Convert month to index (0-11)

  const date = new Date(year, monthIndex, day);
  const now = new Date();

  const timeDiff = now.getTime() - date.getTime();
  const dayMs = 24 * 60 * 60 * 1000;

  switch (filterType) {
    case "All Time":
      return true;
    case "A day ago":
      return timeDiff <= dayMs;
    case "A week ago":
      return timeDiff <= 7 * dayMs;
    case "A month ago":
      return timeDiff <= 30 * dayMs;
    case "Past 6 months":
      return timeDiff <= 6 * 30 * dayMs;
    default:
      return false;
  }
}

export function parseDocumentUrls(base_url: string, names_string: string) {
  const urls = names_string
    .split(",")
    .map((name) => ({ name, url: `${base_url}/${name.trim()}` }));

  return urls;
}

// export function splitArrayIntoParts<T>(array: T[], n: number): T[][] {
//   // If n is greater than the array length, adjust it to the array length
//   if (n > array.length) {
//     n = array.length;
//   }

//   const result: T[][] = [];
//   const partSize = Math.floor(array.length / n); // Minimum number of items in each part
//   const remainder = array.length % n; // Extra items to distribute evenly

//   let startIndex = 0;

//   for (let i = 0; i < n; i++) {
//     const currentPartSize = partSize + (i < remainder ? 1 : 0); // Add one extra item for the first 'remainder' parts
//     const part = array.slice(startIndex, startIndex + currentPartSize);
//     result.push(part);
//     startIndex += currentPartSize;
//   }

//   return result;
// }

export function splitArrayIntoParts<T>(array: T[], n: number): T[][] {
  const result: T[][] = [];
  let startIndex = 0;

  while (startIndex < array.length) {
    const part = array.slice(startIndex, startIndex + n); // Slice up to 'n' elements
    result.push(part);
    startIndex += n;
  }

  return result;
}

export function getMinMaxDates(dates: string[]): {
  minDate: Date;
  maxDate: Date;
} {
  if (dates.length === 0) {
    throw new Error("Array cannot be empty");
  }

  const parsedDates = dates.map((date) => new Date(date.split("T")[0]));
  let minDate = parsedDates[0];
  let maxDate = parsedDates[0];

  for (const date of parsedDates) {
    if (date < minDate) {
      minDate = date;
    }
    if (date > maxDate) {
      maxDate = date;
    }
  }

  return { minDate, maxDate };
}

export function getMinMax(numbers: number[]): { min: number; max: number } {
  if (numbers.length === 0) {
    return { min: 0, max: 0 };
  }

  let min = numbers[0];
  let max = numbers[0];

  for (const num of numbers) {
    if (num < min) {
      min = num;
    }
    if (num > max) {
      max = num;
    }
  }

  return { min, max };
}
