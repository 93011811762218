import React from "react";
import FalsifiedRecordsReport from "./_components/FalsifiedRecordsAnalytics";

export default function LegalPage() {
  return (
    <div>
      <FalsifiedRecordsReport />
    </div>
  );
}
