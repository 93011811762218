import React from "react";
import RevenueTopImportCategories from "../../dashboard/components/RevenueTopImportCategories";
import WaiverRecordsChart from "../../accounting/components/WaiverRecordsChart";
import RevenueByCategory from "../../accounting/components/RevenueByCategory";
import RevenueByState from "../../accounting/components/RevenueByState";
import Invoicing from "../../accounting/components/Invoicing";
import RevenueByImporterCategoryReport from "@/components/reports/RevenueByImporterCategory";

export default function AccountingAnalyticsPage() {
  return (
    <div className="flex flex-col gap-10">
      <RevenueTopImportCategories />
      <WaiverRecordsChart />
      <RevenueByCategory />
      <RevenueByState />
      <Invoicing />

      {/* // TODO: REVENUE BY IMPORTER CATEGORY */}
      <RevenueByImporterCategoryReport />
      {/* // TODO: CLEARANCE PAYMENTS CHART */}
    </div>
  );
}
