import React from "react";
import AccountingOverview from "../dashboard/components/AccountingOverview";
import RevenueTopImportCategories from "../dashboard/components/RevenueTopImportCategories";
import LegalOverview from "../dashboard/components/LegalOverview";
import ClearanceOverview from "../dashboard/components/ClearanceOverview";
import ReportOverview from "../dashboard/components/ReportOverview";

export default function ReportsPage() {
  return (
    <div className="flex flex-col gap-10">
      <AccountingOverview />
      <RevenueTopImportCategories />
      <LegalOverview />
      <ClearanceOverview />
      <ReportOverview />
    </div>
  );
}
