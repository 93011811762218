import React from "react";
import ImportClearanceApplicationsReport from "./_components/ImportClearanceApplicationsReport";
import ImportClearanceApplicationsByUserCategoryReport from "./_components/ClearanceByUserCategory";
import ImportClearanceApplicationsByItemCategory from "./_components/ClearancesByItemCategory";
import ImportClearanceApplicationsbByTopItemCategoryReport from "./_components/ClearancesByTopItemCategory";

export default function ClearancePage() {
  return (
    <div className="flex flex-col gap-10">
      <ImportClearanceApplicationsReport />
      {/* <ImportClearanceApplicationsByItemCategory /> */}
      {/* <ImportClearanceApplicationsbByTopItemCategoryReport /> */}
      <ImportClearanceApplicationsByUserCategoryReport />
    </div>
  );
}
