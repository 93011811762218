import WaiverRequestsTable from "@/components/tables/WaiverRequestsTable";
import DashboardHeader from "@/components/ui/dashboard-header";
import React from "react";

export default function WaiversView() {
  return (
    <div>
      <DashboardHeader
      title="Waiver Requests"
      caption="View all waiver requests"
      />
      <WaiverRequestsTable />
    </div>
  );
}
