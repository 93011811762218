import { DocumentsForm } from "@/components/forms/DocumentsForm";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import LoaderWrapper from "@/components/ui/loader-wrapper";
import { getBackendUrl } from "@/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";

export default function ReportForm({
  nesreaId,
  certificateNumber,
}: {
  nesreaId: string;
  certificateNumber: string;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const formSchema = z.object({
    nesreaId: z.string().min(2, { message: "Please enter the user id" }),
    certificateNumber: z
      .string()
      .min(5, { message: "Please enter the certificate number" }),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      nesreaId: nesreaId,
      certificateNumber: certificateNumber,
    },
  });
  return (
    <LoaderWrapper isLoading={isLoading}>
      <div className="w-full mb-10">
        <Form {...form}>
          <form
            className="w-full max-w-md mx-auto space-y-3"
            onSubmit={form.handleSubmit(
              async ({ certificateNumber, nesreaId }) => {}
            )}
          >
            <h4 className="font-medium text-lg text-center">
              Report Invalid Certificate
            </h4>
            <FormField
              control={form.control}
              name="nesreaId"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Nesrea ID</FormLabel>
                  <FormControl>
                    <Input {...field} placeholder="Enter your Nesrea ID" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="certificateNumber"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Certificate Number</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      placeholder="Enter your certificate number"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <DocumentsForm
              handleSubmit={async (files) => {
                try {
                  setIsLoading(true);
                  if (form.formState.isValid) {
                    const formdata = new FormData();
                    formdata.append("nesrea_id", form.getValues("nesreaId"));
                    formdata.append(
                      "certificate_number",
                      form.getValues("certificateNumber")
                    );
                    files.forEach((file) => {
                      formdata.append("files[]", file);
                    });
                    // Call the API to report the certificate
                    await axios.post(
                      `${getBackendUrl()}/report-certificate`,
                      formdata
                    );
                    toast.success("Certificate reported successfully");
                    window.location.reload();
                  }
                } catch (error) {
                  toast.error("An error occurred. Please try again");
                  setIsLoading(false);
                }
              }}
              buttonText="Report"
              isDestructive
              isButtonFull
              isDisabled={!form.formState.isValid}
            />
          </form>
        </Form>
      </div>
    </LoaderWrapper>
  );
}
