import { Button, buttonVariants } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { STATES } from "@/lib/interfaces";
import { LGAs, stateNamesMap, states } from "@/lib/site-data";
import { cn, getBackendUrl, phoneNumberValidator } from "@/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Link, useNavigate } from "react-router-dom";
import { useStore } from "@/contexts/userStore";
import { toast } from "sonner";
import { PaystackButton } from "react-paystack";
import axios from "axios";
import { PaymentForm } from "@/components/ui/payment-section";
import { useGovernmentProfileRegistrationForm } from "./_hooks";
import LoaderWrapper from "@/components/ui/loader-wrapper";
import ComponentSwitch from "@/components/ui/component-switch";

export const GovernmentProfileRegistrationForm = () => {
  const { company_form, utils } = useGovernmentProfileRegistrationForm();

  return (
    <LoaderWrapper isLoading={utils.isLoading}>
      <div className="w-full flex flex-col gap-10">
        <div className="space-y-3">
          <h4 className="font-medium text-2xl">
            {company_form.formPage <= 2 &&
              "Enter the following details to update your profile"}
            {company_form.formPage == 3 &&
              "Upload the documents to update your profile"}
            {company_form.formPage == 4 &&
              "Make a payment to complete your profile registration"}
          </h4>
        </div>

        {/* If the user has not uploaded a profile, show the Profile Registration Form */}

        <>
          <ComponentSwitch
            activeKey={company_form.formPage.toString()}
            components={company_form.formPages}
          />
        </>
      </div>
    </LoaderWrapper>
  );
};
