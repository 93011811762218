import { buttonVariants } from "@/components/ui/button";
import { useClearanceAnalytics } from "@/hooks/analytics/useClearanceAnalytics";
import { cn } from "@/lib/utils";
import React from "react";
import { Link } from "react-router-dom";

export default function ClearanceOverview() {
  const data = useClearanceAnalytics();

  const CardStats = [
    {
      title: "Total Number of Issued Clearances",
      value: Number(data?.data?.approvedApplications || 0),
    },
    {
      title: "Total Number of Pending Clearances",
      value: Number(data?.data?.pendingApplications || 0),
    },
    {
      title: "Total Number of Rejected Clearances",
      value: Number(data?.data?.disapprovedApplications || 0),
    },
  ];
  return (
    <div className="w-full shadow-[0_4px_20px_0px_#00000014] rounded-xl p-4 flex flex-col gap-5">
      <div className="flex items-center justify-between w-full">
        <h1 className="text-primary-700 text-xl font-medium">
          Clearance Overview
        </h1>
        <Link
          to={"/admin/reports/clearances"}
          className={cn(
            buttonVariants({ variant: "link" }),
            "p-0 underline text-xs"
          )}
        >
          View All
        </Link>
      </div>
      <div className="grid grid-cols-3 gap-16 w-full">
        {CardStats.map(({ title, value }, index) => (
          <div
            key={`${title} ${index}`}
            className={cn(
              "flex flex-col gap-3 p-5 rounded-xl bg-primary-50 text-primary-700",
              index == 0 && "bg-primary-700 text-primary-foreground"
            )}
          >
            <p className={cn("text-xs ")}>{title}</p>
            <h4 className="font-bold text-2xl">{value}</h4>
          </div>
        ))}
      </div>
    </div>
  );
}
