import React, { useState } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { ArrowUpDown, MoreHorizontal, Pencil } from "lucide-react";

import { Button, buttonVariants } from "@/components/ui/button";

import { cn, formatDate, getBackendUrl } from "@/lib/utils";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Checkbox } from "@/components/ui/checkbox";
import { agentStoreInitialState, useStore } from "@/contexts/agentStore";
import { CertificatesData, ImportItem, PaymentsSchema } from "@/lib/interfaces";
import { Link } from "react-router-dom";
import { format } from "fecha";
import ClearanceCertificate from "@/components/docs/certificate";
import { pdf, PDFViewer } from "@react-pdf/renderer";
import { toast } from "sonner";
import { saveAs } from "file-saver";
import axios from "axios";
import { ImporterCertificate } from ".";
import { Skeleton } from "@/components/ui/skeleton";

export const ClientsColumns: ColumnDef<ImporterCertificate>[] = [
  {
    accessorKey: "sn",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          SN
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
    cell: ({ row }) => {
      return <p>{row.index + 1}</p>;
    },
  },
  {
    accessorKey: "importer_name",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Importer Name
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
    cell: ({ row }) => {
      return (
        <p className="capitalize">
          {`${row.original.importer_name}`.toLowerCase()}
        </p>
      );
    },
  },
  {
    accessorKey: "nesrea_id",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Importer ID
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
  },
  {
    accessorKey: "created_at",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Date of Issuance
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
    cell: ({ row }) => {
      const { created_at } = row.original;
      return (
        <p>{created_at ? format(new Date(created_at), "mediumDate") : "N/A"}</p>
      );
    },
  },
  {
    accessorKey: "certificate_number",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Certificate Number
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
  },
  {
    accessorKey: "expiry_date",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Expiry Date
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
    cell: ({ row }) => {
      const { expiry_date } = row.original;
      const year = expiry_date
        ? new Date(expiry_date.split("/")[0]).getFullYear()
        : undefined;
      return <p>{year ? `Dec 31, ${year}.` : "N/A"}</p>;
    },
  },

  {
    accessorKey: "action",
    header: ({ column }) => {
      return (
        <div className="flex items-center">
          Action
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
    cell: ({ row }) => {
      const [isLoading, setIsLoading] = useState(false);
      const [importItems, setImportItems] = useState<ImportItem[]>([]);
      const fetchClearanceItems = async () => {
        setIsLoading(true);
        try {
          console.log('fetching')
          const { data } = await axios.get(
            `${getBackendUrl()}/get-certifiacte-details/${
              row.original.certificate_number
            }`
          );

          setImportItems(
            data?.clearance_details.map((item: any) => {
              return {
                itemName: item.item,
                hscode: item.hs_code,
                unit: item.item_unit,
                quantity: item.item_quantity,
                cargo_number: item.container_cargo_number,
                country_of_origin: item.country_of_origin,
                port_of_entry: item.port_of_entry,
                purpose_of_importation: item.purpose_of_importation,
                final_destination_address: item.final_destination_address,
                expected_date_of_arrival: item.expected_date_of_arrival,
                cost: item.cost,
              };
            }) || []
          );

          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          toast.error("An error occurred.");
        }
      };
      return (
        <Dialog>
          <DialogTrigger
            onClick={!importItems.length ? fetchClearanceItems : () => {}}
            className="underline text-primary-700 text-sm"
          >
            View
          </DialogTrigger>
          <DialogContent>
            {isLoading ? (
              <>
                <Skeleton className="w-full aspect-[1/1.414]" />
              </>
            ) : (
              <>
                <PDFViewer className="w-full aspect-[1/1.414]">
                  <ClearanceCertificate
                    nesrea_id={row.original.nesrea_id}
                    certificate_number={row.original.certificate_number}
                    date_issued={
                      row.original?.created_at
                        ? `${format(
                            new Date(row.original.created_at),
                            "mediumDate"
                          )}`
                        : "N/A"
                    }
                    import_items={importItems}
                  />
                </PDFViewer>
                <Button
                  onClick={async () => {
                    try {
                      const blob = await pdf(
                        <ClearanceCertificate
                          nesrea_id={row.original.nesrea_id}
                          certificate_number={row.original.certificate_number}
                          date_issued={
                            row.original?.created_at
                              ? `${format(
                                  new Date(row.original.created_at),
                                  "mediumDate"
                                )}`
                              : "N/A"
                          }
                          import_items={importItems}
                        />
                      ).toBlob();
                      saveAs(
                        blob,
                        "Environmental_Import_Clearance_Invoice.pdf"
                      );
                      toast.success("Saved Successfully.");
                    } catch (error) {
                      toast.error("An error occurred.");
                    }
                  }}
                  className={cn("w-full max-w-[80%] mx-auto border-none")}
                >
                  Download
                </Button>
              </>
            )}
          </DialogContent>
        </Dialog>
      );
    },
  },
];
