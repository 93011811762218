import React from "react";
import { DataTable } from "./data-table";
import { CertificatesAdminColumns } from "./columns";
import { useStore } from "@/contexts/agentStore";
import paymentsData from "@/lib/dummy-data/paymentsData";
import certificatesData from "@/lib/dummy-data/certificatesData";
import { certificatesAdminData } from "@/lib/dummy-data/certificatesAdminData";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { getBackendUrl } from "@/lib/utils";
import { FilterData } from "@/components/ui/filter";

export type Certificate = {
  id: number;
  nesrea_id: string;
  importer_name: string;
  importer_email: string;
  clearance_id: string;
  issuerance_date: string;
  certificate_number: string;
  expiry_date: string;
  created_by: string;
  status: string;
  created_at: string;
  updated_at: string;
  activities: string;
};
export default function CertificatesAdminTable() {
  const agentStore = useStore((state) => state);
  const { data, isLoading } = useQuery({
    queryKey: ["admin-certificates"],
    queryFn: async () => {
      type CertificatesResponse = {
        data?: {
          certificates: Certificate[];
        };
      };

      const { data }: CertificatesResponse = await axios.get(
        `${getBackendUrl()}/get-all-certificate`
      );

      return data?.certificates || [];
    },
  });

  const [data_, setData_] = React.useState<FilterData>([]);
  const [filteredData, setFilteredData] = React.useState<FilterData>([]);
  const filteredIDs = filteredData.map((d) => d.id);

  const updatedData = React.useMemo(() => {
    return data?.filter((stat) => filteredIDs.includes(`${stat.id}`)) || [];
  }, [data, filteredIDs]);

  React.useEffect(() => {
    const newData = data?.map((item) => ({
      id: `${item.id}`,
      name: item.importer_name,
      certificate_numbe: item.certificate_number,
      date: item.created_at,
      status: item.status,
    }));
    setData_(newData || []);
    if (!filteredData.length) {
      setFilteredData(newData || []);
    }
  }, [data]);
  return (
    <div>
      <DataTable
        columns={CertificatesAdminColumns}
        setData_={setData_}
        filteredData={filteredData}
        setFilteredData={setFilteredData}
        data_={data_}
        data={updatedData || []}
      />
    </div>
  );
}
