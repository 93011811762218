import { stateLabels } from "@/lib/site-data";
import { phoneNumberValidator } from "@/lib/utils";
import { string, z } from "zod";

export const individual_form_schema_page_1 = z.object({
  fullName: z.string().min(2, {
    message: "Full name must be at least 2 characters.",
  }),
  phoneNumber: z
    .string()
    .min(10, {
      message: "Phone number must be at least 10 digits.",
    })
    .refine((val) => phoneNumberValidator(val), {
      message: "Invalid phone number format.",
    }),
  state: z.string().min(2, { message: "State is required." }),
  lga: z.string().min(2, {
    message: "LGA must be at least 2 characters.",
  }),
  street: z.string().min(2, {
    message: "Street must be at least 2 characters.",
  }),
});

export const individual_form_schema_page_2 = z.object({
  association: z.string().optional(),
  nin: z
    .string()
    .min(11, {
      message: "NIN must be exactly 11 digits.",
    })
    .max(11, { message: "NIN must be exactly 11 digits." }),
});

export type individual_form_schema_page_1_type = z.infer<
  typeof individual_form_schema_page_1
>;
export type individual_form_schema_page_2_type = z.infer<
  typeof individual_form_schema_page_2
>;
