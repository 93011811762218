import React from "react";
import { DataTable } from "./data-table";
import { HSCodesColumns } from "./columns";
import { useStore } from "@/contexts/agentStore";
import { categoriesData } from "@/lib/dummy-data/hsCodesData";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { getBackendUrl } from "@/lib/utils";
import { Category } from "@/lib/interfaces";

export default function ParentCategoriesTable() {
  const agentStore = useStore((state) => state);
  const { data } = useQuery({
    queryKey: ["categories"],
    queryFn: async () => {
      const { data }: { data?: { category: any[] } } = await axios.get(
        `${getBackendUrl()}/get-categories`
      );

      const processedData: Category[] =
        data?.category.map((category) => ({
          id: category.categoryId,
          label: category.categoryLabel,
          hscode: `${category.categoryCode}.0.0.0`,
          cost: category.categoryCost,
        })) || [];

      return processedData;
    },
  });
  return (
    <div>
      <DataTable columns={HSCodesColumns} data={data || []} />
    </div>
  );
}
