import * as React from "react";
import { TrendingUp } from "lucide-react";
import { Label, Pie, PieChart } from "recharts";

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { useClearanceAnalytics } from "@/hooks/analytics/useClearanceAnalytics";

const chartConfig = {
  "chemical-raw-materials": {
    label: "Chemical (Raw Materials)",
    color: "rgb(var(--primary))",
  },
  "used-electrical-and-electronics-equipment": {
    label: "Used Electrical and Electronics Equipment",
    color: "rgb(var(--secondary))",
  },
  "used-auto-parts-vehicles": {
    label: "individual",
    color: "#254D55",
  },
  "food-and-drugs": {
    label: "government",
    color: "#8AC58A",
  },
} satisfies ChartConfig;

export function ImportClearanceByTopImportCategoryChart({
  data,
}: {
  data: ReturnType<typeof useClearanceAnalytics>["data"];
}) {
  const chartData = [
    {
      category: "Chemical Raw Materials",
      count: 100,
      fill: "var(--color-chemical-raw-materials)",
    },
    {
      category: "Used Electrical and Electronics Equipment",
      count: 30,
      fill: "var(--color-used-electrical-and-electronics-equipment)",
    },
    {
      category: "Used Auto Parts/Vehicles",
      count: 120,
      fill: "var(--color-used-auto-parts-vehicles)",
    },
    {
      category: "Food and Drugs",
      count: 50,
      fill: "var(--color-food-and-drugs)",
    },
  ];


  const legendData = Object.entries(chartConfig).map(([key, value]) => ({
    label: value.label,
    color: value.color,
  }));
  return (
    <div className="flex flex-col">
      <div className="flex-1 flex justify-center items-center pb-0">
        <ChartContainer
          config={chartConfig}
          className="aspect-square min-w-[300px] max-h-[300px]"
        >
          <PieChart>
            <ChartTooltip cursor={false} content={<ChartTooltipContent />} />
            <Pie
              data={chartData}
              dataKey="count"
              nameKey="category"
              strokeWidth={2}
              stroke="white"
            ></Pie>
          </PieChart>
        </ChartContainer>
        <div className="flex flex-col w-full">
          {legendData.map((data, index) => (
            <div className="flex items-center gap-1">
              <div
                className="w-2 min-w-2 aspect-square rounded-full"
                style={{ backgroundColor: data.color }}
              ></div>
              <span className="capitalize text-sm">{data.label}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
