import React from "react";

export default function SeaPort() {
  return (
    <div>
      <div className="w-full">
        <img
          src="/images/animated-elements/crane.png"
          className="w-[25%]"
          alt=""
        />
      </div>
      <div className="w-full relative">
        <div className="w-full flex flex-col gap-2 absolute bottom-0 right-0 h-full z-10">
          <div className="flex-1 relative">
            <img
              src="/images/animated-elements/boat.png"
              className="w-[10%] absolute bottom-0 right-10 steady-rocking-boat"
              alt=""
            />
          </div>
          <div className="flex-1 relative">
            <img
              src="/images/animated-elements/ship.png"
              className="w-[25%] absolute bottom-0 left-44 rocking-ship"
              alt=""
            />
          </div>
        </div>
        <div className="w-fit flex animate-sea">
          <img
            src="/images/animated-elements/sea.png"
            className="min-w-[100vw]"
            alt=""
          />
          <img
            src="/images/animated-elements/sea.png"
            className="min-w-[100vw]"
            alt=""
          />
          <img
            src="/images/animated-elements/sea.png"
            className="min-w-[100vw]"
            alt=""
          />
          <img
            src="/images/animated-elements/sea.png"
            className="min-w-[100vw]"
            alt=""
          />
        </div>
      </div>
    </div>
  );
}
