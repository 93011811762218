"use client";

import * as React from "react";
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ChevronLeft, ChevronRight, PlusCircle, Search } from "lucide-react";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Button, buttonVariants } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { arrayToCSV, cn, getBackendUrl } from "@/lib/utils";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { Link } from "react-router-dom";
import { useStore } from "@/contexts/userStore";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { toast } from "sonner";
import CSVExport from "@/components/ui/csv-export";
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
} from "@/components/ui/form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { importDocumentationData } from "@/lib/dummy-data/importDocumentationData";
import { userTypes } from "@/lib/site-data";
import {
  AGENT_PERMISSIONS,
  COMPANY_PERMISSIONS,
  GOVERNMENT_PERMISSIONS,
  INDIVIDUAL_PERMISSIONS,
  INITIAL_STATE_PERMISSIONS,
  PERMISSIONS,
} from "@/lib/permissions";
import { Checkbox } from "@/components/ui/checkbox";
import { Label } from "@/components/ui/label";
import axios from "axios";

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
}

export function DataTable<TData, TValue>({
  columns,
  data,
}: DataTableProps<TData, TValue>) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [isActionLoading, setIsActionLoading] = React.useState(false);
  const userStore = useStore((state) => state.user);
  const formSchema = z.object({
    importerType: z.string().min(1),
  });
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      importerType: "",
    },
  });

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 20,
      },
    },
  });

  const [selectedPermissions, setSelectedPermissions] = React.useState(
    INITIAL_STATE_PERMISSIONS
  );

  const permission_templates = {
    agent: AGENT_PERMISSIONS,
    company: COMPANY_PERMISSIONS,
    government: GOVERNMENT_PERMISSIONS,
    individual: INDIVIDUAL_PERMISSIONS,
  };

  return (
    <div className="rounded-xl border border-neutral-300 overflow-hidden flex flex-col gap-3">
      <div className="">
        <div className="flex items-center justify-between px-6 py-7 pb-0">
          <h4 className="text-lg text-primary-700 font-medium">Manage Role</h4>
          <div className="flex items-center gap-2">
            <CSVExport data={[]} fileName="CSV Data" />
            <Dialog>
              <DialogTrigger>
                <Button>
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.0013 4.66699V16.3337M4.16797 10.5003H15.8346"
                      stroke="white"
                      strokeWidth="1.67"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Add
                </Button>
              </DialogTrigger>
              <DialogContent className="w-full min-w-[700px] max-h-[85vh] overflow-y-auto">
                <Form {...form}>
                  <form
                    className="flex flex-col gap-4 pt-7"
                    onSubmit={form.handleSubmit(async ({ importerType }) => {
                      try {
                        setIsActionLoading(true);
                        console.log(selectedPermissions);
                        await axios.post(`${getBackendUrl()}/add-role`, {
                          account_type: importerType,
                          roles: selectedPermissions,
                          created_by: userStore.email,
                        });
                        setIsActionLoading(false);
                        toast.success("Permissions assigned successfully");
                        form.reset();
                        setSelectedPermissions(INITIAL_STATE_PERMISSIONS);
                      } catch (error) {
                        setIsActionLoading(false);
                        toast.error("Failed to assign permissions");
                      }
                    })}
                  >
                    <div className="flex w-full justify-between">
                      <h1 className="text-lg font-medium text-primary-700">
                        User Administration
                      </h1>
                      <Button
                        type="submit"
                        disabled={isActionLoading || !form.formState.isValid}
                      >
                        Create New Permission
                      </Button>
                    </div>
                    <FormField
                      control={form.control}
                      name="importerType"
                      render={({ field }) => (
                        <FormItem className="flex flex-col gap-2">
                          <FormLabel>Importer Type</FormLabel>
                          <Select
                            {...field}
                            onValueChange={(val) => {
                              form.setValue("importerType", val);
                              setSelectedPermissions(
                                permission_templates[
                                  val as keyof typeof permission_templates
                                ]
                              );
                              field.onBlur();
                            }}
                          >
                            <SelectTrigger className="w-full max-w-sm capitalize">
                              <SelectValue placeholder="Importer Type" />
                            </SelectTrigger>
                            <SelectContent>
                              {userTypes.map((type) => (
                                <SelectItem
                                  className="capitalize"
                                  key={type}
                                  value={type}
                                >
                                  {type}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        </FormItem>
                      )}
                    />

                    <div className="flex flex-col gap-2">
                      <h3 className="font-medium text-primary-700">
                        Assign Roles
                      </h3>
                      <div className="w-full p-2 bg-secondary-50 grid grid-cols-3">
                        <h1 className="uppercase text-xl font-medium">
                          module
                        </h1>
                        <h1 className="uppercase text-xl font-medium">
                          permissions
                        </h1>
                      </div>
                      {PERMISSIONS.map((permission) => (
                        <div className="w-full p-3 bg-[#F1F1F1] grid grid-cols-3">
                          <div className="flex items-center gap-2">
                            <Checkbox
                              id={permission.module}
                              checked={
                                selectedPermissions.find(
                                  (p) => p.module === permission.module
                                )?.isAllowed
                              }
                              onCheckedChange={(isChecked) => {
                                setSelectedPermissions((prev) => {
                                  return prev.map((p) => {
                                    if (p.module === permission.module) {
                                      return {
                                        ...p,
                                        isAllowed: isChecked as boolean,
                                        permissions: isChecked
                                          ? permission.permissions
                                          : [],
                                      };
                                    }
                                    return p;
                                  });
                                });
                              }}
                            />
                            <Label
                              className="capitalize cursor-pointer text-xs font-normal"
                              htmlFor={permission.module}
                            >
                              {permission.module.split("-").join(" ")}
                            </Label>
                          </div>
                          {permission.permissions.map((perm, index) => (
                            <div className="flex items-center gap-2">
                              <Checkbox
                                id={`${perm}-${index}-${permission.module}`}
                                checked={selectedPermissions
                                  .find((p) => p.module === permission.module)
                                  ?.permissions.includes(perm)}
                                onCheckedChange={(isChecked) => {
                                  setSelectedPermissions((prev) => {
                                    return prev.map((p) => {
                                      if (p.module === permission.module) {
                                        return {
                                          ...p,
                                          permissions: isChecked
                                            ? [...p.permissions, perm]
                                            : p.permissions.filter(
                                                (p) => p !== perm
                                              ),
                                        };
                                      }
                                      return p;
                                    });
                                  });
                                }}
                              />
                              <Label
                                className="capitalize cursor-pointer text-xs font-normal"
                                htmlFor={`${perm}-${index}-${permission.module}`}
                              >
                                {perm}
                              </Label>
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  </form>
                </Form>
              </DialogContent>
            </Dialog>
          </div>
        </div>
        <p className="text-sm text-neutral-500 px-6">Add or remove roles</p>
      </div>
      <div className="border-y border-neutral-300">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow
                className="bg-secondary-50 hover:bg-secondary-50/80"
                key={headerGroup.id}
              >
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      className="text-neutral-600 px-6"
                      key={header.id}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  className="border-neutral-300 border-none hover:bg-secondary-50"
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell
                      className="border-none px-6 text-sm text-neutral-800 align-top"
                      key={cell.id}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex items-center justify-end space-x-2 py-4">
        <Button
          variant="outline"
          size="sm"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          <ChevronLeft size={15} />
        </Button>
        <span className="text-xs">
          {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
        </span>
        <Button
          variant="outline"
          size="sm"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          <ChevronRight size={15} />
        </Button>
      </div>
    </div>
  );
}
