import React from "react";
import { DataTable } from "./data-table";
import { InvoiceColumns } from "./columns";
import { useQuery } from "@tanstack/react-query";
import LoaderWrapper from "@/components/ui/loader-wrapper";
import axios from "axios";
import { getBackendUrl } from "@/lib/utils";

export default function InvoiceTable() {
  const { data, isLoading } = useQuery({
    queryKey: ["invoices"],
    queryFn: async () => {
      try {
        const { data } = await axios.get(
          `${getBackendUrl()}/all-clearance-application`
        );
        const invoice_details = data?.allclearanceapps
          .filter((item: any) => item.status.toLowerCase() == "approved")
          .map((item: any) => ({
            id: item.clearance_id,
            importerName: item.importer_name,
            importerCategory: item.importer_category,
            invoiceNumber: item?.clearance_id?.split("CL")[1],
            invoiceDate: item.created_at,
            status: item.payment_status == null ? "unpaid" : "paid",
          }));
        return invoice_details;
      } catch (error) {}
    },
  });

  return (
    <LoaderWrapper isLoading={isLoading}>
      <DataTable columns={InvoiceColumns} data={data || []} />
    </LoaderWrapper>
  );
}
