import AgentsRegistrationsTable from "@/components/tables/AgentsRegistrationsTable";
import RegistrationsTable from "@/components/tables/RegistrationsTable";
import { useStore } from "@/contexts/userStore";
import React from "react";

export default function RegistrationsPage() {
  const SECTION_NAME = "Profile Management";
  const MODULE_NAME = "Registrations";

  const user = useStore((state) => state.user);
  const permission_data = user.admin_role?.find(
    (role) => role.name === SECTION_NAME
  );

  const module_data = permission_data?.permissions.find(
    (perm) => perm.module == MODULE_NAME
  );

  const module_perm = module_data?.permissions;

  if (
    !permission_data ||
    !permission_data.isAllowed ||
    !module_data?.isAllowed
  ) {
    return (
      <div>
        <h1 className="text-2xl font-bold">
          You do not have access to this page
        </h1>
      </div>
    );
  }
  return (
    <div className="flex flex-col gap-4">
      {module_perm?.includes("view:registrations") && (
        <RegistrationsTable permissions={module_perm} />
      )}
      {module_perm?.includes("view:agent-registrations") && (
        <AgentsRegistrationsTable permissions={module_perm} />
      )}
    </div>
  );
}
