import * as React from "react";
import { TrendingUp } from "lucide-react";
import { Label, Pie, PieChart } from "recharts";

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { useClearanceAnalytics } from "@/hooks/analytics/useClearanceAnalytics";
import { usePaymentAnalytics } from "@/hooks/analytics/usePaymentAnalytics";

const chartConfig = {
  agent: {
    label: "agent",
    color: "rgb(var(--primary))",
  },
  company: {
    label: "company",
    color: "rgb(var(--secondary))",
  },
  individual: {
    label: "individual",
    color: "#254D55",
  },
  government: {
    label: "government",
    color: "#8AC58A",
  },
} satisfies ChartConfig;

export function RevenueByImporterCategoryChart({
  data,
}: {
  data: ReturnType<typeof usePaymentAnalytics>;
}) {
  const chartData = [
    {
      category: "agent",
      count: 0,
      fill: "var(--color-agent)",
    },
    {
      category: "company",
      count: data?.metrics?.total.company || 0,
      fill: "var(--color-company)",
    },
    {
      category: "individual",
      count: data?.metrics?.total.individual || 0,
      fill: "var(--color-individual)",
    },
    {
      category: "government",
      count: data?.metrics?.total.government || 0,
      fill: "var(--color-government)",
    },
  ];
  const totalVisitors = React.useMemo(() => {
    return chartData.reduce((acc, curr) => acc + curr.count, 0);
  }, []);

  const legendData = Object.entries(chartConfig).map(([key, value]) => ({
    label: value.label,
    color: value.color,
  }));
  return (
    <div className="flex flex-col">
      <div className="flex-1 flex justify-center items-center pb-0">
        <ChartContainer
          config={chartConfig}
          className="aspect-square min-w-[300px] max-h-[300px]"
        >
          <PieChart>
            <ChartTooltip cursor={false} content={<ChartTooltipContent />} />
            <Pie
              data={chartData}
              dataKey="count"
              nameKey="category"
              innerRadius={85}
              strokeWidth={5}
            ></Pie>
          </PieChart>
        </ChartContainer>
        <div className="flex flex-col">
          {legendData.map((data, index) => (
            <div className="flex items-center gap-1">
              <div
                className="w-2 aspect-square rounded-full"
                style={{ backgroundColor: data.color }}
              ></div>
              <span className="capitalize">{data.label}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
