import HSCodesTable from "@/components/tables/HSCodesTable";
import React from "react";
import ParentCategoriesTable from "./_tables/ParentCategoriesTable";

export default function CategoriesPage() {
  return (
    <div>
      <ParentCategoriesTable />
    </div>
  );
}
