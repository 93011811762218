import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useStore } from "@/contexts/agentStore";
import { zodResolver } from "@hookform/resolvers/zod";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { DocumentsForm } from "../DocumentsForm";
import { toast } from "sonner";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { getBackendUrl } from "@/lib/utils";
import { useStore as useStore_ } from "@/contexts/userStore";
import { AllClearanceApplicationsTableData } from "@/pages/dashboard/applications/ImporterView/all-clearance-applications-table/_interfaces";
import LoaderWrapper from "@/components/ui/loader-wrapper";

const formSchema = z.object({
  clearanceApplication: z.string().min(2, { message: "" }),
  percentage: z.string().min(1, { message: "" }).max(100, { message: "" }),
  comments: z.string().min(2, { message: "" }),
});

export type formSchemaType = z.infer<typeof formSchema>;

export default function WaiverRequestForm() {
  const agentStore = useStore((state) => state);
  const userStore = useStore_((state) => state.user);
  const [files, setFiles] = useState<File[]>([]);
  const navigate = useNavigate();
  const [isActionLoading, setIsActionLoading] = useState(false);
  const isAgent = userStore.account_type != "agent";

  const { data, isLoading } = useQuery({
    queryKey: ["user-applications"],
    queryFn: async () => {
      const { data } = await axios.get(
        `${getBackendUrl()}/get-clearance-applications/${userStore.email}`
      );

      const formattedData: AllClearanceApplicationsTableData[] =
        data?.UserAppList.map((item: any) => ({
          id: item.clearance_id,
          name: item.importer_name,
          category: item.importer_category,
          dateCreated: item.created_at,
          status: item.status,
          payment_status: item.payment_status,
        })).filter((item: any) => {
          return (
            item.category !== "individual" && item.payment_status !== "success"
          );
        });

      return formattedData;
    },
  });

  const form = useForm<formSchemaType>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      clearanceApplication: "",
      percentage: "",
      comments: "",
    },
  });

  useEffect(() => {
      const subscription = form.watch((value, { name }) => {
        if (name) {
          form.trigger(name);
        }
      });
      return () => subscription.unsubscribe();
    }, [form]);

  const handleSubmit = async (values: formSchemaType) => {
    // let today = new Date();
    // agentStore.addWaiverRequest({
    //   id: "NESREA/24/008",
    //   item: "Import Item",
    //   amount: "300",
    //   dateCreated: `${today.getFullYear()}-${
    //     today.getMonth() + 1
    //   }-${today.getDay()}`,
    //   status: "Pending",
    // });
    // navigate("/dashboard/waivers");
  };
  const handleDocumentsUpload = async (documents: File[]) => {
    form.trigger();
    if (!form.formState.isValid) {
      toast.error("Please fill in all required fields.");
      return;
    }
    if (documents.length === 0) {
      toast.error("No document selected");
      return;
    }
    let total_mb = 0;
    documents.forEach((file) => {
      total_mb += file.size / 1024 / 1024;
    });
    if (total_mb > 3) {
      toast.error("Total file size must not be more than 3MB");
      return;
    }

    try {
      setIsActionLoading(true);
      const formValues = form.getValues();
      const waiverFormData = new FormData();
      documents.forEach((doc) => waiverFormData.append("files[]", doc));
      waiverFormData.append("clearance_id", formValues.clearanceApplication);
      waiverFormData.append("created_by", userStore.email);
      waiverFormData.append("comments", formValues.comments);
      waiverFormData.append("percentage", formValues.percentage);

      await axios.post(
        `${getBackendUrl()}/store-waiver-request`,
        waiverFormData
      );

      toast.success("Waiver Request Uploaded");
      navigate("/user/waivers");
    } catch (error) {
      setIsActionLoading(false);
      toast.error("An error occurred");
    }
  };
  return (
    <LoaderWrapper isLoading={isLoading || isActionLoading}>
      <>
        <Form {...form}>
          <form
            className="flex flex-col gap-10"
            onSubmit={form.handleSubmit(handleSubmit)}
          >
            <h2 className="font-medium text-2xl">
              Input the necessary details to request for your waiver code
            </h2>
            <FormField
              control={form.control}
              name="clearanceApplication"
              render={({ field }) => (
                <FormItem className="w-full flex gap-2">
                  <FormLabel className="flex-[0.2]">
                    Waiver Request Application
                  </FormLabel>
                  <FormControl className="flex-1 !m-0">
                    <div>
                      <Select
                        name={field.name}
                        disabled={!data?.length}
                        onValueChange={
                          !data?.length
                            ? () => {}
                            : (value) => {
                                form.setValue("clearanceApplication", value);
                                field.onBlur();
                                form.trigger("clearanceApplication");
                              }
                        }
                        value={field.value}
                      >
                        <SelectTrigger className="w-full">
                          <SelectValue
                            placeholder={
                              !data?.length
                                ? "No Approved or Pending Applications Available"
                                : "Select Clearance Application"
                            }
                          />
                        </SelectTrigger>
                        <SelectContent>
                          {data
                            ?.sort((a, b) => a.name.localeCompare(b.name))
                            .map((unit) => (
                              <SelectItem key={unit.id} value={unit.id}>
                                {unit.name} - {unit.id}
                              </SelectItem>
                            ))}
                        </SelectContent>
                      </Select>
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="percentage"
              render={({ field }) => (
                <FormItem className="w-full flex gap-2">
                  <FormLabel className="flex-[0.2]">Percentage</FormLabel>
                  <FormControl className="flex-1 !m-0">
                    <Input
                      type="number"
                      max={100}
                      min={0}
                      placeholder="Enter value"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="comments"
              render={({ field }) => (
                <FormItem className="w-full flex gap-2">
                  <FormLabel className="flex-[0.2]">Comments</FormLabel>
                  <FormControl className="flex-1 !m-0">
                    <Input type="text" placeholder="Enter comment" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <DocumentsForm
              handleSubmit={handleDocumentsUpload}
              buttonText="Apply"
            />
          </form>
        </Form>
      </>
    </LoaderWrapper>
  );
}
