import React from "react";
import AccountingOverview from "./components/AccountingOverview";
import RevenueTopImportCategories from "./components/RevenueTopImportCategories";
import LegalOverview from "./components/LegalOverview";
import ClearanceOverview from "./components/ClearanceOverview";
import ReportOverview from "./components/ReportOverview";
import FalsifiedRecordsTable from "@/components/tables/FalsifiedRecordsTable";
import { useStore } from "@/contexts/userStore";

const SECTION_NAME = "Dashboard";

export default function DashboardPage() {
  const user = useStore((state) => state.user);
  const permission_data = user.admin_role?.find(
    (role) => role.name === SECTION_NAME
  );

  if (!permission_data || !permission_data.isAllowed) {
    return (
      <div>
        <h1 className="text-2xl font-bold">
          You do not have access to this page
        </h1>
      </div>
    );
  }

  const module_perms = permission_data?.permissions;
  const perm = module_perms[0];

  return (
    <div className="w-full flex flex-col gap-16">
      {perm.permissions.includes("view:accounting-overview") && (
        <AccountingOverview />
      )}
      {/* <RevenueTopImportCategories /> */}
      {/* <LegalOverview /> */}
      {perm.permissions.includes("view:falsified-records") && (
        <FalsifiedRecordsTable />
      )}

      {perm.permissions.includes("view:clearance-overview") && (
        <ClearanceOverview />
      )}

      {perm.permissions.includes("view:user-base-summary") && <ReportOverview />}
    </div>
  );
}
