import ConfirmationModal from "@/components/modals/ConfirmationModal";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormItem,
  FormLabel,
  FormField,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectValue,
  SelectTrigger,
  SelectItem,
  SelectContent,
} from "@/components/ui/select";
import { zodResolver } from "@hookform/resolvers/zod";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useNavigate } from "react-router-dom";
import { useStore } from "@/contexts/userStore";
import axios from "axios";
import { getBackendUrl } from "@/lib/utils";
import { toast } from "sonner";
import LoaderWrapper from "@/components/ui/loader-wrapper";

export default function GenerateWaiverForm() {
  const [showModal, setShowModal] = useState(false);
  const userStore = useStore((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const formSchema = z.object({
    type: z.enum(["import-clearance-application"]),
    percentage: z.string().min(1, { message: "Percentage is required" }),
    validityPeriod: z.enum(["1-month", "3-months", "6-months", "1-year"]),
  });
  type formSchemaType = z.infer<typeof formSchema>;
  const form = useForm<formSchemaType>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      type: "import-clearance-application",
      percentage: "",
      validityPeriod: "1-month",
    },
  });
  return (
    <LoaderWrapper isLoading={isLoading}>
      {showModal && (
        <ConfirmationModal
          cta={{
            handleClick: () => {
              navigate("/admin/accounting/waiver");
            },
            txt: "Back to Waiver Details",
          }}
          title="Waiver generated successfully"
        />
      )}
      <Form {...form}>
        <form
          className="flex flex-col gap-3"
          onSubmit={form.handleSubmit(async (data) => {
            setIsLoading(true);
            try {
              const waiver_body = {
                waiver_type: "Clearance Application",
                percentage: `${data.percentage}`,
                validity_period: data.validityPeriod,
                created_by: `${userStore.id}`,
              };

              await axios.post(
                `${getBackendUrl()}/generate-waiver-code`,
                waiver_body
              );
              setIsLoading(false);
              setShowModal(true);
            } catch (error) {
              setIsLoading(false);
              toast.error("Error Occurred While Generating Waiver");
            }
          })}
        >
          <FormField
            control={form.control}
            name="type"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  Waiver Type <span className="text-red-500">*</span>
                </FormLabel>
                <FormControl>
                  <Select
                    {...field}
                    onValueChange={(value) => field.onChange(value)}
                    defaultValue={field.value}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select type" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="import-clearance-application">
                        Import Clearance Application
                      </SelectItem>
                    </SelectContent>
                  </Select>
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="percentage"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  Percentage <span className="text-red-500">*</span>
                </FormLabel>
                <FormControl>
                  <Input type="number" min={0} max={100} {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="validityPeriod"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  Validity Period <span className="text-red-500">*</span>
                </FormLabel>
                <FormControl>
                  <Select
                    {...field}
                    onValueChange={(value) => field.onChange(value)}
                    defaultValue={field.value}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select validity period" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="1-month">1 Month</SelectItem>
                      <SelectItem value="3-months">3 Months</SelectItem>
                      <SelectItem value="6-months">6 Months</SelectItem>
                      <SelectItem value="1-year">1 Year</SelectItem>
                    </SelectContent>
                  </Select>
                </FormControl>
              </FormItem>
            )}
          />
          <div className="w-full flex justify-end">
            <Button type="submit" className="w-fit">
              Generate Waiver
            </Button>
          </div>
        </form>
      </Form>
    </LoaderWrapper>
  );
}
