import React from "react";
import { DataTable } from "./data-table";
import { ClientsColumns } from "./columns";
import { useStore } from "@/contexts/agentStore";
import { getBackendUrl } from "@/lib/utils";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export default function ClientsTableAdmin({ email }: { email: string }) {
  const agentStore = useStore((state) => state);
  const { data, isLoading } = useQuery({
    queryKey: ["agent-clients"],
    queryFn: async () => {
      const { data }: { data: { Clientlist: any[] } } = await axios.get(
        `${getBackendUrl()}/get-my-client/${email}`
      );
      return data.Clientlist.map((item) => ({
        name: item.importer_name,
        category: item.importer_category,
        dateCreated: item.created_at,
        status: item.status,
      }));
    },
  });
  return (
    <div>
      <DataTable columns={ClientsColumns} data={data || []} />
    </div>
  );
}
