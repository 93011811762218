import React from "react";
import Admins from "../../admin-panel/_components/Admins";
import UserLoginHeatMap from "../../admin-panel/_components/UserLoginHeatMap";

export default function AdminPanelReportPage() {
  return (
    <div className="flex flex-col gap-10">
      <Admins />
      <UserLoginHeatMap />
    </div>
  );
}
