import React, { useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, UseFormReturn } from "react-hook-form";
import { z } from "zod";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { toast } from "sonner";
import { PasswordInput } from "@/components/ui/password-input";
import { formStages } from ".";
import { cn, getBackendUrl } from "@/lib/utils";
import axios from "axios";
import { CheckCircle, XCircle } from "lucide-react";

export default function RegForm({
  setFormStage,
  activeUserType,
  formSchema,
  form,
  passwordErrorMessage,
}: {
  setFormStage: React.Dispatch<React.SetStateAction<formStages>>;
  activeUserType: string;
  formSchema: z.ZodEffects<
    z.ZodObject<
      {
        email: z.ZodString;
        password: z.ZodString;
      },
      "strip",
      z.ZodTypeAny,
      {
        email: string;
        password: string;
      },
      {
        email: string;
        password: string;
      }
    >,
    {
      email: string;
      password: string;
    },
    {
      email: string;
      password: string;
    }
  >;
  form: UseFormReturn<z.infer<typeof formSchema>>;
  passwordErrorMessage: string;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const passwordMessages = [
    "Password must be at least 8 characters.",
    "Password must contain at least one lowercase letter.",
    "Password must contain at least one uppercase letter.",
    "Password must contain at least one number.",
    "Password must contain at least one special character.",
  ];

  const validatePassword = (password: string) => {
    const passwordCriteria = [
      { message: "Password must be at least 8 characters.", regex: /.{8,}/ },
      {
        message: "Password must contain at least one lowercase letter.",
        regex: /[a-z]/,
      },
      {
        message: "Password must contain at least one uppercase letter.",
        regex: /[A-Z]/,
      },
      { message: "Password must contain at least one number.", regex: /\d/ },
      {
        message: "Password must contain at least one special character.",
        regex: /[!@#$%^&*(),.?":{}|<>]/,
      },
    ];

    return passwordCriteria
      .filter((criteria) => !criteria.regex.test(password))
      .map((criteria) => criteria.message);
  };

  async function onSubmit(data: z.infer<typeof formSchema>) {
    try {
      setIsLoading(true);
      await axios.post(`${getBackendUrl()}/user-register`, {
        email: data.email,
        password: data.password,
        account_type: activeUserType,
        name: "null",
      });
      // localStorage.setItem("nesrea_user_email", data.email);
      // localStorage.setItem("nesrea_user_form_stage", "otp");
      toast.success("You have been registered successfully", {
        description: "Please check your email for the OTP",
      });
      setFormStage("otp");
      setIsLoading(false);
    } catch (error: any) {
      const errMessage = error.response?.data?.message;
      toast.error(errMessage || "An error occurred while submitting the form", {
        description: "Please try again",
      });
      setIsLoading(false);
    }
  }

  useEffect(() => {
    const subscription = form.watch((value, { name }) => {
      if (name) {
        form.trigger(name);
      }
    });
    return () => subscription.unsubscribe();
  }, [form]);
  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="max-w-sm w-full space-y-6 text-left"
      >
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="text-primary">
                Email <span className="text-destructive">*</span>
              </FormLabel>
              <FormControl>
                <Input
                  placeholder={
                    activeUserType == "government"
                      ? "example@domain.gov.ng"
                      : "example@domain.com"
                  }
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem className="flex flex-col">
              <FormLabel className="text-primary">
                Password <span className="text-destructive">*</span>
              </FormLabel>
              <FormControl>
                <PasswordInput placeholder="********" {...field} />
              </FormControl>

              {form.formState.dirtyFields.password && (
                <ul className="mt-6 gap-3 grid">
                  {passwordMessages.map((message, index) => {
                    const isSatisfied = !validatePassword(field.value).includes(
                      message
                    );
                    return (
                      <div key={index} className="flex gap-2">
                        <span
                          className={cn(
                            "text-xs",
                            isSatisfied ? "text-green-600" : "text-red-600"
                          )}
                        >
                          {isSatisfied ? (
                            <CheckCircle size={15} />
                          ) : (
                            <XCircle size={15} />
                          )}
                        </span>
                        <li
                          className={cn(
                            "text-xs",
                            isSatisfied ? "text-green-600" : "text-red-600"
                          )}
                        >
                          {message}
                        </li>
                      </div>
                    );
                  })}
                </ul>
              )}
            </FormItem>
          )}
        />
        <Button
          type="submit"
          className="w-full"
          disabled={isLoading || !form.formState.isValid}
        >
          {isLoading ? "Submitting..." : "Sign Up"}
        </Button>
      </form>
    </Form>
  );
}
