import React from "react";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { cn } from "@/lib/utils";
import { User } from "lucide-react";
import { Link } from "react-router-dom";
import { useStore } from "@/contexts/userStore";

export function UserNav() {
  const handleLogOut = async () => {};
  const user = useStore((state) => state.user);
  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <div
            className={cn(
              "flex gap-3 items-center cursor-pointer p-2 rounded-full w-fit duration-300 hover:text-black"
            )}
          >
            <Button
              variant="ghost"
              className="relative h-7 w-7 rounded-full bg-transparent p-0 hover aspect-square"
            >
              <UserAvatar />
            </Button>
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          className="w-64 px-5 py-1 bg"
          align="end"
          forceMount
        >
          <div className="w-full flex flex-col">
            <div className="relative w-full h-24">
              <div className="w-full h-[80%] bg-primary-900 rounded-xl flex items-center justify-center">
                <h1 className="text-white text-sm uppercase opacity-80 font-medium">
                  Nesrea Admin
                </h1>
              </div>
              <div className="absolute left-0 bottom-0 w-[25%] bg-primary-900 border-4 border-white rounded-full aspect-square flex items-center justify-center p-2">
                <UserAvatar />
              </div>
            </div>
            <div className="w-full flex flex-col gap-5">
              <div className="w-full flex items-center justify-between">
                <div className="">
                  <h4>{user.name || "Admin"}</h4>
                  <p className="text-sm opacity-50">Nesrea Admin</p>
                </div>
                {/* <Button size={'sm'} className="rounded-full font-light text-xs px-3 py-2 h-fit" variant={"destructive"}>
                  Logout
                </Button> */}
              </div>
              <p className="text-sm opacity-50">{user.email}</p>
            </div>
          </div>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
}

const UserAvatar = () => {
  return (
    <Avatar className="h-full w-full overflow-hidden border bg-transparent border-white">
      <AvatarFallback className="bg-transparent p-1 overflow-hidden delay-0 duration-300 text-primary-foreground">
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 24 23"
          className="mt-2"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 12C15.3137 12 18 9.31371 18 6C18 2.68629 15.3137 0 12 0C8.68629 0 6 2.68629 6 6C6 9.31371 8.68629 12 12 12Z"
            fill="currentColor"
          />
          <path
            d="M12 13.999C7.03172 14.0046 3.00553 18.0307 3 22.999C3 23.5513 3.4477 23.999 3.99998 23.999H20C20.5522 23.999 21 23.5513 21 22.999C20.9945 18.0307 16.9683 14.0045 12 13.999Z"
            fill="currentColor"
          />
        </svg>
      </AvatarFallback>
    </Avatar>
  );
};
