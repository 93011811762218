import { buttonVariants } from "@/components/ui/button";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { useAdminAnalytics } from "@/hooks/analytics/useAdminAnalytics";
import { cn } from "@/lib/utils";
import React from "react";

export default function Admins() {
  const { adminsData, analyticsData } = useAdminAnalytics();
  const CardStats = [
    {
      title: "Total Number of Admins",
      value: analyticsData?.totalAdmins || 0,
    },
    {
      title: "Total Number of Online Admins",
      value: analyticsData?.activeAdmins || 0,
    },
    {
      title: "Total Number of Offline Admins",
      value: analyticsData?.inactiveAdmins || 0,
    },
  ];
  return (
    <div className="w-full shadow-[0_4px_20px_0px_#00000014] rounded-xl p-4 flex flex-col gap-5">
      <div className="flex items-center justify-between w-full">
        <h1 className="text-primary-700 text-xl font-medium">Admins</h1>
      </div>
      <div className="flex flex-wrap justify-center gap-5 w-full">
        {CardStats.map(({ title, value }, index) => (
          <div
            className={cn(
              "flex flex-col gap-3 p-5 rounded-xl flex-1 min-w-[200px] max-w-full h-full justify-between bg-primary-50 text-primary-700",
              index === 0 && "bg-primary-700 text-primary-foreground"
            )}
          >
            <p className={cn("text-xs ")}>{title}</p>
            <h4 className="font-bold text-2xl">{value}</h4>
          </div>
        ))}
      </div>
    </div>
  );
}
