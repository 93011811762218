import React, { useEffect, useState } from "react";
import { Button, buttonVariants } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { ChevronDown, ChevronRight } from "lucide-react";
import { HSCodes } from "@/lib/dummy-data/hsCodes";
import { cn, getBackendUrl } from "@/lib/utils";
import { Badge } from "@/components/ui/badge";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  countries,
  importationPurposes,
  portsData,
  units,
} from "@/lib/site-data";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import { useStore } from "@/contexts/agentStore";
import { useAddImportItemForm } from "./_hooks";
import { ImportItem } from "@/lib/interfaces";
import { DialogClose } from "@/components/ui/dialog";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { Skeleton } from "@/components/ui/skeleton";
import { DateInput } from "@/components/ui/date-input";
import { ScrollArea } from "@/components/ui/scroll-area";

const ItemSkeleton = () => <Skeleton className="w-full h-[20vh]" />;

const ImportDetailItemForm = ({
  handleSubmit,
  importItems,
}: {
  handleSubmit: (data: ImportItem) => void;
  importItems: ImportItem[];
}) => {
  const { form, hsCode, setHSCode, showDropdown, setShowDropdown } =
    useAddImportItemForm();

  const queryClient = useQueryClient();

  // useEffect(() => {
  //   form.setValue("hsCode", hsCode.map((item) => item.code).join("."));
  // });

  const [availableUnits, setAvailableUnits] = useState<typeof units>([]);
  const [costing, setCosting] = useState<{
    [key in (typeof units)[number]["id"]]?: string;
  }>({});

  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const { data: categoriesData, isLoading: isCategoriesDataLoading } = useQuery(
    {
      queryKey: ["get-categories"],
      queryFn: async () => {
        const {
          data,
        }: {
          data: {
            category?: {
              id: number;
              categoryId: string;
              categoryLabel: string;
              categoryCode: string;
              categoryCost: string;
              created_at: string;
              updated_at: string;
              status: null;
            }[];
          };
        } = await axios.get(`${getBackendUrl()}/get-categories`);
        const processedData: {
          id: number;
          categoryId: string;
          categoryLabel: string;
          categoryCode: string;
          categoryCost: {
            [key in (typeof units)[number]["id"]]?: string;
          };
          created_at: string;
          updated_at: string;
          status: null;
        }[] =
          data.category?.map((item) => ({
            ...item,
            categoryCost: JSON.parse(item.categoryCost || "{}"),
          })) || [];
        return processedData;
      },
    }
  );
  const [categoryData, setCategoryData] = useState(
    categoriesData?.find((d) => d.categoryId === selectedCategoryId)
  );

  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState("");
  const {
    data: subCategoriesData,
    isLoading: isSubCategoriesDataLoading,
    isFetching: isSubCategoriesDataFetching,
  } = useQuery({
    queryKey: ["get-sub-categories"],
    queryFn: async () => {
      if (categoryData?.categoryId) {
        const {
          data,
        }: {
          data: {
            id: number;
            categoryId: string;
            subCategoryId: string;
            subCategoryLabel: string;
            subCategoryCode: string;
            subCategoryCost: string;
            created_at: string;
            updated_at: string;
            status: null;
          }[];
        } = await axios.get(
          `${getBackendUrl()}/subcategories/${categoryData.categoryId}`
        );
        const processedData: {
          id: number;
          categoryId: string;
          subCategoryId: string;
          subCategoryLabel: string;
          subCategoryCode: string;
          subCategoryCost: {
            [key in (typeof units)[number]["id"]]?: string;
          };
          created_at: string;
          updated_at: string;
          status: null;
        }[] =
          data?.map((item) => ({
            ...item,
            subCategoryCost: JSON.parse(item.subCategoryCost || "{}"),
          })) || [];
        return processedData;
      }
      return [];
    },
  });
  const [subCategoryData, setSubCategoryData] = useState(
    subCategoriesData?.find((d) => d.subCategoryId === selectedSubCategoryId)
  );

  const [selectedItemCategoryId, setSelectedItemCategoryId] = useState("");
  const {
    data: itemCategoriesData,
    isLoading: isItemsCategoriesDataLoading,
    isFetching: isItemsCategoriesFetching,
  } = useQuery({
    queryKey: ["get-item-categories"],
    queryFn: async () => {
      if (subCategoryData?.subCategoryId) {
        const {
          data,
        }: {
          data: {
            id: number;
            subCategoryId: string;
            itemCategoryId: string;
            itemCategoryLabel: string;
            itemCategoryCode: string;
            itemCategoryCost: string;
            created_at: string;
            updated_at: string;
            status: null;
          }[];
        } = await axios.get(
          `${getBackendUrl()}/itemcategories/${subCategoryData.subCategoryId}`
        );
        const processedData: {
          id: number;
          subCategoryId: string;
          itemCategoryId: string;
          itemCategoryLabel: string;
          itemCategoryCode: string;
          itemCategoryCost: {
            [key in (typeof units)[number]["id"]]?: string;
          };
          created_at: string;
          updated_at: string;
          status: null;
        }[] =
          data?.map((item) => ({
            ...item,
            itemCategoryCost: JSON.parse(item.itemCategoryCost || "{}"),
          })) || [];
        return processedData;
      }
      return [];
    },
  });
  const [itemCategoryData, setItemCategoryData] = useState(
    itemCategoriesData?.find((d) => d.itemCategoryId === selectedItemCategoryId)
  );

  const {
    data: itemsData,
    isLoading: isItemsLoading,
    isFetching: isItemsFetching,
  } = useQuery({
    queryKey: ["get-items"],
    queryFn: async () => {
      if (itemCategoryData?.itemCategoryId) {
        const {
          data,
        }: {
          data: {
            id: number;
            itemCategoryId: string;
            itemLabel: string;
            itemId: string;
            itemCode: string;
            itemCost: string;
            created_at: string;
            updated_at: string;
            status: null;
          }[];
        } = await axios.get(
          `${getBackendUrl()}/items/${itemCategoryData.itemCategoryId}`
        );

        const processedData: {
          id: number;
          itemCategoryId: string;
          itemLabel: string;
          itemId: string;
          itemCode: string;
          itemCost: {
            [key in (typeof units)[number]["id"]]?: string;
          };
          created_at: string;
          updated_at: string;
          status: null;
        }[] =
          data.map((item) => ({
            ...item,
            itemCost: JSON.parse(item.itemCost || "{}"),
          })) || [];

        return processedData;
      }
      return [];
    },
  });
  const [selectedItemID, setSelectedItemID] = useState("");
  const [itemData, setItemData] = useState(
    itemsData?.find((d) => d.itemCategoryId === selectedItemID)
  );

  useEffect(() => {
    // handleCost(itemData?.itemCost || {});
    let queryKey = ["get-sub-categories"];
    queryClient.setQueryData(queryKey, []);
    queryClient.invalidateQueries({
      queryKey,
    });
    handleCost(categoryData?.categoryCost || {});
  }, [categoryData]);

  useEffect(() => {
    handleCost(subCategoryData?.subCategoryCost || {});
    let queryKey = ["get-item-categories"];
    queryClient.setQueryData(queryKey, []);
    queryClient.invalidateQueries({
      queryKey,
    });
    handleCost(subCategoryData?.subCategoryCost || {});
  }, [subCategoryData]);

  useEffect(() => {
    handleCost(itemCategoryData?.itemCategoryCost || {});
    let queryKey = ["get-items"];
    queryClient.setQueryData(queryKey, []);
    queryClient.invalidateQueries({
      queryKey,
    });
    handleCost(itemCategoryData?.itemCategoryCost || {});
  }, [itemCategoryData]);

  useEffect(() => {
    handleCost(itemData?.itemCost || {});
  }, [itemData]);

  const handleCost = (itemCost: {
    [key in (typeof units)[number]["id"]]?: string;
  }) => {
    const itemCostKeys = Object.keys(itemCost || {});
    if (itemCostKeys.length == 0) return;
    const newAvailableUnits = units.filter((unit) =>
      itemCostKeys.includes(unit.id)
    );
    // if (itemCost && itemCostKeys.length > 0) {
    // }
    setAvailableUnits(newAvailableUnits || []);
    setCosting(itemCost);
  };

  const getCostValue = (): string => {
    const { unit, quantity }: { unit: any; quantity: any } = form.watch();
    let unitValue: (typeof units)[number]["id"] = unit;
    const costPerUnit = costing?.[unitValue] || "0";
    const totalCost = Number(costPerUnit) * Number(quantity);
    return totalCost.toString();
  };

  return (
    <Form {...form}>
      <form
        className="flex flex-col gap-10"
        onSubmit={form.handleSubmit((data) => {
          const hs_codes = importItems.map((item) => item.hscode);
          if (hs_codes.includes(data.hsCode)) {
            toast.error(`${data.hsCode} has already been added.`);
            return;
          }
          const importItem: ImportItem = {
            itemName: data.item,
            hscode: data.hsCode,
            unit: data.unit,
            quantity: data.quantity.toString(),
            cargo_number: data.cargoNumber,
            country_of_origin: data.countryOfOrigin,
            port_of_entry: data.portOfEntry,
            purpose_of_importation: data.purposeOfImportation,
            final_destination_address: data.finalDestinationAddress,
            expected_date_of_arrival: data.expectedDateofArrival,
            cost: getCostValue(),
          };
          handleSubmit(importItem);
        })}
      >
        <FormField
          control={form.control}
          name="item"
          render={({ field }) => (
            <FormItem className="w-full flex gap-2 items-start">
              <FormLabel className="flex-[0.3] flex font-semibold">
                Item
              </FormLabel>
              <FormControl className="flex-1 !m-0">
                <div className="flex flex-col gap-4 w-full">
                  <div
                    onClick={() => setShowDropdown(!showDropdown)}
                    className="w-full p-2 rounded-lg border cursor-pointer flex items-center justify-between text-neutral-400 "
                  >
                    {!hsCode.length && (
                      <p className="font-light text-sm">Select Item</p>
                    )}
                    <div className="flex gap-2">
                      {hsCode.map((code, index) => (
                        <div className="flex gap-2" key={code.id}>
                          <Badge>{code.label}</Badge>
                          {index != hsCode.length - 1 && <ChevronRight />}
                        </div>
                      ))}
                    </div>
                    <ChevronDown size={15} />
                  </div>
                  <div className="grid grid-cols-4 gap-4">
                    {showDropdown && (
                      <>
                        <ScrollArea className="max-h-[30vh] bg-white rounded-lg overflow-hidden">
                          {categoriesData && (
                            <div className="flex flex-col w-full">
                              {categoriesData.map((category) => {
                                let isSelected = hsCode.find(
                                  (codeItem) =>
                                    codeItem.id == category.categoryId
                                );
                                return (
                                  <div
                                    className={cn(
                                      "p-2 hover:bg-primary-50 text-sm cursor-pointer hover:text-primary-700",
                                      isSelected &&
                                        "bg-primary-50 text-primary-700"
                                    )}
                                    onClick={() => {
                                      if (!isSelected) {
                                        const categoryData_ =
                                          categoriesData.find(
                                            (d) =>
                                              d.categoryId ===
                                              category.categoryId
                                          );
                                        setCategoryData(categoryData_);
                                        setHSCode((prev) => {
                                          prev[0] = {
                                            label: category.categoryLabel,
                                            code: category.categoryCode,
                                            id: category.categoryId,
                                          };
                                          return [
                                            {
                                              label: category.categoryLabel,
                                              code: category.categoryCode,
                                              id: category.categoryId,
                                            },
                                          ];
                                        });
                                        form.setValue(
                                          "hsCode",
                                          `${category.categoryCode}.0.0.0`
                                        );
                                        setSelectedCategoryId(
                                          category.categoryId
                                        );
                                      }
                                    }}
                                    key={category.categoryId}
                                  >
                                    {category.categoryLabel}
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </ScrollArea>

                        {/* SUB CATEGORY NODE */}
                        {hsCode.length > 0 && (
                          <ScrollArea className="max-h-[30vh]">
                            {isSubCategoriesDataFetching &&
                            !subCategoriesData?.length ? (
                              <>
                                <Skeleton className="w-full h-[20vh]" />
                              </>
                            ) : (
                              <div className="flex flex-col w-full">
                                {subCategoriesData?.map((category) => {
                                  let isSelected = hsCode.find(
                                    (codeItem) =>
                                      codeItem.id == category.subCategoryId
                                  );
                                  return (
                                    <div
                                      className={cn(
                                        "p-2 hover:bg-primary-50 text-sm cursor-pointer hover:text-primary-700",
                                        isSelected &&
                                          "bg-primary-50 text-primary-700"
                                      )}
                                      onClick={() => {
                                        if (!isSelected) {
                                          const subCategoryData_ =
                                            subCategoriesData.find(
                                              (d) =>
                                                d.subCategoryId ===
                                                category.subCategoryId
                                            );
                                          setSubCategoryData(subCategoryData_);
                                          setHSCode((prev) => {
                                            return [
                                              prev[0],
                                              {
                                                label:
                                                  category.subCategoryLabel,
                                                code: category.subCategoryCode,
                                                id: category.subCategoryId,
                                              },
                                            ];
                                          });
                                          form.setValue(
                                            "hsCode",
                                            `${categoryData?.categoryCode}.${category.subCategoryCode}.0.0`
                                          );
                                          form.setValue(
                                            "item",
                                            category.subCategoryLabel
                                          );
                                        }
                                      }}
                                      key={category.subCategoryId}
                                    >
                                      {category.subCategoryLabel}
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                          </ScrollArea>
                        )}

                        {/* ITEM CATEGORY NODE */}
                        {hsCode.length > 1 && (
                          <ScrollArea className="max-h-[30vh]">
                            {isItemsCategoriesFetching &&
                            !itemCategoriesData?.length ? (
                              <>
                                <ItemSkeleton />
                              </>
                            ) : (
                              <div className="flex flex-col w-full">
                                {itemCategoriesData?.map((category) => {
                                  let isSelected = hsCode.find(
                                    (codeItem) =>
                                      codeItem.id == category.itemCategoryId
                                  );
                                  return (
                                    <div
                                      className={cn(
                                        "p-2 hover:bg-primary-50 text-sm cursor-pointer hover:text-primary-700",
                                        isSelected &&
                                          "bg-primary-50 text-primary-700"
                                      )}
                                      onClick={() => {
                                        if (!isSelected) {
                                          const itemCategoryData_ =
                                            itemCategoriesData.find(
                                              (d) =>
                                                d.itemCategoryId ===
                                                category.itemCategoryId
                                            );
                                          setItemCategoryData(
                                            itemCategoryData_
                                          );
                                          setHSCode((prev) => {
                                            return [
                                              prev[0],
                                              prev[1],
                                              {
                                                label:
                                                  category.itemCategoryLabel,
                                                code: category.itemCategoryCode,
                                                id: category.itemCategoryId,
                                              },
                                            ];
                                          });
                                          form.setValue(
                                            "hsCode",
                                            `${categoryData?.categoryCode}.${subCategoryData?.subCategoryCode}.${category.itemCategoryCode}.0`
                                          );
                                          form.setValue(
                                            "item",
                                            category.itemCategoryLabel
                                          );
                                          setSelectedItemCategoryId(
                                            category.itemCategoryId
                                          );
                                        }
                                      }}
                                      key={category.itemCategoryId}
                                    >
                                      {category.itemCategoryLabel}
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                          </ScrollArea>
                        )}

                        {/* ITEM NODE */}
                        {hsCode.length > 2 && (
                          <ScrollArea className="max-h-[30vh]">
                            {isItemsFetching && !itemsData?.length ? (
                              <>
                                <ItemSkeleton />
                              </>
                            ) : (
                              <div className="flex flex-col w-full">
                                {itemsData?.map((item) => {
                                  let isSelected = hsCode.find(
                                    (codeItem) => codeItem.id == item.itemId
                                  );
                                  return (
                                    <div
                                      className={cn(
                                        "p-2 hover:bg-primary-50 text-sm cursor-pointer hover:text-primary-700",
                                        isSelected &&
                                          "bg-primary-50 text-primary-700"
                                      )}
                                      onClick={() => {
                                        if (!isSelected) {
                                          const itemData_ = itemsData?.find(
                                            (d) => d.itemId === item.itemId
                                          );
                                          setItemData(itemData_);
                                          setHSCode((prev) => {
                                            prev[3] = {
                                              label: item.itemLabel,
                                              code: (
                                                item.itemCode || "0.0.0.0"
                                              ).split(".")[3],
                                              id: item.itemId,
                                            };
                                            return [...prev];
                                          });
                                          form.setValue("item", item.itemLabel);
                                          form.setValue(
                                            "hsCode",
                                            `${item.itemCode}`
                                          );
                                          setSelectedItemID(item.itemId);
                                          field.onBlur();
                                          setShowDropdown(false);
                                        }
                                      }}
                                      key={item.itemId}
                                    >
                                      {item.itemLabel}
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                          </ScrollArea>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="hsCode"
          render={({ field }) => (
            <FormItem className="w-full flex gap-2">
              <FormLabel className="flex-[0.3]">HS Code</FormLabel>
              <FormControl className="flex-1 !m-0">
                <Input type="text" disabled {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="unit"
          render={({ field }) => (
            <FormItem className="w-full flex gap-2">
              <FormLabel className="flex-[0.3]">Unit</FormLabel>
              <FormControl className="flex-1 !m-0">
                <div>
                  <Select
                    name={field.name}
                    onValueChange={(value) => {
                      form.setValue("unit", value);
                      field.onBlur();
                    }}
                    disabled={availableUnits.length == 0}
                    value={field.value}
                  >
                    <SelectTrigger className="w-full">
                      <SelectValue
                        placeholder={
                          availableUnits.length == 0
                            ? "No Available Units"
                            : "Select unit"
                        }
                      />
                    </SelectTrigger>
                    <SelectContent>
                      {availableUnits.map((unit) => (
                        <SelectItem
                          key={unit.id}
                          className="hover:bg-muted-100 duration-300 cursor-pointer"
                          value={unit.id}
                        >
                          {unit.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="quantity"
          render={({ field }) => (
            <FormItem className="w-full flex gap-2">
              <FormLabel className="flex-[0.3]">Quantity</FormLabel>
              <FormControl className="flex-1 !m-0">
                <Input
                  type="number"
                  {...field}
                  onChange={(e) => {
                    let v = parseInt(e.target.value);
                    console.log("PARSED INT", v);
                    if (v < 1 || Number.isNaN(v)) {
                      form.setValue("quantity", 1);
                      return;
                    }

                    form.setValue("quantity", v);
                  }}
                  min={1}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="cargoNumber"
          render={({ field }) => (
            <FormItem className="w-full flex gap-2">
              <FormLabel className="flex-[0.3]">
                Container / Cargo No.
              </FormLabel>
              <FormControl className="flex-1 !m-0">
                <Input type="text" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="countryOfOrigin"
          render={({ field }) => (
            <FormItem className="w-full flex gap-2">
              <FormLabel className="flex-[0.3]">Country of Origin</FormLabel>
              <FormControl className="flex-1 !m-0">
                <div>
                  <Select
                    name={field.name}
                    onValueChange={(value) => {
                      form.setValue("countryOfOrigin", value);
                      field.onBlur();
                    }}
                    value={field.value}
                  >
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="Select country" />
                    </SelectTrigger>
                    <SelectContent>
                      {countries
                        .sort((a, b) => a.label.localeCompare(b.label))
                        .map((unit) => (
                          <SelectItem
                            className="hover:bg-muted-100 duration-300 cursor-pointer"
                            key={unit.id}
                            value={unit.label}
                          >
                            {unit.label}
                          </SelectItem>
                        ))}
                    </SelectContent>
                  </Select>
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="portOfEntry"
          render={({ field }) => (
            <FormItem className="w-full flex gap-2">
              <FormLabel className="flex-[0.3]">Port of Entry</FormLabel>
              <FormControl className="flex-1 !m-0">
                <div>
                  <Select
                    name={field.name}
                    onValueChange={(value) => {
                      form.setValue("portOfEntry", value);
                      field.onBlur();
                    }}
                    value={field.value}
                  >
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="Select port of entry" />
                    </SelectTrigger>
                    <SelectContent>
                      {portsData
                        .sort((a, b) => a.label.localeCompare(b.label))
                        .map((unit) => (
                          <SelectItem
                            className="hover:bg-muted-100 duration-300 cursor-pointer"
                            key={unit.id}
                            value={unit.label}
                          >
                            {unit.label}
                          </SelectItem>
                        ))}
                    </SelectContent>
                  </Select>
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="purposeOfImportation"
          render={({ field }) => (
            <FormItem className="w-full flex gap-2">
              <FormLabel className="flex-[0.3]">
                Purpose of Importation
              </FormLabel>
              <FormControl className="flex-1 !m-0">
                <div>
                  <Select
                    name={field.name}
                    onValueChange={(value) => {
                      form.setValue("purposeOfImportation", value);
                      field.onBlur();
                    }}
                    value={field.value}
                  >
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="Select purpose of importation" />
                    </SelectTrigger>
                    <SelectContent>
                      {importationPurposes.map((unit) => (
                        <SelectItem
                          className="hover:bg-muted-100 duration-300 cursor-pointer"
                          key={unit.id}
                          value={unit.label}
                        >
                          {unit.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="finalDestinationAddress"
          render={({ field }) => (
            <FormItem className="w-full flex gap-2">
              <FormLabel className="flex-[0.3]">Final Dest. Address</FormLabel>
              <FormControl className="flex-1 !m-0">
                <Input
                  type="text"
                  {...field}
                  placeholder="Enter full address"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="expectedDateofArrival"
          render={({ field }) => (
            <FormItem className="w-full flex gap-2">
              <FormLabel className="flex-[0.3]">
                Expected Date of Arr.
              </FormLabel>
              <FormControl className="flex-1 !m-0">
                {/* <Input type="date" {...field} placeholder="DD/MM/YY" /> */}
                <DateInput id="expected-date-of-arr" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="relative w-full pt-7 pb-3 bg-gradient-to-t from-white via-white/50 to-transparent flex items-center justify-center ">
          <DialogClose
            disabled={!form.formState.isValid}
            type="submit"
            className={cn(
              buttonVariants({ variant: "default" }),
              "w-fit disabled:!cursor-not-allowed",
              "w-full max-w-[164px] disabled:opacity-100 disabled:bg-primary-200 disabled:!cursor-not-allowed"
            )}
          >
            Add Item
          </DialogClose>
          <div className="absolute right-0 top-0 h-full flex items-center pt-7 pb-3">
            <h3 className="ml-auto text-xl font-medium">
              Import Item Cost: ₦{Number(getCostValue()).toLocaleString()}
            </h3>
          </div>
        </div>
      </form>
    </Form>
  );
};

export default ImportDetailItemForm;
