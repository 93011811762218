import React from "react";
import UserProfiling from "../../profile-management/components/UserProfiling";
import Registrations from "../../profile-management/components/Registrations";
import RevenueOverview from "../../profile-management/components/RevenueOverview";

export default function ProfileManagementAnalyticsPage() {
  return (
    <div className="flex flex-col gap-10">
      <UserProfiling />
      <Registrations />
      <RevenueOverview />
    </div>
  );
}
