import React from "react";
import FalsifiedRecordsReport from "../../legal/_components/FalsifiedRecordsAnalytics";
import FalsifiedRecordsTable from "@/components/tables/FalsifiedRecordsTable";

export default function LegalReportPage() {
  return (
    <div>
      <FalsifiedRecordsTable />
    </div>
  );
}
