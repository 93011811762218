import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Image,
  PDFViewer,
} from "@react-pdf/renderer";
import { ImportItem } from "@/lib/interfaces";

// Define styles
const styles = StyleSheet.create({
  page: {
    padding: 40,
    fontSize: 10,
  },
  header: {
    textAlign: "center",
    marginBottom: 20,
  },
  logo: {
    width: 80,
    height: 80,
    marginBottom: 10,
    alignSelf: "center",
    objectFit: "contain",
  },
  title: {
    fontSize: 18,
    color: "#005C00",
    fontFamily: "Helvetica-Bold",
    textAlign: "center",
    marginBottom: 5,
    maxWidth: "80%",
    margin: "0 auto",
  },
  subtitle: {
    textAlign: "center",
    fontFamily: "Times-Italic",
    color: "#7a0c00",
    fontSize: 12,
  },
  content: {
    marginTop: 20,
    lineHeight: 1,
  },
  signature: {
    marginTop: "auto",
    marginBottom: 5,
    textAlign: "center",
  },
  footer: {
    // position: "absolute",
    // bottom: 20,
    // left: 40,
    // right: 40,
    fontSize: 8,
    textAlign: "center",
  },
  table: {
    marginTop: 20,
    width: "100%",
    border: "1px solid #A6E72A",
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#A6E72A",
  },
  tableHeader: {
    backgroundColor: "#e2f2e6",
    fontFamily: "Helvetica-Bold",
  },
  tableCell: {
    flex: 1,
    fontSize: 10,
    padding: 5,
    textAlign: "center",
    borderRightWidth: 1,
    borderRightColor: "#A6E72A",
  },
  lastCell: {
    borderRightWidth: 0,
  },
  totalRow: {
    flexDirection: "row",
    justifyContent: "flex-end",
    fontSize: 12,
    fontWeight: "bold",
    marginTop: 10,
  },
});

// Define the document
const ClearanceCertificate = ({
  nesrea_id,
  certificate_number,
  date_issued,
  import_items,
}: {
  nesrea_id: string;
  certificate_number: string;
  date_issued: string;
  import_items: ImportItem[];
}) => {
  const date_ = new Date(date_issued);
  const year = date_.getFullYear();
  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        {/* Header Section */}
        <View style={styles.header}>
          <Image src="/images/Logo.png" style={styles.logo} />{" "}
          {/* Replace with actual logo path */}
          <Text style={styles.title}>
            NATIONAL ENVIRONMENTAL STANDARDS AND REGULATIONS ENFORCEMENT AGENCY
          </Text>
          <Text style={styles.subtitle}>
            ...ensuring a cleaner and healthier environment for Nigerians
          </Text>
          <Text
            style={{
              fontSize: 13,
              fontFamily: "Times-Italic",
              borderBottom: "2px solid black",
              paddingBottom: 5,
            }}
          >
            All correspondences to: Director General / Chief Executive Officer
          </Text>
          <Text
            style={{
              textTransform: "uppercase",
              fontSize: 16,
              marginTop: 5,
              fontFamily: "Helvetica-Bold",
            }}
          >
            DEPARTMENT OF INSPECTION AND ENFORCEMENT
          </Text>
        </View>

        {/* Content Section */}
        <View style={styles.content}>
          <View
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text style={{}}>NESREA ID: {nesrea_id}</Text>
              <Text style={{}}>CERTIFICATE NUMBER: {certificate_number}</Text>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text style={{}}>{date_issued}</Text>
              <Text style={{}}>Expires: {`December 31, ${year}.`}</Text>
            </View>
          </View>
          <Text style={{ marginTop: 15 }}>
            The Manager,{"\n"}
            Samchase Nigeria Limited,{"\n"}
            Plot 25 Block M,{"\n"}
            Isolo Industrial Scheme,{"\n"}
            Oshodi Apapa Expressway,{"\n"}
            Lagos State.
          </Text>

          <Text
            style={{
              marginTop: 15,
              fontFamily: "Helvetica-Bold",
              textAlign: "center",
            }}
          >
            ENVIRONMENTAL IMPORT CLEARANCE
          </Text>

          <Text style={{ marginTop: 6 }}>
            I am directed to inform you that pursuant to the National
            Environmental Sectoral Regulations and in the exercise of the powers
            conferred under the provisions of the National Environmental
            Standards and Regulations Enforcement Agency (NESREA) Establishment
            Act CAP N164 LFN 2010, a clearance is hereby granted to you to
            import 1 Piece of Used Caterpillar M 315D with GP Bucket VAN101221
            with Invoice No. 202414.
          </Text>

          <Text style={{ marginTop: 6 }}>
            2. You are to ensure that the Agency is notified of the arrival of
            the consignment for necessary inspection.
          </Text>

          <Text style={{ marginTop: 6 }}>
            3. Please accept the assurances of the Director General/CEO's best
            regards.
          </Text>
        </View>

        {/* Signature Section */}
        <View style={styles.signature}>
          <Image
            style={{
              width: 100,
              height: 100,
              objectFit: "contain",
              margin: "0 auto",
            }}
            src={"/images/signature.png"}
          />
          <Text>Isa Abdussalam</Text>
          <Text>Director (Inspection and Enforcement)</Text>
          <Text>For: Director General/CEO</Text>
        </View>

        {/* Footer Section */}
        <View style={styles.footer}>
          <Text>
            No. 56, Lome Crescent, Wuse Zone 7, P.M.B. 641, Garki, Abuja, FCT,
            Nigeria.
          </Text>
          <Text>
            Tel: +234-814-609-9778 E-mail: dg@nesrea.gov.ng www.nesrea.gov.ng
          </Text>
        </View>

        {/* Table */}
        {import_items.length && (
          <>
            <Text
              style={{
                marginTop: 15,
                fontFamily: "Helvetica-Bold",
                textAlign: "center",
              }}
              break
            >
              DETAILS OF IMPORT ITEMS
            </Text>
            <View style={styles.table}>
              {/* Table Header */}
              <View style={[styles.tableRow, styles.tableHeader]}>
                <Text style={styles.tableCell}>S/N</Text>
                <Text style={styles.tableCell}>Description</Text>
                <Text style={styles.tableCell}>QTY</Text>
                <Text style={styles.tableCell}>Unit</Text>
              </View>
              {/* Table Rows */}
              {import_items.map((item, index) => (
                <View style={styles.tableRow}>
                  <Text style={styles.tableCell}>{index + 1}</Text>
                  <Text style={styles.tableCell}>{item.itemName}</Text>
                  <Text style={styles.tableCell}>{item.quantity}</Text>
                  <Text style={styles.tableCell}>{item.unit}</Text>
                </View>
              ))}
            </View>
          </>
        )}
        {/* Signature Section */}
        <View style={styles.signature}>
          <Image
            style={{
              width: 100,
              height: 100,
              objectFit: "contain",
              margin: "0 auto",
            }}
            src={"/images/signature.png"}
          />
          <Text>Isa Abdussalam</Text>
          <Text>Director (Inspection and Enforcement)</Text>
          <Text>For: Director General/CEO</Text>
        </View>

        {/* Footer Section */}
        <View style={styles.footer}>
          <Text>
            No. 56, Lome Crescent, Wuse Zone 7, P.M.B. 641, Garki, Abuja, FCT,
            Nigeria.
          </Text>
          <Text>
            Tel: +234-814-609-9778 E-mail: dg@nesrea.gov.ng www.nesrea.gov.ng
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default ClearanceCertificate;
