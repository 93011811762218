import React from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { Button } from "./button";
import { cn } from "@/lib/utils";

const ExportComponentAsPDF = ({
    componentRef,
}: {
    componentRef: React.RefObject<HTMLElement>;
}) => {
    const handleExportAsPDF = async () => {
        const element = componentRef.current;
        if (element) {
            const canvas = await html2canvas(element, {
                scale: 2, // Increase the scale to improve quality
                useCORS: true, // Enable cross-origin images
            });
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF();
            const imgProps = pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

            // Add some margins
            const margin = 10;
            const contentWidth = pdfWidth - 2 * margin;
            const contentHeight = (imgProps.height * contentWidth) / imgProps.width;

            // Add title
            pdf.setFontSize(18);
            pdf.text("Report Title", pdfWidth / 2, margin, { align: "center" });

            // Add image below the title
            pdf.addImage(imgData, "PNG", margin, margin + 20, contentWidth, contentHeight);
            pdf.save("download.pdf");
        }
    };

    return (
        <Button
            className={cn(
                "bg-transparent text-primary-700 border hover:text-primary-foreground flex gap-1 items-center"
            )}
            onClick={handleExportAsPDF}
        >
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M6.66699 14.1667L10.0003 17.5M10.0003 17.5L13.3337 14.1667M10.0003 17.5V10M16.667 13.9524C17.6849 13.1117 18.3337 11.8399 18.3337 10.4167C18.3337 7.88536 16.2816 5.83333 13.7503 5.83333C13.5682 5.83333 13.3979 5.73833 13.3054 5.58145C12.2187 3.73736 10.2124 2.5 7.91699 2.5C4.46521 2.5 1.66699 5.29822 1.66699 8.75C1.66699 10.4718 2.3632 12.0309 3.48945 13.1613"
                    stroke="currentColor"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
            Export
        </Button>
    );
};

export default ExportComponentAsPDF;
