import { buttonVariants } from "@/components/ui/button";
import { useUserAnalytics } from "@/hooks/analytics/useUserAnalytics";
import { userCategoryPluralMap } from "@/lib/site-data";
import { cn, getPercentage } from "@/lib/utils";
import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function ReportOverview() {
  return (
    <div className="w-full shadow-[0_4px_20px_0px_#00000014] rounded-xl flex flex-col gap-5 p-3">
      <div className="flex items-center justify-between w-full">
        <h1 className="text-primary-700 text-xl font-medium">
          User Base Summary
        </h1>
        <Link
          to={"/admin/reports"}
          className={cn(
            buttonVariants({ variant: "link" }),
            "p-0 underline text-xs"
          )}
        >
          View All
        </Link>
      </div>

      <CircleChart />
    </div>
  );
}

const CircleChart = () => {
  // Define your data with category, percentage, color, and positions for clustering
  const userAnalyticsData = useUserAnalytics();

  type Range = { min: number; max: number };
  type Circle = {
    id: number;
    category: string;
    percentage: number;
    color: string;
    x: number;
    y: number;
    width: number;
    total: number;
  };

  function generateCoordinates(
    percentage: number,
    xrange: Range,
    yrange: Range
  ): { x: number; y: number; width: number } {
    if (percentage < 0 || percentage > 100) {
      throw new Error("Percentage must be between 0 and 100.");
    }

    const xRangeSize = xrange.max - xrange.min;
    const yRangeSize = yrange.max - yrange.min;

    let width = (percentage / 100) * xRangeSize;
    width = Math.max(50, Math.min(250, width)); // Clamp the width between 50 and 250

    // let x = Math.random() * (xRangeSize - width) + xrange.min;
    // let y = Math.random() * (yRangeSize - width) + yrange.min;
    let x = Math.random() * (xrange.max - xrange.min) + xrange.min;
    let y = Math.random() * (yrange.max - yrange.min) + yrange.min;

    return { x, y, width };
  }

  // function generateCoordinates(
  //   percentage: number,
  //   centerX: number,
  //   centerY: number,
  //   radius: number
  // ): { x: number; y: number; width: number } {
  //   if (percentage < 0 || percentage > 100) {
  //     throw new Error("Percentage must be between 0 and 100.");
  //   }

  //   let width = (percentage / 100) * radius;
  //   width = Math.max(50, Math.min(250, width)); // Clamp the width between 50 and 250

  //   const angle = Math.random() * 2 * Math.PI; // Random angle for placement
  //   const distance = Math.random() * radius * 0.5; // Random distance from the center, within half the radius

  //   const x = centerX + distance * Math.cos(angle);
  //   const y = centerY + distance * Math.sin(angle);

  //   return { x, y, width };
  // }

  function resolveCollisions(circles: Circle[]) {
    const isOverlapping = (circleA: Circle, circleB: Circle) => {
      const dx = circleA.x - circleB.x;
      const dy = circleA.y - circleB.y;
      const distance = Math.sqrt(dx * dx + dy * dy);
      return distance < (circleA.width + circleB.width) / 2;
    };

    for (let i = 0; i < circles.length; i++) {
      for (let j = i + 1; j < circles.length; j++) {
        while (isOverlapping(circles[i], circles[j])) {
          // Adjust the position of the second circle slightly to resolve overlap
          circles[j].x += Math.random() * 10 - 5;
          circles[j].y += Math.random() * 10 - 5;
        }
      }
    }

    return circles;
  }

  const percentages = {
    agent: Number(
      getPercentage(
        userAnalyticsData?.users_count.TOTAL_AGENTS || 0,
        userAnalyticsData?.users_count.TOTAL_USERS || 0
      )
    ),
    company: Number(
      getPercentage(
        userAnalyticsData?.users_count.TOTAL_COMPANIES || 0,
        userAnalyticsData?.users_count.TOTAL_USERS || 0
      )
    ),
    individual: Number(
      getPercentage(
        userAnalyticsData?.users_count.TOTAL_INDIVIDUALS || 0,
        userAnalyticsData?.users_count.TOTAL_USERS || 0
      )
    ),
    government: Number(
      getPercentage(
        userAnalyticsData?.users_count.TOTAL_GOVERNMENT || 0,
        userAnalyticsData?.users_count.TOTAL_USERS || 0
      )
    ),
  };

  const centerX = 0; // Center X in percentage
  const centerY = 0; // Center Y in percentage
  const radius = 100; // Radius of the area to place circles

  let circles = [
    {
      id: 1,
      category: "Agent",
      percentage: percentages.agent,
      color: "#274b1c",
      total: userAnalyticsData?.users_count.TOTAL_AGENTS || 0,
      ...generateCoordinates(
        percentages.agent,
        { min: 12, max: 15 },
        { min: 30, max: 35 }
      ),
    },
    {
      id: 2,
      category: "Company",
      percentage: percentages.company,
      color: "#5ba8b5",
      total: userAnalyticsData?.users_count.TOTAL_COMPANIES || 0,
      ...generateCoordinates(
        percentages.company,
        { min: 10, max: 10 },
        { min: 10, max: 10 }
      ),
    },
    {
      id: 3,
      category: "Individual",
      percentage: percentages.individual,
      color: "#b2d7b4",
      total: userAnalyticsData?.users_count.TOTAL_INDIVIDUALS || 0,
      ...generateCoordinates(
        percentages.individual,
        { min: 55, max: 55 },
        { min: 55, max: 55 }
      ),
    },
    {
      id: 4,
      category: "Government",
      percentage: percentages.government,
      color: "#77a55a",
      total: userAnalyticsData?.users_count.TOTAL_GOVERNMENT || 0,
      ...generateCoordinates(
        percentages.government,
        { min: 35, max: 40 },
        { min: 35, max: 40 }
      ),
    },
  ];

  // circles = resolveCollisions(circles);

  // const data = [
  //   {
  //     id: 1,
  //     category: "Agent",
  //     // percentage: 50,
  //     percentage: 0,
  //     color: "#274b1c",
  //     top: 0,
  //     // 0,
  //     // min 0, max 45
  //     left: 0,
  //     //min 25, max 35
  //     total: userAnalyticsData?.users_count.TOTAL_AGENTS || 0,
  //   },
  //   {
  //     id: 2,
  //     category: "Company",
  //     percentage: companyPercentage,
  //     color: "#5ba8b5",
  //     top: companyPercentage * 0.45,
  //     left: 25 + companyPercentage * 0.25,
  //     total: userAnalyticsData?.users_count.TOTAL_COMPANIES || 0,
  //   },
  //   {
  //     id: 3,
  //     category: "Individual",
  //     percentage: individualPercentage,
  //     // percentage: 100,
  //     color: "#b2d7b4",
  //     // top: individualPercentage * 0.25,
  //     top: individualPercentage * 5,
  //     // min max 7
  //     left: 15,
  //     total: userAnalyticsData?.users_count.TOTAL_INDIVIDUALS || 0,
  //   },
  //   {
  //     id: 4,
  //     category: "Government",
  //     percentage: governmentPercentage,
  //     color: "#77a55a",
  //     top: governmentPercentage * 0.45,
  //     left: 25 + governmentPercentage * 0.25,
  //     total: userAnalyticsData?.users_count.TOTAL_GOVERNMENT || 0,
  //   },
  // ];

  const [hoveredCircle, setHoveredCircle] = useState<any>(null);
  const [isHovered, setIsHovered] = useState(false);

  // Convert percentage to a proportional circle size
  const calculateSize = (percentage: number) => {
    const maxSize = 500; // Adjust this to control the max circle size
    return (percentage / 100) * maxSize;
  };

  const handleMouseEnter = (id: any, e: any) => {
    setIsHovered(true);
    const circle = circles.find((item) => item.id === id);
    const circleRect = e.target.getBoundingClientRect();
    const offsetX = e.clientX - (circleRect.left + circleRect.width / 2);
    const offsetY = e.clientY - (circleRect.top + circleRect.height / 2);

    const displacement = 3; // Adjust this value for stronger or weaker displacement
    setHoveredCircle({
      id,
      top: circle?.y! - (offsetY / Math.abs(offsetY)) * displacement,
      left: circle?.x! - (offsetX / Math.abs(offsetX)) * displacement,
    });
  };

  const handleMouseLeave = () => {
    setHoveredCircle(null); // Reset position when the mouse leaves
    setIsHovered(false);
  };

  return (
    <div className="w-full flex h-[80vh] relative">
      {circles.map((item, index) => {
        const isHovered = hoveredCircle && hoveredCircle.id === item.id;
        const top = isHovered ? hoveredCircle.top : item.y;
        const left = isHovered ? hoveredCircle.left : item.x;
        return (
          <div
            key={item.id}
            onMouseEnter={(e) => handleMouseEnter(item.id, e)}
            onMouseLeave={handleMouseLeave}
            className="duration-500 min-w-[50px] aspect-square delay-150"
            style={{
              position: "absolute",
              top: `${top}%`,
              left: `${left}%`,
              width: `${item.percentage * 0.25}%`,
              backgroundColor: item.color,
              borderRadius: "50%",
              color: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "1.2rem",
              fontWeight: "bold",
            }}
          >
            <>{`${item.percentage}%`}</>
            {isHovered && (
              <p className="flex items-center gap-1 mix-blend-difference absolute z-[9999999999] font-normal -top-10">
                <strong>{`${item.total}`}</strong>
                <span>
                  {item.total > 1
                    ? userCategoryPluralMap[
                        item.category.toLowerCase() as
                          | "agent"
                          | "company"
                          | "individual"
                          | "government"
                      ]
                    : item.category}
                </span>
              </p>
            )}
          </div>
        );
      })}
      <div className="w-full self-end flex items-center justify-center gap-10 py-10">
        {circles.map((item) => (
          <div key={item.id} className="flex items-center gap-2">
            <div
              className="w-[20px] aspect-square rounded"
              style={{ backgroundColor: item.color }}
            ></div>
            <h5>{item.category}</h5>
          </div>
        ))}
      </div>
    </div>
  );
};

// const CircleChart = () => {
//   const userAnalyticsData = useUserAnalytics();

//   type Range = { min: number; max: number };
//   type Circle = {
//     id: number;
//     category: string;
//     percentage: number;
//     color: string;
//     x: number;
//     y: number;
//     width: number;
//     total: number;
//   };

//   return (
//     <div className="w-full flex h-[80vh] relative">
//       {circles.map((item) => (
//         <div
//           key={item.id}
//           className="duration-500 min-w-[50px] aspect-square delay-150"
//           style={{
//             position: "absolute",
//             top: `${item.y}%`,
//             left: `${item.x}%`,
//             width: `${item.width}px`,
//             height: `${item.width}px`,
//             backgroundColor: item.color,
//             borderRadius: "50%",
//             color: "white",
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//             fontSize: "1rem",
//             fontWeight: "bold",
//           }}
//         >
//           {`${item.percentage}%`}
//         </div>
//       ))}
//     </div>
//   );
// };
