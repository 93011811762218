import { useStore } from "@/contexts/agentStore";
import { clearanceApplications } from "@/lib/dummy-data/clearanceApplicationData";
import { ClearanceApplicationColumns } from "./columns";
import { DataTable } from "./data-table";
import React from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { getBackendUrl } from "@/lib/utils";
import { ClearanceApplicationData } from "@/lib/interfaces";
import { FilterData } from "@/components/ui/filter";

export default function ClearanceApplicationTable() {
  const agentStore = useStore((state) => state);
  const { data, isLoading } = useQuery({
    queryKey: ["clearance-applications-admin"],
    queryFn: async () => {
      const { data }: { data: { allclearanceapps: any[] } } = await axios.get(
        `${getBackendUrl()}/all-clearance-application`
      );
      const dataset: ClearanceApplicationData[] = data.allclearanceapps
        .map((item) => ({
          id: item.clearance_id,
          name: item.importer_name,
          importerCategory: item.importer_category,
          applicationDate: item.created_at,
          importerEmail: item.importer_email,
          status: item.status,
        }))
        .sort(
          (a, b) =>
            new Date(b.applicationDate).getTime() -
            new Date(a.applicationDate).getTime()
        );
      return dataset;
    },
  });

  const [data_, setData_] = React.useState<FilterData>([]);
  const [filteredData, setFilteredData] = React.useState<FilterData>([]);
  const filteredIDs = filteredData.map((d) => d.id);

  const updatedData = React.useMemo(() => {
    return data?.filter((stat) => filteredIDs.includes(`${stat.id}`)) || [];
  }, [data, filteredIDs]);

  React.useEffect(() => {
    const newData = data?.map((item) => ({
      id: `${item.id}`,
      name: item.name,
      category: item.importerCategory,
      date: item.applicationDate,
      status: item.status,
    }));
    setData_(newData || []);
    if (!filteredData.length) {
      setFilteredData(newData || []);
    }
  }, [data]);
  return (
    <div>
      <DataTable
        columns={ClearanceApplicationColumns}
        setData_={setData_}
        filteredData={filteredData}
        setFilteredData={setFilteredData}
        data_={data_}
        data={updatedData || []}
      />
    </div>
  );
}
