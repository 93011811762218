import React, { useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { cn, getBackendUrl } from "@/lib/utils";
import { Button, buttonVariants } from "@/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogTrigger,
} from "@/components/ui/dialog";

import { generatedWaivers } from "@/lib/dummy-data/generatedWaivers";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import LoaderWrapper from "@/components/ui/loader-wrapper";
import { Copy } from "lucide-react";
import { toast } from "sonner";

export default function GeneratedWaiverDetailsPage() {
  const [searchParams] = useSearchParams();
  let id = searchParams.get("id");

  const { data, isLoading } = useQuery({
    queryKey: ["generated-waiver-details"],
    queryFn: async () => {
      try {
        const { data } = await axios.get(
          `${getBackendUrl()}/get-waiver-code/${id}`
        );
        return data?.WaiverCodeDetails;
      } catch (error) {}
    },
  });

  let waiverDetails = generatedWaivers.find((data) => data.id == id);

  return (
    <LoaderWrapper isLoading={isLoading}>
      <div className="w-full flex flex-col gap-7">
        <h3 className="text-2xl font-medium">
          The following are details of the generated waiver:
        </h3>
        <div className="w-full grid grid-cols-2 gap-7">
          <div className="border-[0.5px] border-primary-700 bg-white rounded-xl p-11 flex flex-col gap-7">
            <h3 className="text-2xl font-semibold text-center">
              Waiver Details
            </h3>
            <div className="w-full flex flex-col gap-6">
              <div className="w-full flex items-center justify-between">
                <h6 className="font-medium text-primary-700">Waiver Type</h6>
                <p className="text-neutral-500">{data?.waiver_type}</p>
              </div>
              <div className="w-full flex items-center justify-between">
                <h6 className="font-medium text-primary-700">Waiver ID</h6>
                <p className="text-neutral-500">{data?.waiver_id}</p>
              </div>
              <div className="w-full flex items-center justify-between">
                <h6 className="font-medium text-primary-700">Waiver Value</h6>
                <p className="text-neutral-500">{data?.waiver_value}</p>
              </div>
              <div className="w-full flex items-center justify-between">
                <h6 className="font-medium text-primary-700">Percentage</h6>
                <p className="text-neutral-500">{data?.percentage}</p>
              </div>
              <div className="w-full flex items-center justify-between">
                <h6 className="font-medium text-primary-700">
                  Validity Period
                </h6>
                <p className="text-neutral-500">{data?.validity_period}</p>
              </div>
            </div>
          </div>
          <div className="border-[0.5px] border-primary-700 bg-white rounded-xl p-11 flex flex-col gap-7">
            <h3 className="text-2xl font-semibold text-center">Waiver Usage</h3>
            <div className="w-full flex flex-col gap-6">
              <div className="w-full flex items-center justify-between">
                <h6 className="font-medium text-primary-700">Usage Status</h6>
                <p className="text-neutral-500">{data?.usage_status || "-"}</p>
              </div>
              <div className="w-full flex items-center justify-between">
                <h6 className="font-medium text-primary-700">Applied By</h6>
                <p className="text-neutral-500">{data?.applied_by || "-"}</p>
              </div>
              <div className="w-full flex items-center justify-between">
                <h6 className="font-medium text-primary-700">Usage Date</h6>
                <p className="text-neutral-500">{data?.usage_data || "-"}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-end">
          <Dialog>
            <DialogTrigger>
              <Button>View Waiver Code</Button>
            </DialogTrigger>
            <DialogContent>
              <div className="w-full flex flex-col gap-6">
                <h5 className="text-primary-500 text-center">
                  {"Here's"} your generated waiver code
                </h5>
                <div className="flex items-center gap-2 justify-center">
                  <p className="text-primary-500 text-center text-xl font-semibold">
                    {data?.waiver_code}
                  </p>
                  <Copy
                    size={15}
                    onClick={async () => {
                      await navigator.clipboard.writeText(data?.waiver_code);
                      toast.success("Waiver Code Copied Successfully");
                    }}
                    className="text-primary-700 hover:scale-110 duration-500 cursor-pointer"
                  />
                </div>
                <DialogClose asChild>
                  <Button>Close</Button>
                </DialogClose>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </div>
    </LoaderWrapper>
  );
}
