import DashboardModal from "@/components/modals/DashboardModal";
import { Button, buttonVariants } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { cn, getBackendUrl } from "@/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import { BadgeCheck, BadgeXIcon, CheckCircle, ChevronLeft } from "lucide-react";
import React, { useState } from "react";
import { set, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { z } from "zod";
import ReportForm from "./_components/ReportForm";
import { PDFViewer } from "@react-pdf/renderer";
import ClearanceCertificate from "@/components/docs/certificate";
import { format } from "fecha";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { QueryClient, useQuery } from "@tanstack/react-query";
import { ImportItem } from "@/lib/interfaces";

const nesreaIdRegex = /^NU\d{16}$/;
const certificateNumberRegex = /^C\d{16}$/;

// C2501061106109244
// NU2501061038451275

interface CertificateDetails {
  certificate_details?: {
    id: number;
    nesrea_id: string;
    importer_name: string;
    importer_email: string;
    clearance_id: string;
    issuerance_date: string;
    certificate_number: string;
    expiry_date: string;
    created_by: string;
    status: string;
    created_at: string;
    updated_at: string;
    activities: string;
  };
  clearance_details?: {
    id: number;
    nesrea_id: string;
    importer_email: string;
    importer_category: string;
    clearance_id: string;
    clearance_date: string;
    item: string;
    hs_code: string;
    item_unit: string;
    item_quantity: string;
    container_cargo_number: string;
    country_of_origin: string;
    port_of_entry: string;
    purpose_of_importation: string;
    final_destination_address: string;
    expected_date_of_arrival: string;
    item_status: string;
    waiver_status: string | null;
    created_by: string;
    created_at: string;
    updated_at: string;
    action_taken_item: string;
    item_approved_by: string;
    item_approval_date: string;
    item_approval_comment: string;
    category_id: string;
    category_label: string;
    category_code: string;
    sub_category_id: string;
    sub_category_label: string;
    sub_category_code: string;
    item_category_id: string;
    item_category_label: string;
    item_category_code: string;
    item_id: string;
    item_label: string;
    item_code: string;
    cost: string;
  }[];
}

export default function VerifyCertificatePage() {
  const navigate = useNavigate();
  const [isReporting, setIsReporting] = useState(false);
  const [verification, setVerification] = useState<
    "approved" | "rejected" | "pending" | undefined
  >();
  const [hasVerified, setHasVerified] = useState(false);
  const formSchema = z.object({
    nesreaId: z
      .string()
      .min(2, { message: "Please enter the nesrea id" })
      .refine((value) => nesreaIdRegex.test(value), {
        message: "Invalid Nesrea ID",
      }),
    certificateNumber: z
      .string()
      .min(5, { message: "Please enter the certificate number" })
      .refine((value) => certificateNumberRegex.test(value), {
        message: "Invalid certificate number",
      }),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      nesreaId: "",
      certificateNumber: "",
    },
  });

  // const { data: certificateDetails, isLoading } = useQuery({
  //   queryKey: ["cerificate-details"],
  //   queryFn: async () => {
  //     if (verification == "approved") {
  //       const { data }: { data: CertificateDetails } = await axios.get(
  //         `${getBackendUrl()}/get-certifiacte-details/${form.getValues(
  //           "certificateNumber"
  //         )}`
  //       );
  //       return data?.certificate_details || null;
  //     }
  //     return null;
  //   },
  // });

  const [certificateDetails, setCertificateDetails] =
    useState<CertificateDetails["certificate_details"]>();

  const [importItems, setImportItems] = useState<ImportItem[]>([]);

  const queryClient = new QueryClient();
  return (
    <div className="w-full grid md:grid-cols-2 grid-cols-1 min-h-screen h-fit relative">
      <div className="relative h-full">
        <div
          className="md:flex hidden absolute top-0 left-0 w-full h-full z-10 brightness-50 bg-cover bg-center bg-no-repeat"
          style={{ backgroundImage: `url('/images/signup-backdrop.png')` }}
        ></div>
        <div className="w-full h-full z-20 relative flex flex-col items-center justify-center text-center">
          <h1 className="text-9xl font-bold text-primary-foreground">NESCAP</h1>
          <p className="text-lg font-medium text-primary-foreground">
            NESREA CONFORMITY ASSESSMENT PORTAL
          </p>
          <p className="bg-white rounded-lg p-3 max-w-md text-primary-700 font-medium border-primary-500 border-2 uppercase">
            For further enquiries, visit us at... No. 56 lome crescent, wuse
            zone 7, Abuja, Nigeria. <br />
            Contact us at: <br />
            dg@nesrea.gov.ng, info@nesrea.gov.ng, +2349153993191
          </p>
          <div className="flex gap-3 items-center w-full justify-center relative z-[99999999999] my-5">
            <Link
              className={cn(
                buttonVariants({ variant: "default" }),
                "bg-primary-foreground text-primary-500 rounded-full hover:bg-primary-700 hover:text-primary-foreground w-full max-w-[150px] font-medium"
              )}
              to={"/user/sign-up"}
            >
              Sign Up
            </Link>
            <Link
              to={"/user/sign-in"}
              className={cn(
                buttonVariants({ variant: "ghost" }),
                "w-full max-w-[150px] border rounded-full border-white hover:bg-white text-primary-foreground"
              )}
            >
              Login
            </Link>
          </div>
        </div>
      </div>

      <div className="w-full h-fit flex flex-col items-center relative">
        {verification == "pending" && (
          <div className="absolute top-0 w-full h-full bg-white/70 z-50 flex items-center justify-center">
            <span className="loader"></span>
          </div>
        )}
        {/* {showModal && (
          <div className="fixed w-full h-screen top-0 right-0 z-[9999999999999999999] flex items-center justify-center">
            <div
              onClick={() => {
                if (verification !== "pending") {
                  setShowModal(false);
                }
              }}
              className={cn(
                "w-full h-full absolute top-0 right-0 bg-black/40",
                verification !== "pending" && "cursor-pointer"
              )}
            ></div>
            <div className="z-10 relative min-w-[150px] bg-white rounded-lg p-5 w-fit">
              {verification === "pending" && (
                <div className="flex flex-col gap-3 items-center justify-center text-center">
                  <h6 className="text-primary-700 font-medium">
                    Validating Certificate
                  </h6>
                  <span className="aux-loader"></span>
                </div>
              )}
              {verification === "approved" && (
                <div className="flex flex-col gap-3 items-center justify-center text-center">
                  <svg
                    width="53"
                    height="54"
                    viewBox="0 0 53 54"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      y="0.5"
                      width="53"
                      height="53"
                      rx="26.5"
                      fill="#008F38"
                    />
                    <mask
                      id="mask0_1499_31468"
                      maskUnits="userSpaceOnUse"
                      x="17"
                      y="17"
                      width="19"
                      height="20"
                    >
                      <rect
                        x="20.3373"
                        y="20.8353"
                        width="12.3256"
                        height="12.3256"
                        fill="#D9D9D9"
                        stroke="#F2F2F2"
                        strokeWidth="6.16279"
                      />
                    </mask>
                    <g mask="url(#mask0_1499_31468)">
                      <mask id="path-3-inside-1_1499_31468" fill="white">
                        <path d="M23.4837 31.6189L19.168 27.2279L20.2469 26.1302L23.4837 29.4234L30.4305 22.3555L31.5094 23.4532L23.4837 31.6189Z" />
                      </mask>
                      <path
                        d="M23.4837 31.6189L19.168 27.2279L20.2469 26.1302L23.4837 29.4234L30.4305 22.3555L31.5094 23.4532L23.4837 31.6189Z"
                        fill="#EEF8FA"
                      />
                      <path
                        d="M23.4837 31.6189L19.0884 35.9388L23.4837 40.4108L27.8789 35.9388L23.4837 31.6189ZM19.168 27.2279L14.7727 22.908L10.5268 27.2279L14.7727 31.5478L19.168 27.2279ZM20.2469 26.1302L24.6422 21.8103L20.2469 17.3383L15.8516 21.8103L20.2469 26.1302ZM23.4837 29.4234L19.0884 33.7433L23.4837 38.2153L27.8789 33.7433L23.4837 29.4234ZM30.4305 22.3555L34.8257 18.0356L30.4305 13.5636L26.0352 18.0356L30.4305 22.3555ZM31.5094 23.4532L35.9047 27.7731L40.1505 23.4532L35.9047 19.1333L31.5094 23.4532ZM27.8789 27.299L23.5632 22.908L14.7727 31.5478L19.0884 35.9388L27.8789 27.299ZM23.5632 31.5478L24.6422 30.4501L15.8516 21.8103L14.7727 22.908L23.5632 31.5478ZM15.8516 30.4501L19.0884 33.7433L27.8789 25.1035L24.6422 21.8103L15.8516 30.4501ZM27.8789 33.7433L34.8257 26.6754L26.0352 18.0356L19.0884 25.1035L27.8789 33.7433ZM26.0352 26.6754L27.1141 27.7731L35.9047 19.1333L34.8257 18.0356L26.0352 26.6754ZM27.1141 19.1333L19.0884 27.299L27.8789 35.9388L35.9047 27.7731L27.1141 19.1333Z"
                        fill="#F2F2F2"
                        mask="url(#path-3-inside-1_1499_31468)"
                      />
                    </g>
                    <path
                      d="M20.4203 22.0482C20.4819 22.3134 20.3068 22.5808 20.1375 22.8732C19.8566 22.6854 19.5815 22.5228 19.5198 22.2576C19.4582 21.9923 19.6098 21.7305 19.8584 21.6727C20.107 21.6149 20.3586 21.783 20.4203 22.0482Z"
                      fill="white"
                    />
                    <path
                      d="M16.4648 22.9661C16.684 23.1276 16.7246 23.4446 16.786 23.7769C16.4505 23.8167 16.1357 23.8719 15.9165 23.7104C15.6972 23.5489 15.6423 23.2514 15.7937 23.0458C15.9451 22.8403 16.2455 22.8046 16.4648 22.9661Z"
                      fill="white"
                    />
                    <path
                      d="M15.3261 26.6334C15.5978 26.6146 15.834 26.8298 16.096 27.0432C15.8661 27.2909 15.6619 27.5367 15.3903 27.5556C15.1186 27.5745 14.8841 27.3834 14.8664 27.1287C14.8487 26.8741 15.0545 26.6523 15.3261 26.6334Z"
                      fill="white"
                    />
                  </svg>

                  <h6 className="font-medium text-primary-700">
                    Certificate Validation Successful
                  </h6>
                </div>
              )}
              {verification === "rejected" && (
                <div className="flex flex-col gap-3 items-center justify-center text-center">
                  <h6 className="font-medium text-destructive">
                    This Import Certificate is Invalid!
                  </h6>
                  <p className="text-sm leading-normal max-w-[250px]">
                    This individual may be using a fake identity or using an
                    invalid certificate
                  </p>
                  <Button
                    className="w-[320px]"
                    variant={"destructive"}
                    onClick={() => {
                      setShowModal(false);
                    }}
                  >
                    Report
                  </Button>
                </div>
              )}
            </div>
          </div>
        )} */}
        <div className="my-auto w-full flex flex-col items-center justify-center min-h-screen gap-2">
          <div
            className="w-[200px] aspect-square bg-contain bg-center bg-no-repeat"
            style={{ backgroundImage: `url('/images/Logo.png')` }}
          />
          {!hasVerified && (
            <Form {...form}>
              <form
                className="w-full max-w-md mx-auto space-y-3"
                onSubmit={form.handleSubmit(
                  async ({ nesreaId, certificateNumber }) => {
                    setVerification("pending");
                    try {
                      const { data } = await axios.post(
                        `${getBackendUrl()}/verify-certificate`,
                        {
                          certificate_number: certificateNumber,
                          nesrea_id: nesreaId,
                        }
                      );

                      if (data?.success) {
                        // queryClient.invalidateQueries({
                        //   queryKey: ["cerificate-details"],
                        // });
                        const { data }: { data: CertificateDetails } =
                          await axios.get(
                            `${getBackendUrl()}/get-certifiacte-details/${certificateNumber}`
                          );

                        if (data?.certificate_details) {
                          setCertificateDetails(data.certificate_details);
                        }
                        if (data?.clearance_details) {
                          setImportItems(
                            data?.clearance_details.map((item: any) => {
                              return {
                                itemName: item.item,
                                hscode: item.hs_code,
                                unit: item.item_unit,
                                quantity: item.item_quantity,
                                cargo_number: item.container_cargo_number,
                                country_of_origin: item.country_of_origin,
                                port_of_entry: item.port_of_entry,
                                purpose_of_importation:
                                  item.purpose_of_importation,
                                final_destination_address:
                                  item.final_destination_address,
                                expected_date_of_arrival:
                                  item.expected_date_of_arrival,
                                cost: item.cost,
                              };
                            })
                          );
                        }
                        setVerification("approved");
                        setHasVerified(true);
                        return;
                      }
                      setHasVerified(true);
                      setVerification("rejected");
                    } catch (error) {
                      setHasVerified(true);
                      setVerification("rejected");
                    }
                  }
                )}
              >
                <h4 className="font-medium text-lg text-center">
                  Enter the following details to verify import clearance
                  certificate
                </h4>
                <FormField
                  control={form.control}
                  name="nesreaId"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Nesrea ID</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          onChange={(e) => {
                            form.setValue("nesreaId", e.target.value);
                            field.onBlur();

                            form.trigger("nesreaId");
                          }}
                          placeholder="Enter your Nesrea ID (e.g. NES/123456789)"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="certificateNumber"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Certificate Number</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          onChange={(e) => {
                            form.setValue("certificateNumber", e.target.value);
                            field.onBlur();

                            form.trigger("certificateNumber");
                          }}
                          placeholder="Enter your certificate number"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <Button className="w-full" disabled={!form.formState.isValid}>
                  Verify
                </Button>
              </form>
            </Form>
          )}
          {hasVerified && !isReporting && (
            <div className="w-full max-w-md mx-auto flex flex-col gap-3 text-center min-h-[40vh]">
              <h1 className="text-2xl font-medium">Verification Complete</h1>
              <div className="flex flex-col gap-3 w-full">
                {verification == "approved" && (
                  <>
                    <p className="flex items-center w-fit mx-auto gap-3 font-medium">
                      <BadgeCheck size={24} className="text-primary-400" />
                      Verification Status:
                      <span className="text-primary-400">Approved.</span>
                    </p>
                    <Dialog>
                      <DialogTrigger
                        disabled={!certificateDetails}
                        className={cn(buttonVariants({ variant: "default" }))}
                      >
                        {certificateDetails
                          ? "View Certificate"
                          : "No certificate details"}
                      </DialogTrigger>
                      <DialogContent>
                        {/* {!certificateDetails && (
                        )} */}
                        <PDFViewer className="w-full aspect-[1/1.414]">
                          <ClearanceCertificate
                            nesrea_id={form.getValues("nesreaId")}
                            certificate_number={form.getValues(
                              "certificateNumber"
                            )}
                            import_items={importItems || []}
                            // date_issued={`${format(new Date(), "mediumDate")}`}
                            date_issued={`${format(
                              new Date(
                                certificateDetails?.issuerance_date.split(
                                  " "
                                )[0] || ""
                              ),
                              "mediumDate"
                            )}`}
                          />
                        </PDFViewer>
                      </DialogContent>
                    </Dialog>
                  </>
                )}
                {verification == "rejected" && (
                  <p className="flex items-center gap-3 font-medium text-destructive justify-center text-center">
                    <BadgeXIcon size={24} className="text-destructive" />

                    <span className="">
                      This certificate number does the exist in our database
                    </span>
                  </p>
                )}
              </div>

              <div className="flex items-center gap-3">
                <Button
                  onClick={() => {
                    // navigate("/user/sign-in");
                    setVerification(undefined);
                    setHasVerified(false);
                  }}
                  className="w-full"
                >
                  Go Back
                </Button>

                {verification == "rejected" && (
                  <Button
                    variant={"destructive"}
                    onClick={() => {
                      setIsReporting(true);
                    }}
                    className="w-full"
                  >
                    Report
                  </Button>
                )}
              </div>
            </div>
          )}
          {verification == "rejected" && isReporting && (
            <ReportForm
              nesreaId={form.getValues().nesreaId}
              certificateNumber={form.getValues().certificateNumber}
            />
          )}
        </div>
      </div>
    </div>
  );
}
