import React, { useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { toast } from "sonner";
import { PasswordInput } from "@/components/ui/password-input";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { getBackendUrl } from "@/lib/utils";
import axios from "axios";
import { userStoreInitialState, useStore } from "@/contexts/userStore";
import Cookies from "js-cookie";

const FormSchema = z.object({
  staff_id: z.string().min(2, {
    message: "id must be at least 2 characters.",
  }),
  password: z.string().min(2, {
    message: "password must be at least 2 characters.",
  }),
});

export default function SignInFormAdmin() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const userStore = useStore();

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      staff_id: "",
      password: "",
    },
  });

  async function onSubmitAdmin(values: z.infer<typeof FormSchema>) {
    try {
      setIsLoading(true);
      let {
        data: { session_id, data, message, user_Profile_info },
      } = await axios.post(`${getBackendUrl()}/sub-admin-login`, {
        staff_id: values.staff_id,
        password: values.password,
      });
      const user_data: userStoreInitialState["user"] = {
        id: data.id,
        nesrea_id: "",
        profile_updated: false,
        registration_status: "verified",
        registration_payment_status: "paid",
        registration_payment_date: "",
        name: data.name,
        email: data.email,
        account_type: "admin",
        admin_role: JSON.parse(data?.admin_role || "{}"),
        email_verification_status: "",
        created_at: data.created_at,
        updated_at: data.updated_at,
      };

      // store user data in local storage
      localStorage.setItem("nesrea_session_id", session_id);
      localStorage.setItem("nesrea_user", JSON.stringify(user_data));

      const expiryTime = new Date().getTime() + 60 * 60 * 1000; // 1 hour from now
      localStorage.setItem("nesrea_session_expiry", expiryTime.toString());

      toast.success("Login successful", {
        description: <>You can now access your dashboard</>,
      });

      userStore.setUser(user_data);

      navigate("/admin/dashboard");
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while signing in", {
        description: "Please try again",
      });
      setIsLoading(false);
    }
  }
  return (
    <div className="w-full max-w-lg shadow-lg m-auto p-12 flex flex-col items-center gap-7 text-center">
      <img src="/images/Logo.png" className="w-[200px]" alt="" />
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmitAdmin)}
          className="max-w-sm w-full space-y-6 text-left"
        >
          <FormField
            control={form.control}
            name="staff_id"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-primary capitalize">
                  Staff ID <span className="text-destructive">*</span>
                </FormLabel>
                <FormControl>
                  <Input placeholder="NES123456789" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-primary flex justify-between items-center">
                  <span className="flex items-center gap-1">
                    Password <span className="text-destructive">*</span>
                  </span>

                  <Link
                    to="/admin/forgot-password"
                    className="text-sm underline text-muted-400"
                  >
                    Forgot password?
                  </Link>
                </FormLabel>
                <FormControl>
                  <PasswordInput placeholder="********" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button disabled={isLoading} type="submit" className="w-full">
            {isLoading ? "Signing in..." : "Sign in"}
          </Button>
        </form>
      </Form>
    </div>
  );
}
