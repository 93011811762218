import React, { useEffect, useState } from "react";
import { CheckCircle, ChevronLeft, XCircle } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { PasswordInput } from "@/components/ui/password-input";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import axios from "axios";
import { toast } from "sonner";
import { cn, getBackendUrl } from "@/lib/utils";

const passwordUpdateSchema = z
  .object({
    email: z.string().email("Invalid email address"),
    currentPassword: z
      .string()
      .min(8, "Current password must be at least 8 characters long"),
    newPassword: z
      .string()
      .min(8, "New password must be at least 8 characters long")
      .regex(/[a-z]/, {
        message: "Password must contain at least one lowercase letter.",
      })
      .regex(/[A-Z]/, {
        message: "Password must contain at least one uppercase letter.",
      })
      .regex(/[0-9]/, {
        message: "Password must contain at least one number.",
      })
      .regex(/[^a-zA-Z0-9]/, {
        message: "Password must contain at least one special character.",
      }),
    confirmPassword: z
      .string()
      .min(8, "Confirm password must be at least 8 characters long"),
  })
  .refine((data) => data.newPassword === data.confirmPassword, {
    message: "New password and confirm password must match",
    path: ["confirmPassword"],
  });

const passwordMessages = [
  "Password must be at least 8 characters.",
  "Password must contain at least one lowercase letter.",
  "Password must contain at least one uppercase letter.",
  "Password must contain at least one number.",
  "Password must contain at least one special character.",
];

const validatePassword = (password: string) => {
  const passwordCriteria = [
    { message: "Password must be at least 8 characters.", regex: /.{8,}/ },
    {
      message: "Password must contain at least one lowercase letter.",
      regex: /[a-z]/,
    },
    {
      message: "Password must contain at least one uppercase letter.",
      regex: /[A-Z]/,
    },
    { message: "Password must contain at least one number.", regex: /\d/ },
    {
      message: "Password must contain at least one special character.",
      regex: /[!@#$%^&*(),.?":{}|<>]/,
    },
  ];

  return passwordCriteria
    .filter((criteria) => !criteria.regex.test(password))
    .map((criteria) => criteria.message);
};

export default function ForgotPasswordAdminPage() {
  const navigate = useNavigate();
  const [loading, setIsLoading] = useState(false);
  const form = useForm<z.infer<typeof passwordUpdateSchema>>({
    resolver: zodResolver(passwordUpdateSchema),
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
  });

  const onSubmit = async (values: z.infer<typeof passwordUpdateSchema>) => {
    setIsLoading(true);
    if (values.currentPassword == values.newPassword) {
      setIsLoading(false);
      form.setError("newPassword", {
        message: "New password cannot be the same as the current password",
        type: "manual",
      });
      return;
    }
    // try {
    //   await axios.post(`${getBackendUrl()}/user-login`, {
    //     email: values.email,
    //     password: values.currentPassword,
    //   });
    // } catch (error) {
    //   setIsLoading(false);
    //   toast.error("Current password is incorrect");
    // }
    try {
      await axios.post(`${getBackendUrl()}/change-password`, {
        email: values.email,
        newpassword: values.newPassword,
      });

      setIsLoading(false);
      toast.success("User password reset successfully");
      navigate("/user/sign-in");
    } catch (error) {
      setIsLoading(false);
      toast.error("An error occurred while resetting admin password");
    }
  };

  useEffect(() => {
    const subscription = form.watch((value, { name }) => {
      if (name) {
        form.trigger(name);
      }
    });
    return () => subscription.unsubscribe();
  }, [form]);

  return (
    <div className="w-full grid md:grid-cols-2 grid-cols-1 min-h-screen">
      <div className="relative">
        <div
          className="md:flex hidden absolute top-0 left-0 w-full h-full z-10 brightness-50 bg-cover bg-center bg-no-repeat"
          style={{ backgroundImage: `url('/images/signup-backdrop.png')` }}
        ></div>
        <div className="w-full h-full z-20 relative flex flex-col items-center justify-center text-center">
          <h1 className="text-9xl font-bold text-primary-foreground">NESCAP</h1>
          <p className="text-lg font-medium text-primary-foreground">
            NESREA CONFORMITY ASSESSMENT PORTAL
          </p>
          <p className="bg-white rounded-lg p-3 max-w-md text-primary-700 font-medium border-primary-500 border-2 uppercase">
            For further enquiries, visit us at... No. 56 lome crescent, wuse
            zone 7, Abuja, Nigeria. <br />
            Contact us at: <br />
            dg@nesrea.gov.ng, info@nesrea.gov.ng, +2349153993191
          </p>
        </div>
      </div>
      <div className="flex flex-col relative">
        <div className="w-full h-full max-w-lg m-auto p-12 flex flex-col items-center justify-center gap-7">
          <img src="/images/Logo.png" className="w-[200px] mb-5" alt="" />
          <div className="w-full flex items-center justify-between">
            <Link
              to="/user/sign-in"
              className="text-primary underline text-sm flex items-center"
            >
              <ChevronLeft size={15} /> Back to Sign in
            </Link>
            <h1 className="font-medium text-sm">Reset User Password</h1>
          </div>
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="w-full flex flex-col gap-4"
            >
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>User Email</FormLabel>
                    <FormControl>
                      <Input placeholder="example@email.com" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="currentPassword"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Current Password</FormLabel>
                    <FormControl>
                      <PasswordInput placeholder="************" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="newPassword"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>New Password</FormLabel>
                    <FormControl>
                      <PasswordInput
                        placeholder="************"
                        {...field}
                        onChange={(e) => {
                          form.setValue("newPassword", e.target.value);
                          form.trigger("newPassword");
                          form.trigger("confirmPassword");
                        }}
                      />
                    </FormControl>
                    {form.formState.dirtyFields?.newPassword && (
                      <ul className="mt-6 gap-3 grid">
                        {passwordMessages.map((message, index) => {
                          const isSatisfied = !validatePassword(
                            field.value
                          ).includes(message);
                          return (
                            <div key={index} className="flex gap-2">
                              <span
                                className={cn(
                                  "text-xs",
                                  isSatisfied
                                    ? "text-green-600"
                                    : "text-red-600"
                                )}
                              >
                                {isSatisfied ? (
                                  <CheckCircle size={15} />
                                ) : (
                                  <XCircle size={15} />
                                )}
                              </span>
                              <li
                                className={cn(
                                  "text-xs",
                                  isSatisfied
                                    ? "text-green-600"
                                    : "text-red-600"
                                )}
                              >
                                {message}
                              </li>
                            </div>
                          );
                        })}
                      </ul>
                    )}
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="confirmPassword"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Confirm Password</FormLabel>
                    <FormControl>
                      <PasswordInput placeholder="************" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <Button
                type="submit"
                disabled={loading || !form.formState.isValid}
              >
                {loading ? "Updating..." : "Update Password"}
              </Button>
            </form>
          </Form>
        </div>
      </div>
    </div>
  );
}
