import { Button } from "@/components/ui/button";
import DashboardHeader from "@/components/ui/dashboard-header";
import { Input } from "@/components/ui/input";
import { ScrollArea } from "@/components/ui/scroll-area";
import { useStore } from "@/contexts/userStore";
import { cn, getBackendUrl } from "@/lib/utils";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { format } from "fecha";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "sonner";

const SupportCategories = [
  "import-clearance",
  "document-upload",
  "log-out",
  "payments",
] as const;

export default function AdminChatPage() {
  const userStore = useStore((state) => state.user);
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [message, setMessage] = useState("");
  const queryClient = useQueryClient();
  const [isActionLoading, setIsActionLoading] = useState(false);

  const { data, isLoading } = useQuery({
    queryKey: ["admin-chat-messages", id],
    queryFn: async () => {
      if (!id) {
        return [];
      }
      const { data } = await axios.get(
        `${getBackendUrl()}/get-ticket-chats/${id}`
      );

      return data?.ticketlist;
    },
    enabled: !!id,
  });

  const sendMessageMutation = useMutation({
    mutationFn: async ({ id, message }: { id: string; message: string }) => {
      await axios.post(`${getBackendUrl()}/send-message`, {
        sender: userStore.email,
        ticketid: id,
        message,
        receiver: "ADMIN",
      });
      setIsActionLoading(false);
    },
    onMutate: async ({ id, message }) => {
      // Cancel ongoing queries for this subject
      await queryClient.cancelQueries({
        queryKey: ["support-messages", id],
      });

      // Get the current data
      const previousMessages = queryClient.getQueryData([
        "support-messages",
        id,
      ]);

      // Optimistically update the data
      queryClient.setQueryData(["support-messages", id], (old: any) => [
        ...(old || []),
        {
          id: Date.now(),
          message_id: `MSG${Date.now()}`,
          sender: userStore.email,
          subject: "Profile not approved yet",
          message,
          receiver: "ADMIN",
          sent_status: "SENT",
          receive_status: "NEW",
          status: "SENT",
          conversation_id: id,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
          activities: "COMPLAINT",
        }, // Add a fake ID for the optimistic update
      ]);

      // Return a context to rollback if necessary
      return { previousMessages };
    },
    onError: (_error, _variables, context: any) => {
      // Rollback on error
      queryClient.setQueryData(
        ["support-messages", id],
        context.previousMessages
      );
    },
    onSettled: () => {
      // Refetch the data
      queryClient.invalidateQueries({
        queryKey: ["support-messages", id],
      });
    },
  });

  const handleSendMessage = () => {
    if (!message.trim() || !id) return;

    sendMessageMutation.mutate({ id, message });
    setMessage(""); // Clear the input field
  };

  const handleMessageInit = async (message: string) => {
    try {
      const subject = `Support-${format(new Date(), "isoDate")}`;
      const sender = userStore.email;
      const { data } = await axios.post(
        `${getBackendUrl()}/admin-create-new-ticket`,
        {
          sender,
          subject,
        }
      );
      const id = data?.ticketinfo.ticket_id;
      setSearchParams({ id });
      sendMessageMutation.mutate({
        id,
        message,
      });
    } catch (error) {
      toast.error("Error occurred while sending message");
    }
  };

  return (
    <div className="w-full min-h-[80vh] flex flex-col">
      <div className="mt-auto flex flex-col gap-10">
        {!id && (
          <>
            <div className="flex items-center gap-3">
              <form
                className="w-full flex items-center gap-3 border pr-3 rounded-lg"
                onSubmit={
                  !isActionLoading
                    ? (e) => {
                        e.preventDefault();
                        console.log("SUBMITTING");
                        if (!id) {
                          handleMessageInit(message);
                          return;
                        }
                        if (message.length > 0) {
                          handleSendMessage();
                        }
                      }
                    : () => {}
                }
              >
                <Input
                  placeholder="Type Message"
                  value={message}
                  className="border-none"
                  disabled={isActionLoading}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <Button
                  type="submit"
                  disabled={isActionLoading}
                  className="bg-transparent hover:bg-transparent border-none p-0"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M9.82149 5.5611L15.234 7.99625C16.8793 8.73647 18.1742 9.31904 19.0526 9.87072C19.9202 10.4156 20.5982 11.0617 20.5982 11.9991C20.5982 12.9365 19.9202 13.5826 19.0526 14.1275C18.1742 14.6792 16.8793 15.2617 15.234 16.002L9.82149 18.4371C8.81586 18.8896 8.03355 19.2415 7.42269 19.47C6.82494 19.6935 6.29071 19.8403 5.81568 19.7886C4.66351 19.6633 3.67207 18.8733 3.43212 17.7578C3.33042 17.2851 3.47262 16.7562 3.67694 16.2068C3.88848 15.638 4.22471 14.9076 4.65305 13.9771L4.6646 13.952C4.99937 13.2248 5.1282 12.9378 5.19394 12.6516C5.29282 12.2209 5.29282 11.7773 5.19394 11.3467C5.1282 11.0604 4.99937 10.7734 4.6646 10.0462L4.65305 10.0211C4.22471 9.09063 3.88848 8.36025 3.67694 7.79141C3.47262 7.24201 3.33042 6.71316 3.43212 6.2404C3.67207 5.12497 4.66351 4.33488 5.81568 4.20959C6.29071 4.15793 6.82494 4.30473 7.42269 4.52826C8.03355 4.75668 8.81586 5.10866 9.82149 5.5611ZM7.00238 5.65224C6.41504 5.43261 6.11499 5.38411 5.94541 5.40256C5.23496 5.47981 4.72188 5.95074 4.60528 6.49277C4.58358 6.59362 4.59873 6.82742 4.80168 7.37313C4.99549 7.89429 5.31228 8.58345 5.75465 9.5444C5.76434 9.56546 5.77392 9.58626 5.78338 9.60681C6.08019 10.2514 6.26663 10.6562 6.3635 11.0781C6.50297 11.6855 6.50297 12.3127 6.3635 12.9201C6.26663 13.342 6.08019 13.7469 5.78338 14.3914C5.77391 14.412 5.76434 14.4328 5.75465 14.4538C5.31228 15.4148 4.99549 16.1039 4.80168 16.6251C4.59873 17.1708 4.58358 17.4046 4.60528 17.5054C4.72188 18.0475 5.23495 18.5184 5.94541 18.5957C6.11499 18.6141 6.41504 18.5656 7.00238 18.346C7.57224 18.1329 8.31898 17.7973 9.3508 17.333L14.6931 14.9295C16.3973 14.1627 17.6151 13.6133 18.4144 13.1113C19.233 12.5971 19.3982 12.2631 19.3982 11.9991C19.3982 11.7351 19.233 11.4011 18.4144 10.8869C17.6151 10.385 16.3973 9.83552 14.6931 9.06876L9.3508 6.6652C8.31899 6.20097 7.57224 5.86534 7.00238 5.65224Z"
                      fill="#02040F"
                    />
                  </svg>
                </Button>
              </form>
            </div>
          </>
        )}
        {id && (
          <div className="flex flex-col gap-4">
            <ScrollArea className="max-h-[60vh] p-4">
              <div className="flex flex-col gap-3">
                {isLoading
                  ? "Loading messages..."
                  : data
                      ?.sort(
                        (a: any, b: any) =>
                          new Date(a.created_at).getTime() -
                          new Date(b.created_at).getTime()
                      )
                      .map((msg: any) => (
                        <div
                          key={msg.message_id}
                          className={cn(
                            "flex",
                            msg.sender === userStore.email
                              ? "justify-end"
                              : "justify-start"
                          )}
                        >
                          <div
                            className={
                              "p-3 rounded-lg max-w-xs bg-green-700 text-white"
                            }
                          >
                            {msg.message}
                          </div>
                        </div>
                      ))}
              </div>
            </ScrollArea>
            <div className="flex items-center gap-3">
              <form
                className="w-full flex items-center gap-3 border pr-3 rounded-lg"
                onSubmit={(e) => {
                  e.preventDefault();
                  console.log("SUBMITTING");
                  if (!id) {
                    handleMessageInit(message);
                    return;
                  }
                  handleSendMessage();
                }}
              >
                <Input
                  placeholder="Type Message"
                  value={message}
                  className="border-none"
                  onChange={(e) => setMessage(e.target.value)}
                />
                <Button
                  type="submit"
                  className="bg-transparent hover:bg-transparent border-none p-0"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M9.82149 5.5611L15.234 7.99625C16.8793 8.73647 18.1742 9.31904 19.0526 9.87072C19.9202 10.4156 20.5982 11.0617 20.5982 11.9991C20.5982 12.9365 19.9202 13.5826 19.0526 14.1275C18.1742 14.6792 16.8793 15.2617 15.234 16.002L9.82149 18.4371C8.81586 18.8896 8.03355 19.2415 7.42269 19.47C6.82494 19.6935 6.29071 19.8403 5.81568 19.7886C4.66351 19.6633 3.67207 18.8733 3.43212 17.7578C3.33042 17.2851 3.47262 16.7562 3.67694 16.2068C3.88848 15.638 4.22471 14.9076 4.65305 13.9771L4.6646 13.952C4.99937 13.2248 5.1282 12.9378 5.19394 12.6516C5.29282 12.2209 5.29282 11.7773 5.19394 11.3467C5.1282 11.0604 4.99937 10.7734 4.6646 10.0462L4.65305 10.0211C4.22471 9.09063 3.88848 8.36025 3.67694 7.79141C3.47262 7.24201 3.33042 6.71316 3.43212 6.2404C3.67207 5.12497 4.66351 4.33488 5.81568 4.20959C6.29071 4.15793 6.82494 4.30473 7.42269 4.52826C8.03355 4.75668 8.81586 5.10866 9.82149 5.5611ZM7.00238 5.65224C6.41504 5.43261 6.11499 5.38411 5.94541 5.40256C5.23496 5.47981 4.72188 5.95074 4.60528 6.49277C4.58358 6.59362 4.59873 6.82742 4.80168 7.37313C4.99549 7.89429 5.31228 8.58345 5.75465 9.5444C5.76434 9.56546 5.77392 9.58626 5.78338 9.60681C6.08019 10.2514 6.26663 10.6562 6.3635 11.0781C6.50297 11.6855 6.50297 12.3127 6.3635 12.9201C6.26663 13.342 6.08019 13.7469 5.78338 14.3914C5.77391 14.412 5.76434 14.4328 5.75465 14.4538C5.31228 15.4148 4.99549 16.1039 4.80168 16.6251C4.59873 17.1708 4.58358 17.4046 4.60528 17.5054C4.72188 18.0475 5.23495 18.5184 5.94541 18.5957C6.11499 18.6141 6.41504 18.5656 7.00238 18.346C7.57224 18.1329 8.31898 17.7973 9.3508 17.333L14.6931 14.9295C16.3973 14.1627 17.6151 13.6133 18.4144 13.1113C19.233 12.5971 19.3982 12.2631 19.3982 11.9991C19.3982 11.7351 19.233 11.4011 18.4144 10.8869C17.6151 10.385 16.3973 9.83552 14.6931 9.06876L9.3508 6.6652C8.31899 6.20097 7.57224 5.86534 7.00238 5.65224Z"
                      fill="#02040F"
                    />
                  </svg>
                </Button>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
