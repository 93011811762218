import React from "react";
import UserProfiling from "./components/UserProfiling";
import Registrations from "./components/Registrations";
import RevenueOverview from "./components/RevenueOverview";
import { useStore } from "@/contexts/userStore";

export default function ProfileManagementPage() {
  const SECTION_NAME = "Profile Management";

  const user = useStore((state) => state.user);
  const permission_data = user.admin_role?.find(
    (role) => role.name === SECTION_NAME
  );

  if (!permission_data || !permission_data.isAllowed) {
    return (
      <div>
        <h1 className="text-2xl font-bold">
          You do not have access to this page
        </h1>
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col gap-16">
      <UserProfiling />
      <Registrations />
      <RevenueOverview />
    </div>
  );
}
