import React, { useEffect } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import {
  government_form_schema_page_1,
  government_form_schema_page_1_type,
} from "../_schema";
import { Form } from "@/components/ui/form";
import {
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "@/components/ui/select";
import { LGAs, stateNamesMap } from "@/lib/site-data";
import { states } from "@/lib/site-data";
import { STATES } from "@/lib/interfaces";

export const GovernmentFirstForm = ({
  handleSubmit,
}: {
  handleSubmit: (values: government_form_schema_page_1_type) => void;
}) => {
  const form = useForm<government_form_schema_page_1_type>({
    resolver: zodResolver(government_form_schema_page_1),
    defaultValues: {
      institutionName: "",
      contactPerson: "",
      phoneNumber: "",
      street: "",
      tin: "",
    },
  });

  useEffect(() => {
    const subscription = form.watch((value, { name }) => {
      if (name) {
        form.trigger(name);
      }
    });
    return () => subscription.unsubscribe();
  }, [form]);
  return (
    <Form {...form}>
      <form
        className="flex flex-col gap-4"
        onSubmit={form.handleSubmit(handleSubmit)}
      >
        <FormField
          control={form.control}
          name="institutionName"
          render={({ field }) => (
            <FormItem className="w-full flex flex-col gap-2">
              <FormLabel>
                Institution Name <span className="text-destructive">*</span>
              </FormLabel>
              <FormControl>
                <Input
                  type="text"
                  placeholder="Enter Institution Name"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="contactPerson"
          render={({ field }) => (
            <FormItem className="w-full flex flex-col gap-2">
              <FormLabel>
                Contact Person <span className="text-destructive">*</span>
              </FormLabel>
              <FormControl>
                <Input
                  type="text"
                  placeholder="Enter Contact Person"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="phoneNumber"
          render={({ field }) => (
            <FormItem className="w-full flex flex-col gap-2">
              <FormLabel>
                Phone Number <span className="text-destructive">*</span>
              </FormLabel>
              <FormControl>
                <Input type="tel" placeholder="Enter Phone Number" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="state"
          render={({ field }) => (
            <FormItem className="flex-1 min-w-[300px] flex-col gap-3 flex">
              <FormLabel>State</FormLabel>
              <FormControl>
                <Select
                  {...field}
                  onValueChange={(currentValue: any) => {
                    const val: government_form_schema_page_1_type["state"] =
                      currentValue;
                    form.setValue("state", val);
                    const label: any = states.filter(
                      (state) => state.value == val
                    )[0].label;
                    const myLabel: STATES = label;
                    form.setValue("lga", LGAs[myLabel][0]);
                    field.onBlur();
                    form.trigger("state");
                  }}
                >
                  <SelectTrigger className="!m-0">
                    <SelectValue placeholder="State" />
                  </SelectTrigger>
                  <SelectContent>
                    {states.map((state) => (
                      <SelectItem key={state.value} value={state.value}>
                        {state.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="lga"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Local Government Area (LGA)</FormLabel>
              <FormControl>
                <Select
                  onValueChange={(value) => {
                    form.setValue("lga", value);
                    field.onBlur();
                    form.trigger("lga");
                  }}
                  {...field}
                >
                  <SelectTrigger className="">
                    <SelectValue placeholder="Local Government Area" />
                  </SelectTrigger>
                  <SelectContent>
                    {LGAs[stateNamesMap[form.getValues().state]] ? (
                      LGAs[stateNamesMap[form.getValues().state]].map((lga) => (
                        <SelectItem key={lga} value={lga}>
                          {lga}
                        </SelectItem>
                      ))
                    ) : (
                      <SelectItem value="n/a">Please Select a State</SelectItem>
                    )}
                  </SelectContent>
                </Select>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="street"
          render={({ field }) => (
            <FormItem className="w-full flex flex-col gap-2">
              <FormLabel>
                Street <span className="text-destructive">*</span>
              </FormLabel>
              <FormControl>
                <Input
                  type="text"
                  placeholder="Enter your street name"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="w-full py-3 border-b mb-2">
          <h3 className="font-medium">Government ID</h3>
          <p className="text-xs font-light text-neutral-600">
            Please enter your <strong>TIN</strong> or <strong>CAC</strong> for
            Identification.
          </p>
        </div>
        <FormField
          control={form.control}
          name="tin"
          render={({ field }) => (
            <FormItem className="w-full flex flex-col gap-2">
              <FormLabel>
                TIN
                {/* <span className="text-destructive">*</span> */}
              </FormLabel>
              <FormControl>
                <Input type="text" placeholder="Enter TIN" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button
          className="w-full max-w-[164px]"
          disabled={!form.formState.isValid}
        >
          Next
        </Button>
      </form>
    </Form>
  );
};
