import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { ImportItem } from "@/lib/interfaces";

// Define styles
const styles = StyleSheet.create({
  page: {
    padding: "64px 48px",
    fontFamily: "Helvetica",
    color: "#000",
    border: "5px solid green",
    overflow: "hidden",
    position: "relative",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
    paddingBottom: 10,
    gap: 5,
  },
  logo: {
    width: 60,
    height: 60,
    objectFit: "contain",
  },
  title: {
    fontSize: 14,
    fontWeight: "bold",
    textAlign: "center",
    color: "green",
    maxWidth: "100%",
    flex: 1,
    // display: "flex",
    flexWrap: "wrap",
  },
  boldText: {
    fontFamily: "Helvetica-Bold",
  },
  invoiceDetails: {
    fontSize: 10,
    marginBottom: 5,
  },
  table: {
    marginTop: 20,
    width: "100%",
    border: "1px solid #A6E72A",
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#A6E72A",
  },
  tableHeader: {
    backgroundColor: "#e2f2e6",
    fontFamily: "Helvetica-Bold",
  },
  tableCell: {
    flex: 1,
    fontSize: 10,
    padding: 5,
    textAlign: "center",
    borderRightWidth: 1,
    borderRightColor: "#A6E72A",
  },
  lastCell: {
    borderRightWidth: 0,
  },
  totalRow: {
    flexDirection: "row",
    justifyContent: "flex-end",
    fontSize: 12,
    fontWeight: "bold",
    marginTop: 10,
  },
  unpaid: {
    position: "absolute",
    fontFamily: "Helvetica-Bold",
    top: "10%",
    left: "-3%",
    width: "200px",
    backgroundColor: "#E19445",
    color: "#fff",
    fontSize: 16,
    padding: 10,
    transform: "rotate(-39.88deg)",
    transformOrigin: "left",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
  },
  paid: {
    position: "absolute",
    fontFamily: "Helvetica-Bold",
    top: "10%",
    left: "-3%",
    width: "200px",
    backgroundColor: "green",
    color: "#fff",
    fontSize: 16,
    padding: 10,
    transform: "rotate(-39.88deg)",
    transformOrigin: "left",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
  },
  signature: {
    marginTop: "auto",
    marginBottom: 5,
    textAlign: "center",
    fontSize:8
  },
  footer: {
    // position: "absolute",
    // bottom: 20,
    // left: 40,
    // right: 40,
    fontSize: 8,
    textAlign: "center",
  },
});

interface InvoiceProps {
  hasPaid: boolean;
  invoiceNumber: string;
  invoiceDate: string;
  name: string;
  address: string;
  import_items: ImportItem[];
  waiverReduction: number;
  costOverride?: number;
}

// Proforma Invoice Component
const ClearanceInvoice = ({
  hasPaid,
  invoiceNumber,
  name,
  invoiceDate,
  address,
  import_items,
  waiverReduction,
  costOverride
}: InvoiceProps) => {
  let totalCost =
    import_items.length > 0
      ? import_items
          .map(({ quantity, cost }) => Number(cost || "100"))
          .reduce((curr, acc) => curr + acc)
      : 0;
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text style={hasPaid ? styles.paid : styles.unpaid}>
          {hasPaid ? "PAID" : "UNPAID"}
        </Text>

        {/* Header Section */}
        <View style={styles.header}>
          <Image style={styles.logo} src="/images/coatofarms.png" />
          <Text style={styles.title}>
            {"NATIONAL ENVIRONMENTAL STANDARDS AND REGULATIONS ENFORCEMENT AGENCY(NESREA)".split(
              ""
            )}
          </Text>
          <Image style={styles.logo} src="/images/Logo.png" />
        </View>
        <View>
          <Text
            style={{
              color: "green",
              fontWeight: "bold",
              margin: "24px 0 5px 0",
              fontSize: 12,
              fontFamily: "Helvetica-Bold",
              width: "100%",
              textAlign: "right",
            }}
          >
            Proforma Invoice #{invoiceNumber}
          </Text>
          <Text
            style={{
              fontWeight: "bold",
              fontSize: 12,
              fontFamily: "Helvetica-Bold",
              width: "100%",
              textAlign: "right",
            }}
          >
            Amount: {costOverride || totalCost}
          </Text>
        </View>

        {/* Invoice Details */}
        <View>
          <Text style={styles.invoiceDetails}>
            <Text style={styles.boldText}>Invoice Date:</Text> {invoiceDate}
          </Text>
          <Text style={styles.invoiceDetails}>
            <Text style={styles.boldText}>Name:</Text> {name}
          </Text>
          <Text style={styles.invoiceDetails}>
            <Text style={styles.boldText}>Address:</Text> {address || "N/A"}
          </Text>
        </View>

        {/* Table */}
        {import_items.length > 0 && (
          <View style={styles.table}>
            {/* Table Header */}
            <View style={[styles.tableRow, styles.tableHeader]}>
              <Text style={styles.tableCell}>S/N</Text>
              <Text style={styles.tableCell}>Description</Text>
              <Text style={styles.tableCell}>QTY</Text>
              <Text style={styles.tableCell}>Unit</Text>
              <Text style={styles.tableCell}>Rate</Text>
              <Text style={[styles.tableCell, styles.lastCell]}>Amount</Text>
            </View>
            {/* Table Rows */}
            {import_items.map((item, index) => (
              <View style={styles.tableRow}>
                <Text style={styles.tableCell}>{index + 1}</Text>
                <Text style={styles.tableCell}>{item.itemName}</Text>
                <Text style={styles.tableCell}>{item.quantity}</Text>
                <Text style={styles.tableCell}>{item.unit}</Text>
                <Text style={styles.tableCell}>
                  {(
                    Number(item.cost || "100") / Number(item.quantity)
                  ).toLocaleString()}
                </Text>
                <Text style={[styles.tableCell, styles.lastCell]}>
                  {Number(item.cost || "100").toLocaleString()}
                </Text>
              </View>
            ))}

            <View style={styles.tableRow}>
              <Text style={styles.tableCell}></Text>
              <Text style={styles.tableCell}></Text>
              <Text style={styles.tableCell}></Text>
              <Text style={styles.tableCell}></Text>
              <Text
                style={[styles.tableCell, { fontFamily: "Helvetica-Bold" }]}
              >
                Total
              </Text>
              <View
                style={[
                  styles.tableCell,
                  styles.lastCell,
                  {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  },
                ]}
              >
                {waiverReduction && (
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {/* {"\u20A6"} */}
                    {(totalCost - waiverReduction).toLocaleString()}
                  </Text>
                )}
                <Text
                  style={[
                    styles.tableCell,
                    styles.lastCell,
                    !waiverReduction
                      ? { fontFamily: "Helvetica-Bold" }
                      : { textDecoration: "line-through" },
                  ]}
                >
                  {/* {"\u20A6"} */}
                  {totalCost.toLocaleString()}
                </Text>
              </View>
            </View>
          </View>
        )}

        {/* Signature Section */}
        <View style={styles.signature}>
          <Image
            style={{
              width: 100,
              height: 100,
              objectFit: "contain",
              margin: "0 auto",
            }}
            src={"/images/signature.png"}
          />
          <Text>Isa Abdussalam</Text>
          <Text>Director (Inspection and Enforcement)</Text>
          <Text>For: Director General/CEO</Text>
        </View>

        {/* Footer Section */}
        <View style={styles.footer}>
          <Text>
            No. 56, Lome Crescent, Wuse Zone 7, P.M.B. 641, Garki, Abuja, FCT,
            Nigeria.
          </Text>
          <Text>
            Tel: +234-814-609-9778 E-mail: dg@nesrea.gov.ng www.nesrea.gov.ng
          </Text>
        </View>

        {/* Total Row */}
        {/* <View style={styles.totalRow}>
        <Text>Total: 19,500,000</Text>
      </View> */}
      </Page>
    </Document>
  );
};

export default ClearanceInvoice;
