import React, { useEffect, useState } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { ArrowUpDown, MoreHorizontal, Pencil } from "lucide-react";

import { Button, buttonVariants } from "@/components/ui/button";

import { cn, formatDate, getBackendUrl } from "@/lib/utils";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Checkbox } from "@/components/ui/checkbox";
import { agentStoreInitialState, useStore } from "@/contexts/agentStore";
import {
  ImportDocumentationData,
  RegistrationPaymentHistoryData,
} from "@/lib/interfaces";
import { Link } from "react-router-dom";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import {
  Form,
  FormField,
  FormLabel,
  FormItem,
  FormMessage,
  FormControl,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { AdminPermissions, Permission, PERMISSIONS } from "@/lib/permissions";
import { Select } from "@/components/ui/select";
import {
  AGENT_PERMISSIONS,
  COMPANY_PERMISSIONS,
  GOVERNMENT_PERMISSIONS,
  INDIVIDUAL_PERMISSIONS,
} from "@/lib/permissions";
import { INITIAL_STATE_PERMISSIONS } from "@/lib/permissions";
import { sections } from "@/lib/admin-permissions";
import axios from "axios";
import { toast } from "sonner";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import { Switch } from "@/components/ui/switch";

interface Admin {
  id: number;
  name: string;
  email: string;
  staff_id: string;
  department: string;
  email_verified_at: null;
  account_type: string;
  account_role: string;
  login_status: number;
  email_verication_code: string;
  email_verification_status: string;
  created_at: string;
  updated_at: string;
  status: string;
  admin_role: AdminPermissions[] | null;
}

export const RolesPermissionsColumns: ColumnDef<Admin>[] = [
  {
    accessorKey: "name",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Admin Name
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
    cell: ({ row }) => {
      const { name } = row.original;
      return <div className="capitalize">{name?.toLowerCase()}</div>;
    },
  },
  {
    accessorKey: "staff_id",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Admin ID
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
  },
  {
    accessorKey: "account_type",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Role
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
    cell: ({ row }) => {
      const { account_type } = row.original;
      return <div className="capitalize">{account_type?.toLowerCase()}</div>;
    },
  },
  {
    accessorKey: "department",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Department
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
    cell: ({ row }) => {
      const { department } = row.original;
      return <div className="capitalize">{department?.toLowerCase()}</div>;
    },
  },

  {
    accessorKey: "action",
    header: ({ column }) => {
      return (
        <div className="flex items-center">
          Action
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
    cell: ({ row }) => {
      const { name, staff_id, department, email, admin_role } = row.original;
      const [selectedPermissions, setSelectedPermissions] = React.useState<
        AdminPermissions[]
      >(
        admin_role?.length
          ? admin_role
          : 
          sections.map((s) => ({
              ...s,
              permissions: s.permissions.map((p) => ({
                ...p,
                permissions: [] as string[],
              })),
            }))
      );
      const [selectedSection, setSelectedSection] = React.useState(
        admin_role?.[0] || sections[0]
      );
      const [isActionLoading, setIsActionLoading] = useState(false);

      const permission_templates = {
        agent: AGENT_PERMISSIONS,
        company: COMPANY_PERMISSIONS,
        government: GOVERNMENT_PERMISSIONS,
        individual: INDIVIDUAL_PERMISSIONS,
      };
      console.log("ADMIN ROLE", admin_role);
      const handleAssignPermissions = async () => {
        try {
          setIsActionLoading(true);
          await axios.post(`${getBackendUrl()}/update-admin-info`, {
            name: name || "admin",
            staffid: staff_id,
            email,
            department,
            roles: selectedPermissions,
          });
          toast.success("Role created successfully");

          setIsActionLoading(false);
          window.location.reload();
        } catch (error) {
          toast.error("An error occurred");
          setIsActionLoading(false);
        }

        // console.log(selectedPermissions, "selectedPermissions");
      };

      // const activeSection = sections.find(
      //   (s) => s.name === selectedSection.name
      // );

      console.log("ACTIVE SECTION", selectedSection);

      return (
        <div className="grid w-fit grid-cols-2 gap-7">
          <Dialog>
            <DialogTrigger className="underline text-primary-700 text-sm">
              Edit
            </DialogTrigger>

            <DialogContent className="w-full min-w-[700px] max-h-[85vh] overflow-y-auto">
              <div className="pt-5 flex w-full justify-between items-center">
                <h3 className="font-medium text-primary-700">Assign Roles</h3>
                <Button
                  disabled={isActionLoading}
                  onClick={handleAssignPermissions}
                >
                  Assign Permissions
                </Button>
              </div>
              <div className="w-full p-2 bg-secondary-50 grid grid-cols-3">
                <h1 className="uppercase text-xl font-medium">module</h1>
                <h1 className="uppercase text-xl font-medium">permissions</h1>
              </div>

              <Carousel
                opts={{
                  align: "center",
                  // loop: true,
                  slidesToScroll: 2,
                }}
                className="w-full max-w-[630px] h-full flex items-center"
              >
                <CarouselPrevious className="border-none p-0 w-fit my-auto h-fit !bottom-0 !left-0 !flex !relative !right-0 !top-0 translate-y-0" />

                <CarouselContent>
                  {selectedPermissions.map((section, sectionIndex) => (
                    <CarouselItem key={sectionIndex} className="basis-1/4">
                      <Button
                        variant={
                          selectedSection.name === section.name
                            ? "default"
                            : "outline"
                        }
                        className="text-xs w-full text-center border-none"
                        onClick={() => {
                          setSelectedSection(section);
                          console.log(section, "section");
                        }}
                        type="button"
                      >
                        {section.name}
                      </Button>
                    </CarouselItem>
                  ))}
                </CarouselContent>

                <CarouselNext className="border-none p-0 w-fit my-auto h-fit !bottom-0 !left-0 !flex !relative !right-0 !top-0 translate-y-0" />
              </Carousel>

              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-10">
                  <h3 className="font-medium text-primary-700">
                    Assign project related task to roles
                  </h3>
                  <div className="flex items-center gap-3">
                    Allow Section
                    <Switch
                      checked={
                        selectedPermissions.find(
                          (p) => p.name === selectedSection.name
                        )?.isAllowed
                      }
                      onCheckedChange={(isChecked) => {
                        sections
                          .find((s) => s.name === selectedSection.name)
                          ?.permissions.map((permission) => {
                            setSelectedPermissions((prev) => {
                              return prev.map((p) => {
                                if (p.name == selectedSection.name) {
                                  return {
                                    ...p,
                                    isAllowed: isChecked as boolean,
                                    permissions: p.permissions.map((pitem) => {
                                      if (pitem.module === permission.module) {
                                        return {
                                          ...pitem,
                                          isAllowed: isChecked as boolean,
                                          permissions: isChecked
                                            ? permission.permissions
                                            : [],
                                        };
                                      }
                                      return pitem;
                                    }),
                                  };
                                }
                                return p;
                              });
                            });
                          });

                        setSelectedSection((prev) => {
                          return {
                            ...prev,
                            isAllowed: isChecked as boolean,
                          };
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="w-full p-2 bg-secondary-50 grid grid-cols-3">
                  <h1 className="uppercase text-xl font-medium">module</h1>
                  <h1 className="uppercase text-xl font-medium">permissions</h1>
                </div>
                {sections
                  .find((s) => s.name === selectedSection.name)
                  ?.permissions.map((permission) => (
                    <div className="w-full p-3 bg-[#F1F1F1] grid grid-cols-3">
                      <div className="flex items-start gap-2 w-full">
                        <Checkbox
                          id={`${permission.module}-${selectedSection.name}`}
                          disabled={
                            !selectedPermissions.find(
                              (p) => p.name === selectedSection.name
                            )?.isAllowed
                          }
                          checked={
                            selectedPermissions
                              .find((p) => p.name === selectedSection.name)
                              ?.permissions.find(
                                (p) => p.module === permission.module
                              )?.isAllowed
                          }
                          onCheckedChange={(isChecked) => {
                            setSelectedPermissions((prev) => {
                              return prev.map((p) => {
                                if (p.name === selectedSection.name) {
                                  return {
                                    ...p,
                                    permissions: p.permissions.map((pitem) => {
                                      if (pitem.module === permission.module) {
                                        return {
                                          ...pitem,
                                          isAllowed: isChecked as boolean,
                                          permissions: isChecked
                                            ? permission.permissions
                                            : [],
                                        };
                                      }
                                      return pitem;
                                    }),
                                  };
                                }
                                return p;
                              });
                            });
                          }}
                        />
                        <Label
                          className="capitalize cursor-pointer font-medium"
                          htmlFor={`${permission.module}-${selectedSection.name}`}
                        >
                          {permission.module.split("_").join(" ")}
                        </Label>
                      </div>
                      <div className="w-full grid grid-cols-3 gap-3 col-span-2">
                        {permission.permissions.map((perm, index) => (
                          <div className="flex items-start gap-2">
                            <Checkbox
                              id={`${perm}-${index}-${permission.module}`}
                              disabled={
                                !selectedPermissions
                                  .find((p) => p.name === selectedSection.name)
                                  ?.permissions.find(
                                    (pitem) =>
                                      pitem.module === permission.module
                                  )?.isAllowed
                              }
                              checked={selectedPermissions
                                .find((p) => p.name === selectedSection.name)
                                ?.permissions.find(
                                  (pitem) => pitem.module === permission.module
                                )
                                ?.permissions.includes(perm)}
                              onCheckedChange={(isChecked) => {
                                setSelectedPermissions((prev) => {
                                  return prev.map((p) => {
                                    if (p.name == selectedSection.name) {
                                      return {
                                        ...p,
                                        permissions: p.permissions.map((p2) => {
                                          if (p2.module === permission.module) {
                                            return {
                                              ...p2,
                                              permissions: isChecked
                                                ? [...p2.permissions, perm]
                                                : p2.permissions.filter(
                                                    (p) => p !== perm
                                                  ),
                                            };
                                          }
                                          return p2;
                                        }),
                                      };
                                    }
                                    return p;
                                  });
                                });
                              }}
                            />
                            <Label
                              className="capitalize cursor-pointer text-xs font-normal"
                              htmlFor={`${perm}-${index}-${permission.module}`}
                            >
                              {perm.split("-").join(" ").split(":").join(" ")}
                            </Label>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
              </div>
            </DialogContent>
          </Dialog>
        </div>
      );
    },
  },
];
