import React, { useEffect, useState } from "react";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSeparator,
  InputOTPSlot,
} from "@/components/ui/input-otp";
import { REGEXP_ONLY_DIGITS } from "input-otp";
import { Button, buttonVariants } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import axios from "axios";
import { cn, getBackendUrl } from "@/lib/utils";
import { Mail } from "lucide-react";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Form, FormLabel } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { DialogClose } from "@radix-ui/react-dialog";

export default function OTP({
  email_,
  hasSent_,
}: {
  email_?: string;
  hasSent_?: boolean;
}) {
  const [email, setEmail] = useState(email_);
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState("");
  const [isComplete, setIsComplete] = useState(false);
  const [cooldown, setCooldown] = useState<number | null>(null);
  const navigate = useNavigate();

  const [hasSent, setHasSent] = useState(hasSent_ || false);

  const otpFormSchema = z.object({
    email: z.string().email(),
  });

  const form = useForm<z.infer<typeof otpFormSchema>>({
    resolver: zodResolver(otpFormSchema),
    defaultValues: {
      email,
    },
  });

  const handleVerification = async () => {
    try {
      setIsLoading(true);
      await axios.post(`${getBackendUrl()}/verify-otp`, {
        email: email,
        otp: value,
      });
      toast.success("You have been verified", {
        description: <>You can now sign in</>,
      });
      setIsLoading(false);
      setValue("");
      setEmail("");
      navigate("/user/sign-in");
    } catch (error: any) {
      const errMessage = error.response?.data?.message;
      toast.error(
        errMessage || "An error occurred while verifying your account",
        {
          description: "Please try again",
        }
      );
      setIsLoading(false);
    }
  };

  const handleResendCode = async (data: { email: string }) => {
    try {
      setIsLoading(true);
      await axios.post(`${getBackendUrl()}/resend-otp`, {
        email: data.email,
      });
      setEmail(data.email);
      toast.success("A new code has been sent to your email", {
        description: <>Please check your inbox</>,
      });
      setIsLoading(false);
      setHasSent(true);
      const cooldownTime = 15 * 60; // 15 minutes in seconds
      setCooldown(cooldownTime);
      localStorage.setItem("nesrea_otp_cooldown_start", Date.now().toString());
      localStorage.setItem(
        "nesrea_otp_cooldown_duration",
        cooldownTime.toString()
      );
    } catch (error) {
      toast.error("An error occurred while resending the code", {
        description: "Please try again",
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const cooldownStart = localStorage.getItem("nesrea_otp_cooldown_start");
    const cooldownDuration = localStorage.getItem(
      "nesrea_otp_cooldown_duration"
    );

    if (cooldownStart && cooldownDuration) {
      const elapsed = Math.floor((Date.now() - parseInt(cooldownStart)) / 1000);
      const remainingCooldown = parseInt(cooldownDuration) - elapsed;
      if (remainingCooldown > 0) {
        setCooldown(remainingCooldown);
      } else {
        localStorage.removeItem("nesrea_otp_cooldown_start");
        localStorage.removeItem("nesrea_otp_cooldown_duration");
      }
    }
  }, []);

  useEffect(() => {
    if (cooldown !== null) {
      const interval = setInterval(() => {
        setCooldown((prev) => (prev !== null && prev > 0 ? prev - 1 : null));
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [cooldown]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  return (
    <>
      <div className="flex items-center gap-5 w-full">
        <Mail size={45} className="p-3 border rounded-[12px] text-primary" />
        <div className="text-left">
          <h4 className="font-medium">
            {hasSent ? "Check your email" : "Click 'Resend Code' to send OTP"}
          </h4>
          <div className="flex gap-1 items-center">
            {hasSent && (
              <p className="text-sm text-neutral-500">
                {"We've"} sent a code to
              </p>
            )}
            <Dialog>
              <DialogTrigger
                className={cn(
                  "underline text-sm",
                  email ? "text-primary-500" : "text-destructive"
                )}
              >
                {email || "email not set"}
              </DialogTrigger>
              <DialogContent>
                <Form {...form}>
                  <form
                    onSubmit={form.handleSubmit(
                      (data) => data.email && setEmail(data.email)
                    )}
                  >
                    <div className="space-y-4">
                      <div>
                        <FormLabel
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Email address
                        </FormLabel>
                        <Input
                          id="email"
                          type="email"
                          {...form.register("email")}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                        {form.formState.errors.email && (
                          <p className="mt-2 text-sm text-red-600">
                            {form.formState.errors.email.message}
                          </p>
                        )}
                      </div>
                      <DialogClose
                        type="submit"
                        className={cn(buttonVariants({ variant: "default" }))}
                        disabled={!form.formState.isValid}
                      >
                        Set Email
                      </DialogClose>
                    </div>
                  </form>
                </Form>
              </DialogContent>
            </Dialog>
          </div>
        </div>
      </div>

      <div className="text-left space-y-2">
        <InputOTP
          value={value}
          className="w-full"
          onChange={(e) => {
            if (e.length < 6) {
              setIsComplete(false);
            }
            setValue(e);
          }}
          onComplete={() => setIsComplete(true)}
          maxLength={6}
          pattern={REGEXP_ONLY_DIGITS}
        >
          <InputOTPGroup>
            <InputOTPSlot index={0} />
            <InputOTPSlot index={1} />
            <InputOTPSlot index={2} />
          </InputOTPGroup>
          <InputOTPSeparator />
          <InputOTPGroup>
            <InputOTPSlot index={3} />
            <InputOTPSlot index={4} />
            <InputOTPSlot index={5} />
          </InputOTPGroup>
        </InputOTP>
        {email && (
          <h5 className="text-xs text-destructive">
            Code expires in 10 minutes
          </h5>
        )}
      </div>
      <div className="flex gap-4 items-center justify-end w-full">
        <Dialog>
          <DialogTrigger
            onClick={() => {
              setHasSent(false);
            }}
            className={cn(buttonVariants({ variant: "outline" }))}
            disabled={cooldown !== null}
          >
            {cooldown !== null
              ? `Resend Code (${formatTime(cooldown)})`
              : "Resend Code"}
          </DialogTrigger>
          {!hasSent ? (
            <DialogContent>
              <Form {...form}>
                <form onSubmit={form.handleSubmit(handleResendCode)}>
                  <div className="space-y-4">
                    <div>
                      <FormLabel
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Email address
                      </FormLabel>
                      <Input
                        id="email"
                        type="email"
                        {...form.register("email")}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                      {form.formState.errors.email && (
                        <p className="mt-2 text-sm text-red-600">
                          {form.formState.errors.email.message}
                        </p>
                      )}
                    </div>
                    <Button type="submit" disabled={isLoading}>
                      {isLoading ? "Sending..." : "Send Code"}
                    </Button>
                  </div>
                </form>
              </Form>
            </DialogContent>
          ) : (
            <></>
          )}
        </Dialog>
        <Button
          onClick={() => handleVerification()}
          disabled={!isComplete || isLoading || !email}
        >
          {isLoading ? "Verifying..." : "Verify"}
        </Button>
      </div>
    </>
  );
}
