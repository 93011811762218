import React from "react";
import { ColumnDef } from "@tanstack/react-table";
import { ArrowUpDown } from "lucide-react";
import { cn, formatDate } from "@/lib/utils";
import { agentStoreInitialState, useStore } from "@/contexts/agentStore";
import { Checkbox } from "@/components/ui/checkbox";
import { Link } from "react-router-dom";
import { userCategoryData } from "@/lib/dummy-data/userCategoryData";
import { buttonVariants } from "@/components/ui/button";
import { format } from "fecha";

export const UserCategoryColumns: ColumnDef<{
  name: string;
  id: string;
  dateCreated: string;
  lastActive: string;
  category: string;
  status: string;
  email: string;
}>[] = [
  {
    accessorKey: "name",
    header: ({ column }) => (
      <div
        className="flex items-center cursor-pointer hover:underline"
        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
      >
        Importer Name
        <ArrowUpDown className="ml-2 h-4 w-4" />
      </div>
    ),
  },
  {
    accessorKey: "id",
    header: ({ column }) => (
      <div
        className="flex items-center cursor-pointer hover:underline"
        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
      >
        Importer ID
        <ArrowUpDown className="ml-2 h-4 w-4" />
      </div>
    ),
  },
  {
    accessorKey: "dateCreated",
    header: ({ column }) => (
      <div
        className="flex items-center cursor-pointer hover:underline"
        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
      >
        Date of Registration
        <ArrowUpDown className="ml-2 h-4 w-4" />
      </div>
    ),
    cell: ({ row }) => {
      const { dateCreated } = row.original;
      return <p>{format(new Date(dateCreated), "mediumDate")}</p>;
    },
  },
  // {
  //   accessorKey: "lastActive",
  //   header: ({ column }) => (
  //     <div
  //       className="flex items-center cursor-pointer hover:underline"
  //       onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
  //     >
  //       Last Active
  //       <ArrowUpDown className="ml-2 h-4 w-4" />
  //     </div>
  //   ),
  // },
  {
    accessorKey: "action",
    header: "Action",
    cell: ({ row }) => {
      const { id, email, category } = row.original;
      return (
        <Link
          className={cn(buttonVariants({ variant: "link" }))}
          to={`/admin/profile-management/user-profiling/details?id=${id}&email=${email}&category=${category}`}
          onClick={() => {}}
        >
          See Details
        </Link>
      );
    },
  },
];
