import { AdminPermissions, Permission } from "@/lib/permissions";
import { getBackendUrl } from "@/lib/utils";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

interface Admin {
  id: number;
  name: string;
  email: string;
  staff_id: string;
  department: string;
  email_verified_at: null;
  account_type: string;
  account_role: string;
  login_status: number;
  email_verication_code: string;
  email_verification_status: string;
  created_at: string;
  updated_at: string;
  status: string;
  admin_role: AdminPermissions[] | null;
}

interface AdminHeatmapEntry {
  id: number;
  email: string;
  name: string;
  staff_id: string;
  admin_action: string;
  created_date: string;
  created_at: string;
  updated_at: string;
}

interface HeatMapPeriod {
  label: string;
  value: number;
}

interface HeatMapDay {
  label: string;
  periods: HeatMapPeriod[];
}

const generateRandomValue = (): number => Math.floor(Math.random() * 100);

const convertAdminHeatmapData = (data: AdminHeatmapEntry[]): HeatMapDay[] => {
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const periods = [
    { label: "00:00 - 03:00", start: 0, end: 3 },
    { label: "03:00 - 06:00", start: 3, end: 6 },
    { label: "06:00 - 09:00", start: 6, end: 9 },
    { label: "09:00 - 12:00", start: 9, end: 12 },
    { label: "12:00 - 15:00", start: 12, end: 15 },
    { label: "15:00 - 18:00", start: 15, end: 18 },
    { label: "18:00 - 21:00", start: 18, end: 21 },
    { label: "21:00 - 00:00", start: 21, end: 24 },
  ];

  return daysOfWeek.map((day) => {
    const dayData = data.filter(
      (entry) => new Date(entry.created_date).getDay() === daysOfWeek.indexOf(day)
    );

    return {
      label: day,
      periods: periods.map((period) => {
        const periodData = dayData.filter((entry) => {
          const hour = new Date(entry.created_date).getHours();
          return hour >= period.start && hour < period.end;
        });

        return {
          label: period.label,
          value: periodData.length,
        };
      }),
    };
  });
};

const processData = (data: any[]) => {
  const totalAdmins = data.length;
  const activeAdmins = data.filter((admin) => admin.status === "active").length;
  const inactiveAdmins = data.filter(
    (admin) => admin.status === "inactive"
  ).length;
  const superAdmins = data.filter(
    (admin) => admin.account_type === "super_admin"
  ).length;
  const adminAdmins = data.filter(
    (admin) => admin.account_type === "admin"
  ).length;
  //   const totalAdminRoles = data.reduce((acc, admin) => {
  //     if (!acc.includes(admin.admin_role)) {
  //       acc.push(admin.admin_role);
  //     }
  //     return acc;
  //   }, [] as string[]).length;

  return {
    totalAdmins,
    activeAdmins,
    inactiveAdmins,
    superAdmins,
    adminAdmins,
    // totalAdminRoles,
  };
};

export const useAdminAnalytics = () => {
  const { data, error } = useQuery({
    queryKey: ["admin-analytics"],
    queryFn: async () => {
      const {
        data,
      }: {
        data?: {
          alladmin: {
            id: number;
            name: string;
            email: string;
            staff_id: string;
            department: string;
            email_verified_at: null;
            account_type: string;
            account_role: string;
            login_status: number;
            email_verication_code: string;
            email_verification_status: string;
            created_at: string;
            updated_at: string;
            status: string;
            admin_role: string | null;
          }[];
        };
      } = await axios.get(`${getBackendUrl()}/get-all-admin`);

      const {
        data: adminHeatMapData,
      }: { data: { adminheatmap: AdminHeatmapEntry[] } } = await axios.get(
        `${getBackendUrl()}/get-admin-heat-map`
      );
      return {
        admins: data?.alladmin || [],
        adminHeatMapData: convertAdminHeatmapData(
          adminHeatMapData.adminheatmap
        ),
      };
    },
  });
  return {
    analyticsData: data ? processData(data.admins) : null,
    adminsData:
      data?.admins?.map((admin) => ({
        ...admin,
        admin_role: JSON.parse(admin.admin_role || "[]"),
      })) || [],
    isLoading: !error && !data,
    error,
    heatmapData: data?.adminHeatMapData || [],
  };
};
