import React from "react";
import { DataTable } from "./data-table";
import { RecentActivityColumns } from "./columns";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { getBackendUrl } from "@/lib/utils";
import { useStore } from "@/contexts/userStore";
import LoaderWrapper from "@/components/ui/loader-wrapper";
import { FilterData } from "@/components/ui/filter";

export default function RecentActivities() {
  const userStore = useStore((state) => state.user);
  const { data, isLoading } = useQuery({
    queryKey: ["activities"],
    queryFn:
      userStore.account_type == "agent"
        ? async () => {
            const { data } = await axios.get(
              `${getBackendUrl()}/agent-activities/${userStore.email}`
            );
            return (
              data?.Agent_activities.map((activity: any) => ({
                title: activity.activities,
                name: activity.agent_name || activity.importer_name,
                date: activity.created_at,
                status: activity.status,
                id: activity.id,
              })) || []
            );
          }
        : async () => {
            const { data } = await axios.get(
              `${getBackendUrl()}/importer-activities/${userStore.email}`
            );
            return (
              data?.User_activities.map((activity: any) => ({
                title: activity.activities,
                name: activity.importer_name,
                date: activity.created_at,
                status: activity.status,
                id: activity.id,
              })) || []
            );
          },
  });

  const [data_, setData_] = React.useState<FilterData>([]);
  const [filteredData, setFilteredData] = React.useState<FilterData>([]);
  const filteredIDs = filteredData.map((d) => d.id);

  const updatedData = React.useMemo(() => {
    return data?.filter((stat: any) => filteredIDs.includes(stat.id)) || [];
  }, [data, filteredIDs]);

  React.useEffect(() => {
    const newData = data?.map((item: any) => ({
      id: item.id,
      name: item.name,
      title: item.title,
      date: item.date,
      status: item.status,
    }));
    setData_(newData || []);
    if (!filteredData.length) {
      setFilteredData(newData || []);
    }
  }, [data]);

  return (
    <LoaderWrapper isLoading={isLoading}>
      <DataTable
        columns={RecentActivityColumns}
        setData_={setData_}
        filteredData={filteredData}
        setFilteredData={setFilteredData}
        data_={data_}
        data={updatedData || []}
      />
    </LoaderWrapper>
  );
}
