import React from "react";
import { DataTable } from "./data-table";
import { ClientsColumns } from "./columns";
import { useStore } from "@/contexts/agentStore";
import { useStore as useStore_ } from "@/contexts/userStore";
import paymentsData from "@/lib/dummy-data/paymentsData";
import LoaderWrapper from "@/components/ui/loader-wrapper";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { getBackendUrl } from "@/lib/utils";
import { PayementTableData } from "@/lib/interfaces";
import { FilterData } from "@/components/ui/filter";

export default function PaymentsTable() {
  const agentStore = useStore((state) => state);
  const userStore = useStore_((state) => state.user);

  const { data, isLoading } = useQuery({
    queryKey: ["importer-payments"],
    queryFn: async () => {
      try {
        const { data } = await axios.get(
          `${getBackendUrl()}/get-user-payment-list/${userStore.email}`
        );
        const import_payments: PayementTableData[] = data.payments.map(
          (item: any) => ({
            title: item.activities,
            payment_id: item.reference,
            clearance_id: item.clearance_id,
            amount: item?.new_amount || item.amount,
            date_created: item.created_at,
            status: item.payment_status,
          })
        );
        return import_payments;
      } catch (error) {}
    },
  });

  const [data_, setData_] = React.useState<FilterData>([]);
  const [filteredData, setFilteredData] = React.useState<FilterData>([]);
  const filteredIDs = filteredData.map((d) => d.id);

  const updatedData = React.useMemo(() => {
    return data?.filter((stat) => filteredIDs.includes(stat.payment_id)) || [];
  }, [data, filteredIDs]);

  React.useEffect(() => {
    const newData = data?.map((item) => ({
      id: item.payment_id,
      clearance_id: item.clearance_id,
      value: Number(item.amount || 0),
      date: item.date_created,
      status: item.status,
    }));
    setData_(newData || []);
    if (!filteredData.length) {
      setFilteredData(newData || []);
    }
  }, [data]);
  return (
    <LoaderWrapper isLoading={isLoading}>
      <DataTable
        columns={ClientsColumns}
        setData_={setData_}
        filteredData={filteredData}
        setFilteredData={setFilteredData}
        data_={data_}
        data={updatedData || []}
      />
    </LoaderWrapper>
  );
}
