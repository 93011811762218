import React from "react";
import { DataTable } from "./data-table";
import { FalsifiedRecordsColumn } from "./columns";
import { useStore } from "@/contexts/agentStore";
import { falsifiedRecords } from "@/lib/dummy-data/falsifiedRecordsData";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { getBackendUrl } from "@/lib/utils";
import LoaderWrapper from "@/components/ui/loader-wrapper";

export default function FalsifiedRecordsTable() {
  const agentStore = useStore((state) => state);
  const { data, isLoading } = useQuery({
    queryKey: ["falsified-records"],
    queryFn: async () => {
      const { data } = await axios.get(
        `${getBackendUrl()}/get-all-report-certificates`
      );
      return data?.reportcerts || [];
    },
  });
  return (
    <LoaderWrapper isLoading={isLoading}>
      <DataTable columns={FalsifiedRecordsColumn} data={data || []} />
    </LoaderWrapper>
  );
}
