import React from "react";
import Road from "./animated/Road";
import SeaPort from "./animated/SeaPort";
import { Link } from "react-router-dom";
import { cn } from "@/lib/utils";
import { buttonVariants } from "@/components/ui/button";

export default function SimplifyImportClearance() {
  return (
    <div
      // id="services"
      className="w-full h-[85vh] p-12 pt-32 bg-primary-50 flex flex-col items-center text-center gap-5 relative"
    >
      <h1 className="text-5xl max-w-2xl relative z-10 leading-tight text-primary-700 font-bold">
        Get Your Import <br />
        Clearance in Record Time
      </h1>
      <p className="text-lg font-light text-primary max-w-xl">
        We ensure compliance with environmental standards for a safer and
        cleaner Nigeria.
      </p>
      <div className="flex gap-3 items-center w-full justify-center relative z-[99999999999]">
        <Link
          className={cn(buttonVariants({ variant: "default" }), "rounded-full w-full max-w-[150px]")}
          to={"/user/sign-up"}
        >
          Sign Up
        </Link>
        <Link
          to={"/user/sign-in"}
          className={cn(
            buttonVariants({ variant: "ghost" }),
            "w-full max-w-[150px] rounded-full hover:bg-white border-primary-700 border-2"
          )}
        >
          Login
        </Link>
      </div>
      <div className="w-full h-full absolute right-0 bottom-0 flex flex-col justify-end">
        <SeaPort />
        <Road />
      </div>
    </div>
  );
}
