import React from "react";
import { ColumnDef } from "@tanstack/react-table";
import { ArrowUpDown, MoreHorizontal, Pencil } from "lucide-react";

import { Button, buttonVariants } from "@/components/ui/button";

import { cn, formatDate } from "@/lib/utils";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Checkbox } from "@/components/ui/checkbox";
import { agentStoreInitialState, useStore } from "@/contexts/agentStore";
import {
  ImportDocumentationData,
  RegistrationPaymentHistoryData,
} from "@/lib/interfaces";
import { Link } from "react-router-dom";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import {
  Form,
  FormField,
  FormLabel,
  FormItem,
  FormMessage,
  FormControl,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { format } from "fecha";

export const WaiverHistoryColumns: ColumnDef<
  RegistrationPaymentHistoryData[0]
>[] = [
  {
    accessorKey: "sn",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          S/N
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
    cell: ({ row }) => {
      return <p>{row.index + 1}</p>;
    },
  },
  {
    accessorKey: "name",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Import Name
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
  },
  {
    accessorKey: "category",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Importer Category
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
    cell: ({ row }) => {
      return (
        <p className="capitalize">{`${row.original.category}`.toLowerCase()}</p>
      );
    },
  },
  {
    accessorKey: "nesreaID",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          NESREA ID
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
  },
  {
    accessorKey: "amount",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Amount
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
    cell: ({ row }) => {
      return (
        <p className="font-medium">
          ₦{Number(row.original.amount || "0").toLocaleString()}
        </p>
      );
    },
  },
  {
    accessorKey: "paymentDate",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Date Created
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
    cell: ({ row }) => {
      return (
        <p>
          {row.original.paymentDate
            ? format(new Date(row.original.paymentDate), "mediumDate")
            : "N/A"}
        </p>
      );
    },
  },

  // {
  //   accessorKey: "action",
  //   header: ({ column }) => {
  //     return (
  //       <div className="flex items-center">
  //         Action
  //         <ArrowUpDown className="ml-2 h-4 w-4" />
  //       </div>
  //     );
  //   },
  //   cell: ({ row }) => {
  //     return (
  //       <div className="grid gap-7">
  //         <Link
  //           className="underline text-primary-700 text-sm"
  //           to={`/admin/accounting/payments/details?id=${row.original.id}`}
  //         >
  //           See details
  //         </Link>
  //       </div>
  //     );
  //   },
  // },
];
