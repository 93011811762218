import React from "react";
import { ColumnDef } from "@tanstack/react-table";
import { ArrowUpDown, MoreHorizontal, Pencil } from "lucide-react";

import { Button, buttonVariants } from "@/components/ui/button";

import { cn, formatDate } from "@/lib/utils";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Checkbox } from "@/components/ui/checkbox";
import { agentStoreInitialState, useStore } from "@/contexts/agentStore";
import {
  CertificatesAdminData,
  CertificatesData,
  PaymentsSchema,
} from "@/lib/interfaces";
import { Link } from "react-router-dom";
import { format } from "fecha";

export const SupportColumns: ColumnDef<any>[] = [
  {
    accessorKey: "subject",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Subject
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
  },
  {
    accessorKey: "activities",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Activity
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
  },
  {
    accessorKey: "ticket_id",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Ticket Number
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
  },
  {
    accessorKey: "nesrea_id",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Nesrea ID
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
  },
  // {
  //   accessorKey: "receiver",
  //   header: ({ column }) => {
  //     return (
  //       <div
  //         className="flex items-center cursor-pointer hover:underline"
  //         onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
  //       >
  //         Admin Name
  //         <ArrowUpDown className="ml-2 h-4 w-4" />
  //       </div>
  //     );
  //   },
  // },
  {
    accessorKey: "status",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Status
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
    cell: ({ row }) => {
      let { ticket_status } = row.original;
      let isClosed = ticket_status.toLowerCase() == "closed";
      let isOpen = ticket_status.toLowerCase() == "open";
      return (
        <p
          className={cn(
            isOpen && "text-primary-300",
            isClosed && "text-orange-400",
            "font-semibold capitalize"
          )}
        >
          {ticket_status.toLowerCase()}
        </p>
      );
    },
  },
  {
    accessorKey: "action",
    header: ({ column }) => {
      return (
        <div className="flex items-center">
          Action
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
    cell: ({ row }) => {
      return (
        <div className="grid gap-7">
          <Link
            className="underline text-primary-700 text-sm"
            to={`/admin/support/support-chat?id=${row.original.ticket_id}?status=${row.original.ticket_status}`}
          >
            View
          </Link>
        </div>
      );
    },
  },
];
