import { Input } from "@/components/ui/input";
import { Search } from "lucide-react";
import React from "react";
import { UserNav } from "./UserNav";
import Notifications from "@/components/ui/Notifications";

export default function DashboardHeader() {
  return (
    <div className="w-full bg-primary-900 text-primary-foreground p-6 flex items-center justify-between">
      <div className="">
        <h1 className="text-2xl font-medium">Dashboard</h1>
        <p className="text-sm font-light">View your dashboard</p>
      </div>
      <div className="flex items-center gap-5">
        <div className="w-[300px] h-11 bg-primary-50 text-primary-300 px-5 py-2 flex items-center rounded-[16px]">
          <Search size={20} />
          <Input
            className="bg-transparent placeholder:text-primary-200 border-none outline-none"
            placeholder="search"
          />
        </div>
        <UserNav />
        <Notifications />
      </div>
    </div>
  );
}
