import React, { useEffect } from "react";
import { Input } from "./input";

interface DateInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  min?: string;
  max?: string;
}

const DateInput = React.forwardRef<HTMLInputElement, DateInputProps>(
  ({ id, min, max, ...props }, ref) => {
    useEffect(() => {
      const dateElement = document.getElementById(id) as HTMLInputElement;
      dateElement.min = min
        ? min.length > 0
          ? new Date(min).toISOString().split("T")[0]
          : new Date().toISOString().split("T")[0]
        : new Date().toISOString().split("T")[0];

      // dateElement.max = max
      //   ? max.toString().length > 0
      //     ? new Date(max).toISOString().split("T")[0]
      //     : new Date().toISOString().split("T")[0]
      //   : new Date().toISOString().split("T")[0];
      if (max) {
        dateElement.max = new Date(max).toISOString().split("T")[0];
      }
    }, [id, min, max]);

    return <input id={id} type="date" ref={ref} {...props} />;
  }
);

DateInput.displayName = "DateInput";

export { DateInput };
