import { cn } from "@/lib/utils";
import React from "react";

export default function LoaderWrapper({
  children,
  isLoading,
  page = true,
}: {
  children: React.ReactNode;
  isLoading: boolean;
  page?: boolean;
}) {
  return (
    <div className="relative w-full">
      {isLoading && (
        <div
          className={cn(
            "top-0 bg-primary/20 backdrop-blur-sm right-0 w-full z-[999999999999999999999999999999999] flex justify-center items-center",
            page ? "fixed h-screen" : "absolute h-full"
          )}
        >
          <div className="loader" />
        </div>
      )}
      {children}
    </div>
  );
}
