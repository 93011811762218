
import AdminTicketsTable from "@/components/tables/AdminTicketsTable";
import React from "react";

export default function AdminChatsPage() {
  return (
    <div>
      <AdminTicketsTable />
    </div>
  );
}
