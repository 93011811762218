import React from "react";
import { ColumnDef } from "@tanstack/react-table";
import { ArrowUpDown, MoreHorizontal, Pencil } from "lucide-react";

import { Button, buttonVariants } from "@/components/ui/button";

import { cn, formatDate, getBackendUrl } from "@/lib/utils";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Checkbox } from "@/components/ui/checkbox";
import { agentStoreInitialState, useStore } from "@/contexts/agentStore";
import { CategoriesData, Category, PaymentsSchema } from "@/lib/interfaces";
import { Link } from "react-router-dom";
import { Input } from "@/components/ui/input";
import {
  Form,
  FormControl,
  FormLabel,
  FormItem,
  FormField,
  FormMessage,
} from "@/components/ui/form";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "sonner";
import axios from "axios";

export const HSCodesColumns: ColumnDef<Category>[] = [
  {
    accessorKey: "sn",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          SN
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
    cell: ({ row }) => {
      return <p>{row.index + 1}</p>;
    },
  },
  {
    accessorKey: "label",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Item Category
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
  },
  {
    accessorKey: "hscode",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          HS Codes
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
  },

  {
    accessorKey: "action",
    header: ({ column }) => {
      return (
        <div className="flex items-center">
          Action
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
    cell: ({ row }) => {
      const [isActionLoading, setIsActionLoading] = React.useState(false);
      const formSchema = z.object({
        itemCategory: z
          .string()
          .min(1, { message: "Item Category is required" }),
        hscodes: z.string().min(1, { message: "HS Codes is required" }),
      });

      const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
          itemCategory: row.original.label,
          hscodes: row.original.hscode.split(".")[0],
        },
      });

      const handleDelete = async () => {
        setIsActionLoading(true);
        try {
          await axios.post(`${getBackendUrl()}/delete-category-all`, {
            categoryId: row.original.id,
          });
          setIsActionLoading(false);
          toast.success("deleted successfully");
          window.location.reload();
        } catch (error) {
          setIsActionLoading(false);
          toast.error("An Error Occurred");
        }
      };
      return (
        <div className="flex items-center gap-3">
          <Dialog>
            <DialogTrigger className="underline text-primary-700 text-sm">
              Edit
            </DialogTrigger>
            <DialogContent>
              <h1 className="text-xl font-semibold text-center">
                Edit Parent Category
              </h1>
              <Form {...form}>
                <form
                  onSubmit={form.handleSubmit(async (data) => {
                    try {
                      const updatedData = {
                        categoryId: row.original.id,
                        categoryLabel: data.itemCategory,
                        categoryCode: data.hscodes,
                        categoryCost: row.original.cost,
                      };
                      await axios.post(
                        `${getBackendUrl()}/store-categories`,
                        updatedData
                      );
                      toast.success("Changes saved successfully");
                      window.location.reload();
                    } catch (error) {
                      toast.error("An error occurred");
                    }
                  })}
                  className="flex flex-col gap-4"
                >
                  <FormField
                    control={form.control}
                    name="hscodes"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>HS Code</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="itemCategory"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Item Category</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  {/* <div className="">
                  <h5 className="w-full text-center font-medium text-primary-600">
                    HS Code
                  </h5>
                  <div className="flex items-center gap-3 w-full mt-2">
                    <FormField
                      control={form.control}
                      name="hscodes"
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <div className="flex items-center gap-3">
                              <Input
                                value={field.value}
                                // disabled={!itemCategoryId}
                                // disabled
                                onChange={(e) => {
                                  form.setValue("hscodes", e.target.value);
                                }}
                                type="number"
                                min={0}
                                className="border-2 border-primary text-center text-xl"
                              />
                              <span className="w-2 aspect-square rounded-full bg-primary"></span>
                            </div>
                          </FormControl>
                        </FormItem>
                      )}
                    />

                    <FormItem>
                      <FormControl>
                        <div className="flex items-center gap-3">
                          <Input
                            value={"0"}
                            disabled
                            type="number"
                            min={0}
                            className="border-2 border-primary text-center text-xl"
                          />
                        </div>
                      </FormControl>
                    </FormItem>
                    <FormItem>
                      <FormControl>
                        <div className="flex items-center gap-3">
                          <Input
                            value={"0"}
                            disabled
                            type="number"
                            min={0}
                            className="border-2 border-primary text-center text-xl"
                          />
                        </div>
                      </FormControl>
                    </FormItem>
                    <FormItem>
                      <FormControl>
                        <div className="flex items-center gap-3">
                          <Input
                            value={"0"}
                            disabled
                            type="number"
                            min={0}
                            className="border-2 border-primary text-center text-xl"
                          />
                        </div>
                      </FormControl>
                    </FormItem>
                  </div>
                </div> */}

                  <Button type="submit" className="w-full">
                    Save Changes
                  </Button>
                </form>
              </Form>
            </DialogContent>
          </Dialog>

          <Dialog>
            <DialogTrigger className="underline text-destructive text-sm p-0">
              Delete
            </DialogTrigger>
            <DialogContent>
              <h3 className="text-primary-500 font-medium text-lg text-center">
                {"You're"} about to delete an import item. Do you want to
                proceed?
              </h3>
              <div className="flex items-center gap-4">
                <Button
                  className="w-full py-7"
                  onClick={handleDelete}
                  disabled={isActionLoading}
                >
                  Yes, proceed
                </Button>
                <Button
                  variant="outline"
                  disabled={isActionLoading}
                  className="w-full py-7 bg-transparent border-destructive text-destructive"
                >
                  No, cancel
                </Button>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      );
    },
  },
];
