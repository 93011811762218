import React from "react";
import { CompanyUpdateFormSchema, CompanyUpdateFormType } from "../_schema";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { useQuery } from "@tanstack/react-query";
import LoaderWrapper from "@/components/ui/loader-wrapper";
import axios from "axios";
import { getBackendUrl } from "@/lib/utils";
import { useStore } from "@/contexts/userStore";

export default function CompanyUpdateFormPageOne({
  handleSubmit,
  canSubmit,
}: {
  handleSubmit: (values: CompanyUpdateFormType) => void;
  canSubmit: boolean;
}) {
  const userStore = useStore((state) => state.user);
  const { data, isLoading } = useQuery({
    queryKey: ["update-information"],
    queryFn: async () => {
      try {
        const { data } = await axios.get(
          `${getBackendUrl()}/get-importer-info/${userStore.email}`
        );
        return data?.Importer_info;
      } catch (error) {}
    },
  });

  return (
    <LoaderWrapper isLoading={isLoading}>
      {data ? (
        <FormRender
          handleSubmit={handleSubmit}
          canSubmit={canSubmit}
          data={data}
        />
      ) : (
        <></>
      )}
    </LoaderWrapper>
  );
}

const FormRender = ({
  data,
  canSubmit,
  handleSubmit,
}: {
  data: any;
  canSubmit: boolean;
  handleSubmit: any;
}) => {
  const form = useForm<CompanyUpdateFormType>({
    resolver: zodResolver(CompanyUpdateFormSchema),
    defaultValues: {
      name: data?.importer_name || "",
      representative: data?.importer_representative || "",
      address: data?.street_address || "",
      tin_cac: data?.tin_nin_cac || "",
    },
  });
  return (
    <Form {...form}>
      <form
        className="flex flex-col gap-5"
        onSubmit={form.handleSubmit(canSubmit ? handleSubmit : () => {})}
      >
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem className="w-full flex flex-col gap-2">
              <FormLabel>Company Name</FormLabel>
              <FormControl>
                <Input
                  disabled={!canSubmit}
                  type="text"
                  placeholder="Enter your company name"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="representative"
          render={({ field }) => (
            <FormItem className="w-full flex flex-col gap-2">
              <FormLabel>Company Representative</FormLabel>
              <FormControl>
                <Input
                  type="text"
                  disabled={!canSubmit}
                  placeholder="Enter your company representative"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="address"
          render={({ field }) => (
            <FormItem className="w-full flex flex-col gap-2">
              <FormLabel>Company Address</FormLabel>
              <FormControl>
                <Input
                  type="text"
                  disabled={!canSubmit}
                  placeholder="Enter your company address"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="tin_cac"
          render={({ field }) => (
            <FormItem className="w-full flex flex-col gap-2">
              <FormLabel>TIN/CAC</FormLabel>
              <FormControl>
                <Input
                  type="number"
                  disabled={!canSubmit}
                  placeholder="Enter your company tin/cac"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        {canSubmit && (
          <Button
            type="submit"
            disabled={!form.formState.isDirty}
            className="w-fit"
          >
            Save Changes
          </Button>
        )}
      </form>
    </Form>
  );
};
