import { Button, buttonVariants } from "@/components/ui/button";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { cn } from "@/lib/utils";
import { TrendingUp } from "lucide-react";
import { CartesianGrid, Label, Line, LineChart, XAxis, YAxis } from "recharts";

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";

import React, { useState } from "react";
import { usePaymentAnalytics } from "@/hooks/analytics/usePaymentAnalytics";

export default function RevenueOverview() {
  const filters: ("daily" | "monthly" | "annually")[] = [
    "daily",
    "monthly",
    "annually",
  ];
  const [activeFilter, setActiveFilter] = useState(filters[0]);
  const data = usePaymentAnalytics();

  const charts = [
    {
      title: "Agent",
      dailyData: data.charts?.agent.daily || [],
      monthlyData: data.charts?.agent.monthly || [],
      yearlyData: data.charts?.agent.yearly || [],
    },
    {
      title: "Company",
      dailyData: data.charts?.company.daily || [],
      monthlyData: data.charts?.company.monthly || [],
      yearlyData: data.charts?.company.yearly || [],
    },
    {
      title: "Government",
      dailyData: data.charts?.government.daily || [],
      monthlyData: data.charts?.government.monthly || [],
      yearlyData: data.charts?.government.yearly || [],
    },
    {
      title: "Individual",
      dailyData: data.charts?.individual.daily || [],
      monthlyData: data.charts?.individual.monthly || [],
      yearlyData: data.charts?.individual.yearly || [],
    },
  ];

  return (
    <div className="w-full shadow-[0_4px_20px_0px_#00000014] rounded-xl p-4 flex flex-col gap-5">
      <div className="flex items-center justify-between w-full">
        <h1 className="text-primary-700 text-xl font-medium">
          Revenue Overview
        </h1>
        <div className="flex gap-3 items-center w-fit">
          {filters.map((filter) => (
            <Button
              key={`${filter}-filter`}
              className="capitalize px-10"
              onClick={() => setActiveFilter(filter)}
              variant={filter == activeFilter ? "default" : "outline"}
            >
              {filter}
            </Button>
          ))}
        </div>
      </div>

      <div className="w-full grid grid-cols-2 gap-10">
        {charts.map((data) => (
          <React.Fragment key={data.title}>
            {activeFilter == "daily" && (
              <TrendsChart
                activeFilter={activeFilter}
                chartData={data.dailyData}
                title={data.title}
              />
            )}
            {activeFilter == "monthly" && (
              <TrendsChart
                activeFilter={activeFilter}
                chartData={data.monthlyData}
                title={data.title}
              />
            )}
            {activeFilter == "annually" && (
              <TrendsChart
                activeFilter={activeFilter}
                chartData={data.yearlyData}
                title={data.title}
              />
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}

function TrendsChart({
  chartData,
  activeFilter,
  title,
}: {
  chartData: { tag: string; trend: number }[];
  activeFilter: "daily" | "monthly" | "annually";
  title: string;
}) {
  const tickData = { stroke: "black", strokeWidth: 0.25, fontSize: 11 };
  const chartConfig = {
    desktop: {
      label: "trend",
      color: "rgb(var(--primary))",
    },
  } satisfies ChartConfig;
  return (
    <div className="pb-10">
      <ChartContainer config={chartConfig}>
        <LineChart
          accessibilityLayer
          data={chartData}
          margin={{
            left: 32,
            right: 12,
            bottom: 30,
          }}
        >
          <CartesianGrid strokeWidth={0.25} />
          <XAxis
            dataKey="tag"
            // tickLine={false}
            tick={tickData}
            axisLine={false}
            // tickMargin={8}
            label={{
              value: title,
              position: "insideBottom",
              offset: -20,
              stroke: "green",
              strokeWidth: 0.5,
            }}
            tickFormatter={
              activeFilter == "monthly"
                ? (value) => value.slice(0, 3)
                : (value) => value
            }
            style={{
              fill: "black",
            }}
          ></XAxis>
          <YAxis
            tickLine={false}
            axisLine={false}
            tickMargin={0}
            tick={tickData}
            label={{
              value: "Revenue (₦)",
              angle: -90,
              position: "insideLeft",
              offset: -20,
              stroke: "green",
              strokeWidth: 0.5,
            }}
            style={{
              fill: "black",
            }}
            tickFormatter={(value) =>
              `₦${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
            }
          />

          <ChartTooltip cursor={false} content={<ChartTooltipContent />} />
          <Line
            dataKey="trend"
            type="linear"
            stroke="rgb(var(--secondary))"
            strokeWidth={2}
            dot={false}
          />
        </LineChart>
      </ChartContainer>
    </div>
  );
}
