import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { cn, getBackendUrl, getMinMax } from "@/lib/utils";
import { Button, buttonVariants } from "@/components/ui/button";
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
} from "@/components/ui/form";
import { Combobox } from "@/components/ui/combo-box";
import { HSCodeItem, HSCodes } from "@/lib/dummy-data/hsCodes";
import { Input } from "@/components/ui/input";
import slugify from "slugify";
import { toast } from "sonner";
import { unitIds } from "@/lib/site-data";
import { Badge } from "@/components/ui/badge";
import { ScrollArea } from "@/components/ui/scroll-area";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Trash } from "lucide-react";

export default function AddItemPricingPage() {
  const queryClient = useQueryClient();
  const formSchema = z.object({
    categoryId: z.string().min(1),
    categoryLabel: z.string().min(1),
    categoryCode: z.string().min(1),
    categoryCost: z.record(z.enum(unitIds), z.string().optional()).optional(),

    subCategoryId: z.string().min(1),
    subCategoryLabel: z.string().min(1),
    subCategoryCode: z.string().min(1),

    subCategoryCost: z
      .record(z.enum(unitIds), z.string().optional())
      .optional(),
    itemCategoryId: z.string().optional(),
    itemCategoryLabel: z.string().optional(),
    itemCategoryCode: z.string().optional(),
    itemCategoryCost: z
      .record(z.enum(unitIds), z.string().optional())
      .optional(),

    itemId: z.string().optional(),
    itemLabel: z.string().optional(),
    itemCode: z.string().optional(),
    itemCost: z.record(z.enum(unitIds), z.string().optional()).optional(),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      categoryId: "",
      categoryLabel: "",
      categoryCode: "",
      subCategoryId: "",
      subCategoryLabel: "",
      subCategoryCode: "",
      itemCategoryId: "",
      itemCategoryLabel: "",
      itemCategoryCode: "",
      itemId: "",
      itemLabel: "",
      itemCode: "",
    },
  });

  const [data, setData] = useState<HSCodeItem[]>(HSCodes);

  const [isActionLoading, setIsActionLoading] = useState(false);

  const [newCategory, setNewCategory] = useState("");
  const [newCategoryCode, setNewCategoryCode] = useState("");

  const [newSubCategory, setNewSubCategory] = useState("");
  const [newSubCategoryCode, setNewSubCategoryCode] = useState("");

  const [newItemCategory, setNewItemCategory] = useState("");
  const [newItemCategoryCode, setNewItemCategoryCode] = useState("");

  const {
    categoryId,
    categoryCode,
    categoryLabel,
    categoryCost,
    subCategoryId,
    subCategoryCost,
    subCategoryCode,
    subCategoryLabel,
    itemCategoryId,
    itemCategoryCode,
    itemCategoryLabel,
    itemLabel,
    itemCategoryCost,
    itemCode,
    itemCost,
  } = form.watch();

  const { data: categoriesData, isLoading: isCategoriesDataLoading } = useQuery(
    {
      queryKey: ["get-categories"],
      queryFn: async () => {
        const {
          data,
        }: {
          data: {
            category?: {
              id: number;
              categoryId: string;
              categoryLabel: string;
              categoryCode: string;
              categoryCost: string;
              created_at: string;
              updated_at: string;
              status: null;
            }[];
          };
        } = await axios.get(`${getBackendUrl()}/get-categories`);

        return data?.category || [];
      },
    }
  );
  const addCategoryMutation = useMutation({
    mutationFn: async ({
      categoryCode,
      categoryId,
      categoryCost,
      categoryLabel,
    }: {
      categoryId: string;
      categoryLabel: string;
      categoryCode: string;
      categoryCost: string;
    }) => {
      setIsActionLoading(true);
      try {
        await axios.post(`${getBackendUrl()}/store-categories`, {
          categoryId,
          categoryLabel,
          categoryCode,
          categoryCost,
        });
        toast.success("Category updated successfully");
        setIsActionLoading(false);
      } catch (error) {
        setIsActionLoading(false);
        toast.error("Failed to update category");
      }
    },
    onMutate: async ({
      categoryCode,
      categoryCost,
      categoryId,
      categoryLabel,
    }) => {
      await queryClient.cancelQueries({
        queryKey: ["get-categories"],
      });

      const previousCategories = queryClient.getQueryData(["get-categories"]);

      queryClient.setQueryData(["get-categories"], (old: any) => [
        ...(old || []),
        {
          id: 0,
          categoryId,
          categoryLabel,
          categoryCode,
          categoryCost,
          created_at: "",
          updated_at: "",
          status: null,
        }, // Add a fake ID for the optimistic update
      ]);

      return { previousCategories };
    },

    onError: (_error, _variables, context: any) => {
      // Rollback on error
      queryClient.setQueryData(["get-categories"], context.previousCategories);
    },
    onSettled: () => {
      // Refetch the data
      queryClient.invalidateQueries({
        queryKey: ["get-categories"],
      });
    },
  });

  const addNewCategory = async () => {
    addCategoryMutation.mutate({
      categoryId: slugify(newCategory.toLowerCase()),
      categoryLabel: newCategory,
      categoryCode: newCategoryCode,
      categoryCost: "{}",
    });
    setNewCategory("");
    setNewCategoryCode("");
  };

  const [categoryData, setCategoryData] = useState(
    categoriesData?.find((d) => d.categoryId === categoryId)
  );

  const { data: subCategoriesData, isLoading: issubCategoriesDataLoading } =
    useQuery({
      queryKey: ["get-sub-categories"],
      queryFn: async () => {
        const {
          data,
        }: {
          data: {
            id: number;
            categoryId: string;
            subCategoryId: string;
            subCategoryLabel: string;
            subCategoryCode: string;
            subCategoryCost: string;
            created_at: string;
            updated_at: string;
            status: null;
          }[];
        } = await axios.get(
          `${getBackendUrl()}/subcategories/${categoryData?.categoryId}`
        );
        return data || [];
      },
    });

  const addSubCategoryMutation = useMutation({
    mutationFn: async ({
      categoryId,
      subCategoryCode,
      subCategoryCost,
      subCategoryId,
      subCategoryLabel,
    }: {
      categoryId: string;
      subCategoryId: string;
      subCategoryLabel: string;
      subCategoryCode: string;
      subCategoryCost: string;
    }) => {
      setIsActionLoading(true);
      try {
        await axios.post(`${getBackendUrl()}/store-subcategories`, {
          subCategoryId,
          subCategoryLabel,
          subCategoryCode,
          subCategoryCost: "{}",
          categoryId,
        });
        toast.success("Sub Category updated successfully");
        setIsActionLoading(false);
      } catch (error) {
        setIsActionLoading(false);
        toast.error("Failed to update sub category");
      }
    },
    onMutate: async ({
      categoryId,
      subCategoryId,
      subCategoryCode,
      subCategoryCost,
      subCategoryLabel,
    }) => {
      await queryClient.cancelQueries({
        queryKey: ["get-sub-categories"],
      });

      const previousSubCategories = queryClient.getQueryData([
        "get-sub-categories",
      ]);

      queryClient.setQueryData(["get-sub-categories"], (old: any) => [
        ...(old || []),
        {
          id: 0,
          categoryId,
          subCategoryLabel,
          subCategoryCode,
          subCategoryCost,
          subCategoryId,
          created_at: "",
          updated_at: "",
          status: null,
        }, // Add a fake ID for the optimistic update
      ]);

      return { previousSubCategories };
    },

    onError: (_error, _variables, context: any) => {
      // Rollback on error
      queryClient.setQueryData(
        ["get-sub-categories"],
        context.previousSubCategories
      );
    },
    onSettled: () => {
      // Refetch the data
      queryClient.invalidateQueries({
        queryKey: ["get-sub-categories"],
      });
    },
  });

  const addNewSubCategory = async () => {
    if (categoryData?.categoryId) {
      addSubCategoryMutation.mutate({
        categoryId: categoryData?.categoryId,
        subCategoryId: slugify(newSubCategory.toLowerCase()),
        subCategoryLabel: newSubCategory,
        subCategoryCode: newSubCategoryCode,
        subCategoryCost: "{}",
      });
      setNewCategory("");
      setNewCategoryCode("");
      return;
    }
    toast.error("Please select a category first");
  };

  const [subCategoryData, setSubCategoryData] = useState(
    subCategoriesData?.find((d) => d.subCategoryId === subCategoryId)
  );

  const { data: itemCategoriesData, isLoading: isItemsCategoriesDataLoading } =
    useQuery({
      queryKey: ["get-item-categories"],
      queryFn: async () => {
        const {
          data,
        }: {
          data: {
            id: number;
            subCategoryId: string;
            itemCategoryId: string;
            itemCategoryLabel: string;
            itemCategoryCode: string;
            itemCategoryCost: string;
            created_at: string;
            updated_at: string;
            status: null;
          }[];
        } = await axios.get(
          `${getBackendUrl()}/itemcategories/${subCategoryData?.subCategoryId}`
        );
        return data || [];
      },
    });

  const addItemsCategoryMutation = useMutation({
    mutationFn: async ({
      subCategoryId,
      itemCategoryCode,
      itemCategoryCost,
      itemCategoryId,
      itemCategoryLabel,
    }: {
      subCategoryId: string;
      itemCategoryId: string;
      itemCategoryLabel: string;
      itemCategoryCode: string;
      itemCategoryCost: string;
    }) => {
      setIsActionLoading(true);
      try {
        await axios.post(`${getBackendUrl()}/store-itemcategories`, {
          itemCategoryId,
          itemCategoryLabel,
          itemCategoryCode,
          itemCategoryCost: "{}",
          subCategoryId,
        });
        toast.success("Item Category updated successfully");
        setIsActionLoading(false);
      } catch (error) {
        setIsActionLoading(false);
        toast.error("Failed to update item category");
      }
    },
    onMutate: async ({
      subCategoryId,
      itemCategoryId,
      itemCategoryCode,
      itemCategoryCost,
      itemCategoryLabel,
    }) => {
      await queryClient.cancelQueries({
        queryKey: ["get-item-categories"],
      });

      const previousItemCategories = queryClient.getQueryData([
        "get-item-categories",
      ]);

      queryClient.setQueryData(["get-item-categories"], (old: any) => [
        ...(old || []),
        {
          id: 0,
          subCategoryId,
          itemCategoryLabel,
          itemCategoryCode,
          itemCategoryCost,
          itemCategoryId,
          created_at: "",
          updated_at: "",
          status: null,
        }, // Add a fake ID for the optimistic update
      ]);

      return { previousItemCategories };
    },

    onError: (_error, _variables, context: any) => {
      // Rollback on error
      queryClient.setQueryData(
        ["get-item-categories"],
        context.previousItemCategories
      );
    },
    onSettled: () => {
      // Refetch the data
      queryClient.invalidateQueries({
        queryKey: ["get-item-categories"],
      });
    },
  });

  const addNewItemCategory = async () => {
    if (subCategoryData?.subCategoryId) {
      addItemsCategoryMutation.mutate({
        subCategoryId: subCategoryData?.subCategoryId,
        itemCategoryId: slugify(newItemCategory.toLowerCase()),
        itemCategoryLabel: newItemCategory,
        itemCategoryCode: newItemCategoryCode,
        itemCategoryCost: "{}",
      });
      setNewCategory("");
      setNewCategoryCode("");
      return;
    }
    toast.error("Please select a category first");
  };

  const [itemCategoryData, setItemCategoryData] = useState(
    itemCategoriesData?.find((d) => d.itemCategoryId === itemCategoryId)
  );
  // const [itemData, setItemData] = useState(
  //   itemCategoriesData?.find((d) => d.itemCategoryId === itemCategoryId)
  // );

  // const subCategoryData = categoryData?.find(
  //   (d) => d.categoryId === subCategoryId
  // );

  // const itemCategoryData = subCategoryData?.items.find(
  //   (d) => d.categoryId === itemCategoryId
  // );

  // const { max: maxSubCategoryCode } = getMinMax(
  //   categoryData?.items.map((item) => Number(item.code)) || []
  // );
  // const { max: maxItemCategoryCode } = getMinMax(
  //   subCategoryData?.items.map((item) => Number(item.code)) || []
  // );
  // const { max: maxItemCode } = getMinMax(
  //   itemCategoryData?.items.map((item) => Number(item.code)) || []
  // );

  // useEffect(() => {
  //   console.log(categoryCost)
  // })

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: ["get-sub-categories"],
    });
  }, [categoryData]);

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: ["get-item-categories"],
    });
  }, [subCategoryData]);

  useEffect(() => {
    console.log(form.formState.errors, form.formState.isValid);
  });
  return (
    <div className="">
      <>
        <h1 className="text-xl font-semibold text-center">
          Add New Import Item
        </h1>
        <Form {...form}>
          <form
            className="flex flex-col gap-10"
            onSubmit={form.handleSubmit(
              async (values) => {
                setIsActionLoading(true);
                try {
                  toast.success("Item added successfully");
                  const itemHSCode = `${values.categoryCode || "0"}.${
                    values.subCategoryCode || "0"
                  }.${values.itemCategoryCode || "0"}.${
                    values.itemCode || "0"
                  }`;
                  const storedData = {
                    itemId: values.itemId
                      ? slugify(values.itemId.toLowerCase())
                      : "",
                    itemLabel: values.itemLabel || "",
                    itemCode: itemHSCode,
                    itemCost: JSON.stringify(values.itemCost) || "{}",
                    itemCategoryId: values.itemCategoryId || "",

                    categoryLabel: categoryData?.categoryLabel || "",
                    subCategoryLabel: subCategoryData?.subCategoryLabel || "",
                    itemCategoryLabel:
                      itemCategoryData?.itemCategoryLabel || "",
                  };
                  // Store Item
                  await axios.post(
                    `${getBackendUrl()}/store-items`,
                    storedData
                  );
                  toast.success("Item added successfully");
                  form.reset();
                  setIsActionLoading(false);
                } catch (error) {
                  setIsActionLoading(false);
                  toast.error("Failed to add item");
                }
              }
            )}
          >
            <FormField
              control={form.control}
              name="categoryId"
              render={({ field }) => (
                <FormItem>
                  <div className="flex items-center gap-3">
                    <FormLabel>Item Category</FormLabel>
                    {/* <Button
                      disabled={!categoryId}
                      className="p-1 text-xs h-fit"
                      type="button"
                      variant={categoryCost ? "destructive" : "outline"}
                      onClick={
                        categoryCost
                          ? () => {
                              form.setValue("categoryCost", undefined);
                            }
                          : () => {
                              form.setValue("categoryCost", {});

                              form.setValue("subCategoryId", "");
                              form.setValue("subCategoryLabel", "");
                              form.setValue("subCategoryCode", "");
                              form.setValue("subCategoryCost", undefined);

                              form.setValue("itemCategoryId", "");
                              form.setValue("itemCategoryLabel", "");
                              form.setValue("itemCategoryCode", "");
                              form.setValue("itemCategoryCost", undefined);

                              form.setValue("itemId", "");
                              form.setValue("itemLabel", "");
                              form.setValue("itemCode", "");
                              form.setValue("itemCost", undefined);
                            }
                      }
                    >
                      {subCategoryCost ? " x Clear Cost" : "+ Assign Cost"}
                    </Button> */}
                  </div>
                  {/* {categoryCost && (
                    <span className="text-xs font-light text-destructive italic">
                      Note: You are creating this category as an item.
                    </span>
                  )} */}
                  <FormControl>
                    <div className="flex flex-col gap-3">
                      <div className="w-full">
                        <Combobox
                          value={categoryId}
                          values={
                            categoriesData?.map((item) => ({
                              label: item.categoryLabel,
                              value: item.categoryId,
                            })) || []
                          }
                          onChange={(value) => {
                            const categoryData = categoriesData?.find(
                              (d) => d.categoryId === value
                            );
                            setCategoryData(categoryData);

                            form.setValue("categoryId", value);
                            form.setValue(
                              "categoryCode",
                              categoryData?.categoryCode || "0"
                            );
                            form.setValue(
                              "categoryLabel",
                              categoryData?.categoryLabel || ""
                            );
                            form.setValue(
                              "categoryCost",
                              categoryData?.categoryCost
                                ? JSON.parse(categoryData?.categoryCost)
                                : undefined
                            );

                            form.setValue("subCategoryId", "");
                            form.setValue("subCategoryLabel", "");
                            form.setValue("subCategoryCode", "0");
                            form.setValue("subCategoryCost", undefined);

                            form.setValue("itemCategoryId", "");
                            form.setValue("itemCategoryLabel", "");
                            form.setValue("itemCategoryCode", "0");
                            form.setValue("itemCategoryCost", undefined);

                            form.setValue("itemId", "");
                            form.setValue("itemLabel", "");
                            form.setValue("itemCode", "0");
                            form.setValue("itemCost", undefined);

                            field.onBlur();
                          }}
                        >
                          <div className="w-full border rounded-lg p-2 flex items-center bg-white gap-2">
                            <div className="w-full flex gap-3">
                              <Input
                                // className="p-0"
                                placeholder="Enter Item Category Name"
                                onChange={(e) => setNewCategory(e.target.value)}
                                value={newCategory}
                              />
                              <Input
                                // className="p-0"
                                type="number"
                                min={0}
                                placeholder="Enter Item Category Code"
                                onChange={(e) =>
                                  setNewCategoryCode(e.target.value)
                                }
                                value={newCategoryCode}
                              />
                            </div>
                            <Button
                              disabled={
                                !newCategory ||
                                isActionLoading ||
                                !newCategoryCode
                              }
                              onClick={addNewCategory}
                              type="button"
                            >
                              Add Item Category
                            </Button>
                          </div>
                        </Combobox>
                      </div>
                      {categoryCost && (
                        <Accordion className="w-full" type="single" collapsible>
                          <AccordionItem
                            value="item-1"
                            className="w-full border-none"
                          >
                            <AccordionTrigger className="w-full p-0 text-sm underline">
                              <div className="flex gap-2">
                                Edit Category Costing{" "}
                                <em>(Click To View Details)</em>
                              </div>
                            </AccordionTrigger>
                            <AccordionContent className="py-4">
                              <div className="flex flex-col gap-4">
                                <h1 className="">
                                  Select from the available metric options
                                  below:
                                </h1>
                                <div className="flex flex-wrap gap-3">
                                  {unitIds.map((unit) => {
                                    const categoryCostKeys =
                                      Object.keys(categoryCost);
                                    const isAdded =
                                      categoryCostKeys.includes(unit);
                                    return (
                                      <Badge
                                        key={`${unit} sub-category`}
                                        variant={
                                          isAdded ? "default" : "outline"
                                        }
                                        className="capitalize cursor-pointer hover:bg-primary-500 hover:text-primary-foreground"
                                        onClick={() => {
                                          if (isAdded) {
                                            const { [unit]: _, ...rest } =
                                              categoryCost;
                                            form.setValue("categoryCost", rest);
                                          } else {
                                            form.setValue("categoryCost", {
                                              ...categoryCost,
                                              [unit]: "0",
                                            });
                                          }
                                        }}
                                      >
                                        {unit.split("-").join(" ")}
                                      </Badge>
                                    );
                                  })}
                                </div>

                                <div className="grid gap-3 w-full max-w-xl">
                                  {Object.keys(categoryCost).map(
                                    (unit: any) => {
                                      const unitValue: (typeof unitIds)[number] =
                                        unit;
                                      return (
                                        <div
                                          key={`${unitValue} category input`}
                                          className="flex items-center gap-3"
                                        >
                                          <h1 className="capitalize flex-[0.4]">
                                            {unit.split("-").join(" ")}
                                          </h1>
                                          <Input
                                            key={unit}
                                            className="flex-1"
                                            type="number"
                                            min={0}
                                            value={
                                              categoryCost[unitValue] || ""
                                            }
                                            onChange={(e) => {
                                              form.setValue(
                                                "categoryCost",
                                                Object.fromEntries(
                                                  Object.entries(
                                                    categoryCost
                                                  ).map(([k, v]) => [
                                                    k,
                                                    k === unit
                                                      ? e.target.value
                                                      : v,
                                                  ])
                                                )
                                              );
                                            }}
                                            placeholder={`Enter Category Cost Per (${unit})`}
                                          />
                                          <Trash
                                            className="cursor-pointer hover:text-destructive duration-300"
                                            size={15}
                                            onClick={() => {
                                              const {
                                                [unitValue]: _,
                                                ...rest
                                              } = categoryCost;
                                              form.setValue(
                                                "categoryCost",
                                                rest
                                              );
                                            }}
                                          />
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                                <Button
                                  className="w-fit"
                                  disabled={
                                    categoryData?.categoryCost ==
                                      JSON.stringify(categoryCost) ||
                                    isActionLoading
                                  }
                                  onClick={() => {
                                    const updatedCategory = {
                                      categoryId,
                                      categoryLabel,
                                      categoryCode,
                                      categoryCost:
                                        JSON.stringify(categoryCost),
                                    };
                                    addCategoryMutation.mutate(updatedCategory);
                                  }}
                                >
                                  Update
                                </Button>
                              </div>
                            </AccordionContent>
                          </AccordionItem>
                        </Accordion>
                      )}
                    </div>
                  </FormControl>
                </FormItem>
              )}
            />

            {!!categoryId && (
              <FormField
                control={form.control}
                name="subCategoryId"
                render={({ field }) => (
                  <FormItem>
                    <div className="flex items-center gap-2">
                      <FormLabel>Sub Category A</FormLabel>
                      {/* <Button
                        disabled={!categoryId || !subCategoryId}
                        className="p-1 text-xs h-fit"
                        type="button"
                        variant={subCategoryCost ? "destructive" : "outline"}
                        onClick={
                          subCategoryCost
                            ? () => {
                                form.setValue("subCategoryCost", undefined);
                              }
                            : () => {
                                form.setValue("subCategoryCost", {});

                                form.setValue("itemCategoryId", "");
                                form.setValue("itemCategoryLabel", "");
                                form.setValue("itemCategoryCode", "");
                                form.setValue("itemCategoryCost", undefined);

                                form.setValue("itemId", "");
                                form.setValue("itemLabel", "");
                                form.setValue("itemCode", "");
                                form.setValue("itemCost", undefined);
                              }
                        }
                      >
                        {subCategoryCost ? " x Clear Cost" : "+ Assign Cost"}
                      </Button> */}
                    </div>
                    {/* {subCategoryCost && (
                      <span className="text-xs font-light text-destructive italic">
                        Note: You are creating this sub-category as an item.
                      </span>
                    )} */}
                    <FormControl>
                      <div className="flex flex-col items-center gap-2">
                        <div className="w-full">
                          <Combobox
                            disabled={!categoryId}
                            value={subCategoryId}
                            // values={categoryData?.items.map((item) => ({
                            //   label: item.label,
                            //   value: item.categoryId,
                            // }))}
                            values={
                              subCategoriesData?.map((item) => ({
                                label: item.subCategoryLabel,
                                value: item.subCategoryId,
                              })) || []
                            }
                            onChange={(value) => {
                              const subCategoryData_ = subCategoriesData?.find(
                                (d) => d.subCategoryId === value
                              );
                              setSubCategoryData(subCategoryData_);
                              form.setValue("subCategoryId", value);
                              form.setValue(
                                "subCategoryCode",
                                subCategoryData_?.subCategoryCode || "0"
                              );
                              form.setValue(
                                "subCategoryLabel",
                                subCategoryData_?.subCategoryLabel || ""
                              );
                              form.setValue(
                                "subCategoryCost",
                                subCategoryData_?.subCategoryCost
                                  ? JSON.parse(
                                      subCategoryData_?.subCategoryCost
                                    )
                                  : undefined
                              );

                              form.setValue("itemCategoryId", "");
                              form.setValue("itemCategoryLabel", "");
                              form.setValue("itemCategoryCode", "");
                              form.setValue("itemCategoryCost", undefined);

                              form.setValue("itemId", "");
                              form.setValue("itemLabel", "");
                              form.setValue("itemCode", "0");
                              form.setValue("itemCost", undefined);

                              field.onBlur();
                            }}
                          >
                            {/* <div className="w-full border rounded-lg p-2 flex items-center bg-white gap-2">
                            <Input
                              className="border-none h-fit p-0"
                              placeholder="Enter Item Category"
                              onChange={(e) =>
                                setNewSubCategory(e.target.value)
                              }
                              value={newSubCategory}
                            />
                            <Button
                              disabled={!newSubCategory}
                              onClick={() => {
                                setData(
                                  data.map((item) =>
                                    item.categoryId === categoryId
                                      ? {
                                          ...item,
                                          items: [
                                            ...item.items,
                                            {
                                              categoryId: slugify(
                                                newSubCategory.toLowerCase()
                                              ),
                                              label: newSubCategory,
                                              code: (maxSubCategoryCode + 1)
                                                .toString()
                                                .padStart(2, "0"),
                                              items: [],
                                            },
                                          ],
                                        }
                                      : item
                                  )
                                );
                                setNewSubCategory("");
                              }}
                              type="button"
                            >
                              Add Item Category
                            </Button>
                          </div> */}
                            <div className="w-full border rounded-lg p-2 flex items-center bg-white gap-2">
                              <div className="w-full flex gap-3">
                                <Input
                                  // className="p-0"
                                  placeholder="Enter Sub Category Name"
                                  onChange={(e) =>
                                    setNewSubCategory(e.target.value)
                                  }
                                  value={newSubCategory}
                                />
                                <Input
                                  // className="p-0"
                                  type="number"
                                  min={0}
                                  placeholder="Enter Sub Category Code"
                                  onChange={(e) =>
                                    setNewSubCategoryCode(e.target.value)
                                  }
                                  value={newSubCategoryCode}
                                />
                              </div>
                              <Button
                                disabled={
                                  !newSubCategory ||
                                  isActionLoading ||
                                  !newSubCategoryCode
                                }
                                onClick={addNewSubCategory}
                                type="button"
                              >
                                Add Sub Category
                              </Button>
                            </div>
                          </Combobox>
                        </div>

                        {/* {subCategoryCost && (
                        <Accordion className="w-full" type="single" collapsible>
                          <AccordionItem value="item-1" className="w-full">
                            <AccordionTrigger className="w-full p-0 text-xs">
                              <div className="flex gap-2">
                                Edit Sub Category A Costing{" "}
                                <em>(Click To View Details)</em>
                              </div>
                            </AccordionTrigger>
                            <AccordionContent className="py-2">
                              <div className="flex flex-col">
                                <div className="flex flex-wrap gap-3">
                                  {unitIds.map((unit) => {
                                    const subCategoryCostKeys =
                                      Object.keys(subCategoryCost);
                                    const isAdded =
                                      subCategoryCostKeys.includes(unit);
                                    return (
                                      <Badge
                                        key={`${unit} sub-category`}
                                        variant={
                                          isAdded ? "default" : "outline"
                                        }
                                        className="capitalize cursor-pointer"
                                        onClick={() => {
                                          if (isAdded) {
                                            const { [unit]: _, ...rest } =
                                              subCategoryCost;
                                            form.setValue(
                                              "subCategoryCost",
                                              rest
                                            );
                                          } else {
                                            form.setValue("subCategoryCost", {
                                              ...subCategoryCost,
                                              [unit]: "0",
                                            });
                                          }
                                        }}
                                      >
                                        {unit.split("-").join(" ")}
                                      </Badge>
                                    );
                                  })}
                                </div>
                                <div className="">
                                  {Object.keys(subCategoryCost).map(
                                    (unit: any) => {
                                      const unitValue: (typeof unitIds)[number] =
                                        unit;
                                      return (
                                        <div
                                          key={`${unitValue} sub-category input`}
                                          className="flex items-center gap-3"
                                        >
                                          <h1 className="capitalize flex-[0.5]">
                                            {unit.split("-").join(" ")}
                                          </h1>
                                          <Input
                                            key={unit}
                                            className="flex-1"
                                            type="number"
                                            min={0}
                                            value={
                                              subCategoryCost[unitValue] || ""
                                            }
                                            onChange={(e) => {
                                              form.setValue(
                                                "subCategoryCost",
                                                Object.fromEntries(
                                                  Object.entries(
                                                    subCategoryCost
                                                  ).map(([k, v]) => [
                                                    k,
                                                    k === unit
                                                      ? e.target.value
                                                      : v,
                                                  ])
                                                )
                                              );
                                            }}
                                            placeholder={`Enter Sub Category Cost Per (${unit})`}
                                          />
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                            </AccordionContent>
                          </AccordionItem>
                        </Accordion>
                      )} */}

                        {subCategoryCost && (
                          <Accordion
                            className="w-full"
                            type="single"
                            collapsible
                          >
                            <AccordionItem
                              value="item-1"
                              className="w-full border-none"
                            >
                              <AccordionTrigger className="w-full p-0 text-sm underline">
                                <div className="flex gap-2">
                                  Edit Sub Category Costing{" "}
                                  <em>(Click To View Details)</em>
                                </div>
                              </AccordionTrigger>
                              <AccordionContent className="py-4">
                                <div className="flex flex-col gap-4">
                                  <h1 className="">
                                    Select from the available metric options
                                    below:
                                  </h1>
                                  <div className="flex flex-wrap gap-3">
                                    {unitIds.map((unit) => {
                                      const subCategoryCostKeys =
                                        Object.keys(subCategoryCost);
                                      const isAdded =
                                        subCategoryCostKeys.includes(unit);
                                      return (
                                        <Badge
                                          key={`${unit} sub-category`}
                                          variant={
                                            isAdded ? "default" : "outline"
                                          }
                                          className="capitalize cursor-pointer hover:bg-primary-500 hover:text-primary-foreground"
                                          onClick={() => {
                                            if (isAdded) {
                                              const { [unit]: _, ...rest } =
                                                subCategoryCost;
                                              form.setValue(
                                                "subCategoryCost",
                                                rest
                                              );
                                            } else {
                                              form.setValue("subCategoryCost", {
                                                ...subCategoryCost,
                                                [unit]: "0",
                                              });
                                            }
                                          }}
                                        >
                                          {unit.split("-").join(" ")}
                                        </Badge>
                                      );
                                    })}
                                  </div>

                                  <div className="grid gap-3 w-full max-w-xl">
                                    {Object.keys(subCategoryCost).map(
                                      (unit: any) => {
                                        const unitValue: (typeof unitIds)[number] =
                                          unit;
                                        return (
                                          <div
                                            key={`${unitValue} sub-category input`}
                                            className="flex items-center gap-3"
                                          >
                                            <h1 className="capitalize flex-[0.4]">
                                              {unit.split("-").join(" ")}
                                            </h1>
                                            <Input
                                              key={unit}
                                              className="flex-1"
                                              type="number"
                                              min={0}
                                              value={
                                                subCategoryCost[unitValue] || ""
                                              }
                                              onChange={(e) => {
                                                form.setValue(
                                                  "subCategoryCost",
                                                  Object.fromEntries(
                                                    Object.entries(
                                                      subCategoryCost
                                                    ).map(([k, v]) => [
                                                      k,
                                                      k === unit
                                                        ? e.target.value
                                                        : v,
                                                    ])
                                                  )
                                                );
                                              }}
                                              placeholder={`Enter Sub Category Cost Per (${unit})`}
                                            />
                                            <Trash
                                              className="cursor-pointer hover:text-destructive duration-300"
                                              size={15}
                                              onClick={() => {
                                                const {
                                                  [unitValue]: _,
                                                  ...rest
                                                } = subCategoryCost;
                                                form.setValue(
                                                  "subCategoryCost",
                                                  rest
                                                );
                                              }}
                                            />
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                  <Button
                                    className="w-fit"
                                    disabled={
                                      subCategoryData?.subCategoryCost ==
                                        JSON.stringify(subCategoryCost) ||
                                      isActionLoading
                                    }
                                    onClick={() => {
                                      const updatedSubCategory = {
                                        categoryId,
                                        subCategoryId,
                                        subCategoryLabel,
                                        subCategoryCode,
                                        subCategoryCost:
                                          JSON.stringify(categoryCost),
                                      };
                                      addSubCategoryMutation.mutate(
                                        updatedSubCategory
                                      );
                                    }}
                                  >
                                    Update
                                  </Button>
                                </div>
                              </AccordionContent>
                            </AccordionItem>
                          </Accordion>
                        )}
                      </div>
                    </FormControl>
                  </FormItem>
                )}
              />
            )}

            {!!subCategoryId.length && (
              <FormField
                control={form.control}
                name="itemCategoryId"
                render={({ field }) => (
                  <FormItem>
                    <div className="flex items-center gap-2">
                      <FormLabel>Sub Category B</FormLabel>
                      {/* <Button
                        disabled={
                          !categoryId || !subCategoryId || !itemCategoryId
                        }
                        className="p-1 text-xs h-fit"
                        type="button"
                        variant={itemCategoryCost ? "destructive" : "outline"}
                        onClick={
                          itemCategoryCost
                            ? () => {
                                form.setValue("itemCategoryCost", undefined);
                              }
                            : () => {
                                form.setValue("itemCategoryCost", {});

                                form.setValue("itemId", "");
                                form.setValue("itemLabel", "");
                                form.setValue("itemCode", "");
                                form.setValue("itemCost", undefined);
                              }
                        }
                      >
                        {itemCategoryCost ? " x Clear Cost" : "+ Assign Cost"}
                      </Button> */}
                    </div>
                    {/* {itemCategoryCost && (
                      <span className="text-xs font-light text-destructive italic">
                        Note: You are creating this sub-category as an item.
                      </span>
                    )} */}
                    <FormControl>
                      <div className="flex flex-col items-center gap-2">
                        <div className="w-full">
                          <Combobox
                            disabled={!subCategoryId}
                            value={itemCategoryId || ""}
                            // values={categoryData?.items.map((item) => ({
                            //   label: item.label,
                            //   value: item.categoryId,
                            // }))}
                            values={
                              itemCategoriesData?.map((item) => ({
                                label: item.itemCategoryLabel,
                                value: item.itemCategoryId,
                              })) || []
                            }
                            onChange={(value) => {
                              const itemCategoryData_ =
                                itemCategoriesData?.find(
                                  (d) => d.itemCategoryId === value
                                );
                              setItemCategoryData(itemCategoryData_);
                              form.setValue("itemCategoryId", value);
                              form.setValue(
                                "itemCategoryCode",
                                itemCategoryData_?.itemCategoryCode || "0"
                              );
                              form.setValue(
                                "itemCategoryLabel",
                                itemCategoryData_?.itemCategoryLabel || ""
                              );
                              form.setValue(
                                "itemCategoryCost",
                                itemCategoryData_?.itemCategoryCost
                                  ? JSON.parse(
                                      itemCategoryData_?.itemCategoryCost
                                    )
                                  : undefined
                              );

                              form.setValue("itemId", "");
                              form.setValue("itemLabel", "");
                              form.setValue("itemCode", "0");
                              form.setValue("itemCost", undefined);

                              field.onBlur();
                            }}
                          >
                            <div className="w-full border rounded-lg p-2 flex items-center bg-white gap-2">
                              <div className="w-full flex gap-3">
                                <Input
                                  // className="p-0"
                                  placeholder="Enter Item Category Name"
                                  onChange={(e) =>
                                    setNewItemCategory(e.target.value)
                                  }
                                  value={newItemCategory}
                                />
                                <Input
                                  // className="p-0"
                                  type="number"
                                  min={0}
                                  placeholder="Enter Item Category Code"
                                  onChange={(e) =>
                                    setNewItemCategoryCode(e.target.value)
                                  }
                                  value={newItemCategoryCode}
                                />
                              </div>
                              <Button
                                disabled={
                                  !newItemCategory ||
                                  isActionLoading ||
                                  !newItemCategoryCode
                                }
                                onClick={addNewItemCategory}
                                type="button"
                              >
                                Add Item Category
                              </Button>
                            </div>
                          </Combobox>
                        </div>

                        {itemCategoryCost && (
                          <Accordion
                            className="w-full"
                            type="single"
                            collapsible
                          >
                            <AccordionItem
                              value="item-1"
                              className="w-full border-none"
                            >
                              <AccordionTrigger className="w-full p-0 text-sm underline">
                                <div className="flex gap-2">
                                  Edit Item Category Costing{" "}
                                  <em>(Click To View Details)</em>
                                </div>
                              </AccordionTrigger>
                              <AccordionContent className="py-4">
                                <div className="flex flex-col gap-4">
                                  <h1 className="">
                                    Select from the available metric options
                                    below:
                                  </h1>
                                  <div className="flex flex-wrap gap-3">
                                    {unitIds.map((unit) => {
                                      const itemCategoryCostKeys =
                                        Object.keys(itemCategoryCost);
                                      const isAdded =
                                        itemCategoryCostKeys.includes(unit);
                                      return (
                                        <Badge
                                          key={`${unit} sub-category`}
                                          variant={
                                            isAdded ? "default" : "outline"
                                          }
                                          className="capitalize cursor-pointer hover:bg-primary-500 hover:text-primary-foreground"
                                          onClick={() => {
                                            if (isAdded) {
                                              const { [unit]: _, ...rest } =
                                                itemCategoryCost;
                                              form.setValue(
                                                "itemCategoryCost",
                                                rest
                                              );
                                            } else {
                                              form.setValue(
                                                "itemCategoryCost",
                                                {
                                                  ...itemCategoryCost,
                                                  [unit]: "0",
                                                }
                                              );
                                            }
                                          }}
                                        >
                                          {unit.split("-").join(" ")}
                                        </Badge>
                                      );
                                    })}
                                  </div>

                                  <div className="grid gap-3 w-full max-w-xl">
                                    {Object.keys(itemCategoryCost).map(
                                      (unit: any) => {
                                        const unitValue: (typeof unitIds)[number] =
                                          unit;
                                        return (
                                          <div
                                            key={`${unitValue} item-category input`}
                                            className="flex items-center gap-3"
                                          >
                                            <h1 className="capitalize flex-[0.4]">
                                              {unit.split("-").join(" ")}
                                            </h1>
                                            <Input
                                              key={unit}
                                              className="flex-1"
                                              type="number"
                                              min={0}
                                              value={
                                                itemCategoryCost[unitValue] ||
                                                ""
                                              }
                                              onChange={(e) => {
                                                form.setValue(
                                                  "itemCategoryCost",
                                                  Object.fromEntries(
                                                    Object.entries(
                                                      itemCategoryCost
                                                    ).map(([k, v]) => [
                                                      k,
                                                      k === unit
                                                        ? e.target.value
                                                        : v,
                                                    ])
                                                  )
                                                );
                                              }}
                                              placeholder={`Enter Item Category Cost Per (${unit})`}
                                            />
                                            <Trash
                                              className="cursor-pointer hover:text-destructive duration-300"
                                              size={15}
                                              onClick={() => {
                                                const {
                                                  [unitValue]: _,
                                                  ...rest
                                                } = itemCategoryCost;
                                                form.setValue(
                                                  "itemCategoryCost",
                                                  rest
                                                );
                                              }}
                                            />
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                  <Button
                                    className="w-fit"
                                    disabled={
                                      itemCategoryData?.itemCategoryCost ==
                                        JSON.stringify(itemCategoryCost) ||
                                      isActionLoading
                                    }
                                    onClick={() => {
                                      const updatedItemCategory = {
                                        subCategoryId,
                                        itemCategoryId: itemCategoryId || "",
                                        itemCategoryLabel:
                                          itemCategoryLabel || "",
                                        itemCategoryCode:
                                          itemCategoryCode || "",
                                        itemCategoryCost:
                                          JSON.stringify(categoryCost),
                                      };
                                      addItemsCategoryMutation.mutate(
                                        updatedItemCategory
                                      );
                                    }}
                                  >
                                    Update
                                  </Button>
                                </div>
                              </AccordionContent>
                            </AccordionItem>
                          </Accordion>
                        )}
                      </div>
                    </FormControl>
                  </FormItem>
                )}
              />
            )}

            {itemCategoryId?.length ? (
              <FormField
                control={form.control}
                name="itemLabel"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Item Name</FormLabel>
                    <FormControl className="flex flex-col gap-2">
                      <>
                        <Input
                          {...field}
                          onChange={(e) => {
                            if (e.target.value.length == 0) {
                              form.setValue("itemCost", undefined);
                            }

                            form.setValue(
                              "itemId",
                              slugify(e.target.value.toLowerCase())
                            );
                            form.setValue("itemLabel", e.target.value);
                            form.setValue("itemCode", e.target.value);
                            form.setValue("itemCost", {});

                            field.onBlur();
                          }}
                          placeholder="Enter item name"
                          disabled={!itemCategoryId}
                        />

                        {/* {itemCost && (
                          <Accordion
                            className="w-full"
                            type="single"
                            collapsible
                          >
                            <AccordionItem
                              value="itemCategory"
                              className="w-full"
                            >
                              <AccordionTrigger className="w-full p-0 text-xs">
                                <div className="flex gap-2">
                                  Edit Item Costing{" "}
                                  <em>(Click To View Details)</em>
                                </div>
                              </AccordionTrigger>
                              <AccordionContent className="py-2">
                                <div className="flex flex-col">
                                  <div className="flex flex-wrap gap-3">
                                    {unitIds.map((unit) => {
                                      const itemCostKeys =
                                        Object.keys(itemCost);
                                      const isAdded =
                                        itemCostKeys.includes(unit);
                                      return (
                                        <Badge
                                          variant={
                                            isAdded ? "default" : "outline"
                                          }
                                          className="capitalize cursor-pointer"
                                          onClick={() => {
                                            if (isAdded) {
                                              const { [unit]: _, ...rest } =
                                                itemCost;
                                              form.setValue("itemCost", rest);
                                            } else {
                                              form.setValue("itemCost", {
                                                ...itemCost,
                                                [unit]: "0",
                                              });
                                            }
                                          }}
                                        >
                                          {unit.split("-").join(" ")}
                                        </Badge>
                                      );
                                    })}
                                  </div>
                                  <div className="">
                                    {Object.keys(itemCost).map((unit: any) => {
                                      const unitValue: (typeof unitIds)[number] =
                                        unit;
                                      return (
                                        <div className="flex items-center gap-3">
                                          <h1 className="capitalize flex-[0.5]">
                                            {unit.split("-").join(" ")}
                                          </h1>
                                          <Input
                                            key={unit}
                                            className="flex-1"
                                            type="number"
                                            min={0}
                                            value={itemCost[unitValue] || ""}
                                            onChange={(e) => {
                                              form.setValue(
                                                "itemCost",
                                                Object.fromEntries(
                                                  Object.entries(itemCost).map(
                                                    ([k, v]) => [
                                                      k,
                                                      k === unit
                                                        ? e.target.value
                                                        : v,
                                                    ]
                                                  )
                                                )
                                              );
                                            }}
                                            placeholder={`Enter Item Cost Per (${unit})`}
                                          />
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </AccordionContent>
                            </AccordionItem>
                          </Accordion>
                        )} */}

                        {itemCost && (
                          <Accordion
                            className="w-full"
                            type="single"
                            collapsible
                          >
                            <AccordionItem
                              value="item-1"
                              className="w-full border-none"
                            >
                              <AccordionTrigger className="w-full p-0 text-sm underline">
                                <div className="flex gap-2">
                                  Edit Item Costing{" "}
                                  <em>(Click To View Details)</em>
                                </div>
                              </AccordionTrigger>
                              <AccordionContent className="py-4">
                                <div className="flex flex-col gap-4">
                                  <h1 className="">
                                    Select from the available metric options
                                    below:
                                  </h1>
                                  <div className="flex flex-wrap gap-3">
                                    {unitIds.map((unit) => {
                                      const itemCostKeys =
                                        Object.keys(itemCost);
                                      const isAdded =
                                        itemCostKeys.includes(unit);
                                      return (
                                        <Badge
                                          key={`${unit} item`}
                                          variant={
                                            isAdded ? "default" : "outline"
                                          }
                                          className="capitalize cursor-pointer hover:bg-primary-500 hover:text-primary-foreground"
                                          onClick={() => {
                                            if (isAdded) {
                                              const { [unit]: _, ...rest } =
                                                itemCost;
                                              form.setValue("itemCost", rest);
                                            } else {
                                              form.setValue("itemCost", {
                                                ...itemCost,
                                                [unit]: "0",
                                              });
                                            }
                                          }}
                                        >
                                          {unit.split("-").join(" ")}
                                        </Badge>
                                      );
                                    })}
                                  </div>

                                  <div className="grid gap-3 w-full max-w-xl">
                                    {Object.keys(itemCost).map((unit: any) => {
                                      const unitValue: (typeof unitIds)[number] =
                                        unit;
                                      return (
                                        <div
                                          key={`${unitValue} item input`}
                                          className="flex items-center gap-3"
                                        >
                                          <h1 className="capitalize flex-[0.4]">
                                            {unit.split("-").join(" ")}
                                          </h1>
                                          <Input
                                            key={unit}
                                            className="flex-1"
                                            type="number"
                                            min={0}
                                            value={itemCost[unitValue] || ""}
                                            onChange={(e) => {
                                              form.setValue(
                                                "itemCost",
                                                Object.fromEntries(
                                                  Object.entries(itemCost).map(
                                                    ([k, v]) => [
                                                      k,
                                                      k === unit
                                                        ? e.target.value
                                                        : v,
                                                    ]
                                                  )
                                                )
                                              );
                                            }}
                                            placeholder={`Enter Item Cost Per (${unit})`}
                                          />
                                          <Trash
                                            className="cursor-pointer hover:text-destructive duration-300"
                                            size={15}
                                            onClick={() => {
                                              const {
                                                [unitValue]: _,
                                                ...rest
                                              } = itemCost;
                                              form.setValue("itemCost", rest);
                                            }}
                                          />
                                        </div>
                                      );
                                    })}
                                  </div>
                                  {/* <Button
                                    className="w-fit"
                                    disabled={
                                      itemCategoryData?.itemCategoryCost ==
                                        JSON.stringify(itemCategoryCost) ||
                                      isActionLoading
                                    }
                                    onClick={() => {
                                      const updatedItemCategory = {
                                        subCategoryId,
                                        itemCategoryId: itemCategoryId || "",
                                        itemCategoryLabel:
                                          itemCategoryLabel || "",
                                        itemCategoryCode:
                                          itemCategoryCode || "",
                                        itemCategoryCost:
                                          JSON.stringify(categoryCost),
                                      };
                                      addItemsCategoryMutation.mutate(
                                        updatedItemCategory
                                      );
                                    }}
                                  >
                                    Update
                                  </Button> */}
                                </div>
                              </AccordionContent>
                            </AccordionItem>
                          </Accordion>
                        )}
                      </>
                    </FormControl>
                  </FormItem>
                )}
              />
            ) : (
              <></>
            )}

            {/* <FormField
              control={form.control}
              name="itemCost"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Item Cost</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      placeholder="Enter item cost"
                      disabled={!itemCategoryId}
                      type="number"
                      min={0}
                    />
                  </FormControl>
                </FormItem>
              )}
            /> */}

            <hr className="border-t" />

            <div className="">
              <h5 className="w-full text-center font-medium text-primary-600">
                HS Code
              </h5>
              <div className="flex items-center gap-3 w-full mt-2">
                <FormField
                  control={form.control}
                  name="categoryCode"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <div className="flex items-center gap-3">
                          <Input
                            value={categoryCode || "0"}
                            // disabled={!categoryId}
                            disabled
                            onChange={(e) => {
                              form.setValue("categoryCode", e.target.value);
                              field.onBlur();
                            }}
                            type="number"
                            min={0}
                            className="border-2 border-primary text-center text-xl"
                          />
                          <span className="w-2 aspect-square rounded-full bg-primary"></span>
                        </div>
                      </FormControl>
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="categoryCode"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <div className="flex items-center gap-3">
                          <Input
                            value={subCategoryCode || "0"}
                            // disabled={!subCategoryId}
                            disabled
                            onChange={(e) => {
                              form.setValue("subCategoryCode", e.target.value);
                            }}
                            type="number"
                            min={0}
                            className="border-2 border-primary text-center text-xl"
                          />
                          <span className="w-2 aspect-square rounded-full bg-primary"></span>
                        </div>
                      </FormControl>
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="categoryCode"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <div className="flex items-center gap-3">
                          <Input
                            value={itemCategoryCode || "0"}
                            // disabled={!itemCategoryId}
                            disabled
                            onChange={(e) => {
                              form.setValue("itemCategoryCode", e.target.value);
                            }}
                            type="number"
                            min={0}
                            className="border-2 border-primary text-center text-xl"
                          />
                          <span className="w-2 aspect-square rounded-full bg-primary"></span>
                        </div>
                      </FormControl>
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="categoryCode"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <div className="flex items-center gap-3">
                          <Input
                            value={itemCode || "0"}
                            disabled={!itemLabel?.length}
                            onChange={(e) => {
                              form.setValue("itemCode", e.target.value);
                            }}
                            type="number"
                            min={0}
                            className="border-2 border-primary text-center text-xl"
                          />
                        </div>
                      </FormControl>
                    </FormItem>
                  )}
                />
              </div>
            </div>
            {itemLabel && (
              <Button
                disabled={!form.formState.isValid || isActionLoading}
                type="submit"
                className="w-full"
              >
                Add Item
              </Button>
            )}
          </form>
        </Form>
      </>
    </div>
  );
}

// {
//     "categoryId": "textiles",
//     "categoryLabel": "Textiles & Fabrics",
//     "categoryCode": "02",
//     "categoryCost": {
//         "metric-tonnes": "600"
//     },

//     "subCategoryId": "natural-fibers",
//     "subCategoryLabel": "Natural Fibers",
//     "subCategoryCode": "30",
//     "subCategoryCost": {
//         "metric-tonnes": "500"
//     },

//     "itemCategoryId": "cotton",
//     "itemCategoryLabel": "Cotton",
//     "itemCategoryCode": "301",
//     "itemCategoryCost": {
//         "metric-tonnes": "600"
//     },

//     "itemId": "The-name",
//     "itemLabel": "The name",
//     "itemCode": "03",
//     "itemCost": {
//         "barrels": "695"
//     }
// }
