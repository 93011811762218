import GeneratedInvoiceTable from "@/components/tables/generatedInvoicesTable";
import InvoiceTable from "@/components/tables/InvoiceTable";
import { buttonVariants } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import React from "react";
import { Link } from "react-router-dom";

export default function InvoicesPage() {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-end w-full">
        <Link
          to={"/admin/accounting/invoicing/generate-invoice"}
          className={cn(buttonVariants({ variant: "default" }), "px-8")}
        >
          Generate Invoice
        </Link>
      </div>
      <InvoiceTable />
      <GeneratedInvoiceTable />
    </div>
  );
}
