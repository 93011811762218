import { userStoreInitialState } from "@/contexts/userStore";
import { stateLabels } from "@/lib/site-data";
import { getBackendUrl } from "@/lib/utils";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { format } from "fecha";
import { useState } from "react";

const days = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
] as const;

export const usePaymentAnalytics = () => {
  let payments_data: {
    amount: number;
    email: string;
    type: "registration" | "clearance";
    payment_date: string;
    state: string;
    account_type: userStoreInitialState["user"]["account_type"];
  }[] = [];
  const currentYear = new Date().getFullYear();

  const organizeDailyPayments = (
    payments: typeof payments_data,
    account_type: userStoreInitialState["user"]["account_type"],
    state?: string
  ) => {
    const timeSlots = [
      { tag: "00:00-05:59", trend: 0 },
      { tag: "06:00-11:59", trend: 0 },
      { tag: "12:00-17:59", trend: 0 },
      { tag: "18:00-23:59", trend: 0 },
      { tag: "24:00", trend: 0 },
    ];

    payments
      .filter((item) =>
        state
          ? item.state == state &&
            format(new Date(), "mediumDate") ==
              format(new Date(item.payment_date), "mediumDate")
          : item.account_type == account_type &&
            format(new Date(), "mediumDate") ==
              format(new Date(item.payment_date), "mediumDate")
      )
      .forEach((payment) => {
        if (!payment.payment_date) return;

        const date = new Date(payment.payment_date);
        const hours = date.getHours();

        if (hours >= 0 && hours < 6) {
          timeSlots[0].trend += payment.amount;
        } else if (hours >= 6 && hours < 12) {
          timeSlots[1].trend += payment.amount;
        } else if (hours >= 12 && hours < 18) {
          timeSlots[2].trend += payment.amount;
        } else if (hours >= 18 && hours < 24) {
          timeSlots[3].trend += payment.amount;
        } else if (hours === 24) {
          timeSlots[4].trend += payment.amount;
        }
      });

    return timeSlots;
  };
  const organizeMonthlyPayments = (
    payments: typeof payments_data,
    account_type: userStoreInitialState["user"]["account_type"],
    state?: string
  ) => {
    const monthlyTrends = Array.from({ length: 12 }, (_, i) => ({
      tag: new Date(0, i).toLocaleString("default", { month: "long" }),
      trend: 0,
    }));

    payments
      .filter((item) =>
        state ? item.state == state : item.account_type == account_type
      )
      .forEach((payment) => {
        if (!payment.payment_date) return;
        const date = new Date(payment.payment_date);
        const year = date.getFullYear();
        const monthIndex = date.getMonth();

        if (year === currentYear) {
          monthlyTrends[monthIndex].trend += payment.amount;
        }
      });

    return monthlyTrends;
  };

  const organizeYearlyPayments = (
    payments: typeof payments_data,
    account_type: userStoreInitialState["user"]["account_type"],
    state?: string
  ) => {
    const yearlyTrends: { [key: string]: number } = {};

    payments
      .filter((item) =>
        state ? item.state == state : item.account_type == account_type
      )
      .forEach((payment) => {
        if (!payment.payment_date) return;

        const date = new Date(payment.payment_date);
        const year = date.getFullYear();

        if (!yearlyTrends[year]) {
          yearlyTrends[year] = 0;
        }
        yearlyTrends[year] += Number(payment.amount);
      });

    const trendsFormatted = Object.keys(yearlyTrends).map((year) => ({
      tag: year,
      trend: yearlyTrends[year],
    }));
    return trendsFormatted.length > 0
      ? trendsFormatted
      : [
          {
            tag: `${new Date().getFullYear()}`,
            trend: 0,
          },
        ];
  };

  const getTotalRevenuePerState = (payments: typeof payments_data) => {
    const states = stateLabels;
    const revenuePerState: {
      [year: string]: {
        [month: string]: {
          [state: string]: number;
        };
      };
    } = {
      [currentYear]: Object.fromEntries(
        Array.from({ length: 12 }, (_, i: number) => [
          new Date(0, i).toLocaleString("default", { month: "long" }),
          Object.fromEntries(states.map((state) => [state.toLowerCase(), 0])),
        ])
      ),
    };

    payments.forEach((payment) => {
      if (!payment.state || !payment.payment_date) return;

      const normalizedState = payment.state.toLowerCase();
      const date = new Date(payment.payment_date);
      const year = date.getFullYear();
      const month = new Date(0, date.getMonth()).toLocaleString("default", {
        month: "long",
      });

      if (!revenuePerState[year]) {
        revenuePerState[year] = {};
        // Initialize all months for the year
        Array.from({ length: 12 }, (_, i) =>
          new Date(0, i).toLocaleString("default", { month: "long" })
        ).forEach((monthName) => {
          revenuePerState[year][monthName] = {};
          // Initialize all states for each month
          states.forEach((state) => {
            revenuePerState[year][monthName][state.toLowerCase()] = 0;
          });
        });
      }

      if (!revenuePerState[year][month]) {
        revenuePerState[year][month] = {};
        // Initialize all states for the month
        states.forEach((state) => {
          revenuePerState[year][month][state.toLowerCase()] = 0;
        });
      }

      if (!revenuePerState[year][month][normalizedState]) {
        revenuePerState[year][month][normalizedState] = 0;
      }

      revenuePerState[year][month][normalizedState] += payment.amount;
    });

    return revenuePerState;
  };

  const { data: all_payments, isLoading } = useQuery({
    queryKey: ["payment-analytics"],
    queryFn: async () => {
      const { data } = await axios.get(`${getBackendUrl()}/all-payment`);
      payments_data = [];

      data?.allagent.map((agent_item: any) => {
        payments_data.push({
          amount: Number(agent_item.amount),
          email: agent_item.email,
          type: "clearance",
          account_type: "agent",
          state: agent_item.state,
          payment_date: agent_item.payment_date,
        });
      });
      data?.alluser.map((importer_item: any) => {
        payments_data.push({
          amount: Number(importer_item.amount),
          email: importer_item.email,
          type: "clearance",
          payment_date: importer_item.payment_date,
          state: importer_item.state,
          account_type: importer_item.importer_category,
        });
      });

      // data?.allClearance.map((importer_item: any) => {
      //   payments_data.push({
      //     amount: Number(importer_item.new_amount || importer_item.amount),
      //     email: importer_item.email,
      //     type: "clearance",
      //     payment_date: importer_item.payment_date,
      //     state: importer_item.state,
      //     account_type: importer_item.importer_category,
      //   });
      // });

      // Aggregate data for monthly and yearly trends
      // const monthlyTrends = Array.from({ length: 12 }, (_, i) => ({
      //   month: new Date(0, i).toLocaleString("default", { month: "long" }),
      //   trend: 0,
      // }));
      const monthlyTrends: {
        [key: string]: { month: string; trend: number }[];
      } = {
        [currentYear]: Array.from({ length: 12 }, (_, i) => ({
          month: new Date(0, i).toLocaleString("default", { month: "long" }),
          trend: 0,
        })),
      };

      const yearlyTrends: { [key: string]: number } = {
        [currentYear]: 0,
      };

      payments_data.forEach((payment) => {
        if (!payment.payment_date) {
          return;
        }

        const date = new Date(payment.payment_date);
        const monthIndex = date.getMonth();
        const year = date.getFullYear();
        const monthName = new Date(0, monthIndex).toLocaleString("default", {
          month: "long",
        });

        // Update monthly trends
        if (!monthlyTrends[year]) {
          monthlyTrends[year] = Array.from({ length: 12 }, (_, i) => ({
            month: new Date(0, i).toLocaleString("default", { month: "long" }),
            trend: 0,
          }));
        }
        monthlyTrends[year][monthIndex].trend += payment.amount;

        // Update yearly trends
        if (!yearlyTrends[year]) {
          yearlyTrends[year] = 0;
        }
        yearlyTrends[year] += payment.amount;
      });

      const yearlyTrendsData = Object.keys(yearlyTrends).map((year) => ({
        year,
        trend: yearlyTrends[year],
      }));

      return {
        monthlyTrends,
        yearlyTrendsData,
        payments_data,
        totalRevenuePerState: getTotalRevenuePerState(payments_data),
      };
    },
  });

  if (all_payments) {
    // Use all_payments.monthlyTrends and all_payments.yearlyTrendsData as needed
    // console.log(all_payments.monthlyTrends);
    return {
      charts: {
        all: {
          monthly: all_payments.monthlyTrends,
          yearly: all_payments.yearlyTrendsData,
          state: all_payments.totalRevenuePerState,
        },
        agent: {
          daily: organizeDailyPayments(all_payments.payments_data, "agent"),
          monthly: organizeMonthlyPayments(all_payments.payments_data, "agent"),
          yearly: organizeYearlyPayments(all_payments.payments_data, "agent"),
        },
        company: {
          daily: organizeDailyPayments(all_payments.payments_data, "company"),
          monthly: organizeMonthlyPayments(
            all_payments.payments_data,
            "company"
          ),
          yearly: organizeYearlyPayments(all_payments.payments_data, "company"),
        },
        government: {
          daily: organizeDailyPayments(
            all_payments.payments_data,
            "government"
          ),
          monthly: organizeMonthlyPayments(
            all_payments.payments_data,
            "government"
          ),
          yearly: organizeYearlyPayments(
            all_payments.payments_data,
            "government"
          ),
        },
        individual: {
          daily: organizeDailyPayments(
            all_payments.payments_data,
            "individual"
          ),
          monthly: organizeMonthlyPayments(
            all_payments.payments_data,
            "individual"
          ),
          yearly: organizeYearlyPayments(
            all_payments.payments_data,
            "individual"
          ),
        },
      },
      metrics: {
        total: {
          agent: all_payments.payments_data
            .filter((item) => item.account_type == "agent")
            .reduce((acc, item) => acc + item.amount, 0),
          company: all_payments.payments_data
            .filter((item) => item.account_type == "company")
            .reduce((acc, item) => acc + item.amount, 0),
          government: all_payments.payments_data
            .filter((item) => item.account_type == "government")
            .reduce((acc, item) => acc + item.amount, 0),
          individual: all_payments.payments_data
            .filter((item) => item.account_type == "individual")
            .reduce((acc, item) => acc + item.amount, 0),
        },
      },
      utils: {
        isLoading,
      },
    };
  }

  return {
    charts: null,
    utils: {
      isLoading,
    },
  };
};
