import { Search } from "lucide-react";
import React from "react";
import { Input } from "./input";
import Notifications from "./Notifications";

export default function DashboardHeader({
  title,
  caption,
}: {
  title: string;
  caption: string;
}) {
  return (
    <div className="w-full bg-secondary-50 p-6 flex items-center justify-between mb-5">
      <div className="">
        <h1 className="text-2xl text-primary-700 font-medium">{title}</h1>
        <p className="text-primary-300">{caption}</p>
      </div>
      <div className="flex items-center gap-5">
        <div className="w-[300px] h-11 bg-primary-50 text-primary-300 px-5 py-2 flex items-center rounded-[16px]">
          <Search size={20} />
          <Input
            className="bg-transparent placeholder:text-primary-200 border-none outline-none"
            placeholder="search"
          />
        </div>
        <div className="text-primary">
          <Notifications />
        </div>
      </div>
    </div>
  );
}
