import { ChartConfig, ChartTooltipContent } from "@/components/ui/chart";
import { ChartContainer } from "@/components/ui/chart";
import { ChartTooltip } from "@/components/ui/chart";
import ExportComponentAsPDF from "@/components/ui/export-component-as-pdf";
import LoaderWrapper from "@/components/ui/loader-wrapper";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useWaiverAnalytics } from "@/hooks/analytics/useWaiverAnalytics";
import { cn, getPercentage } from "@/lib/utils";
import React from "react";
import { Pie, PieChart } from "recharts";

const filters = [
  {
    title: "Daily",
    value: "daily",
  },
  {
    title: "Weekly",
    value: "weekly",
  },
  {
    title: "Monthly",
    value: "monthly",
  },
  {
    title: "Quarterly",
    value: "quarterly",
  },
  {
    title: "Annually",
    value: "annually",
  },
];

const chartConfig = {
  "waivers-rejected": {
    label: "Waivers Rejected",
  },
  "waivers-granted": {
    label: "Waivers Granted",
  },
} satisfies ChartConfig;

export default function WaiverRecordsChart() {
  const { data, isLoading } = useWaiverAnalytics();
  const componentRef = React.useRef<HTMLDivElement>(null);
  const chartData = [
    {
      category: "waivers-rejected",
      value: data?.totalRejectedWaivers || 0,
      fill: "#50A7B9",
    },
    {
      category: "waivers-granted",
      value: data?.totalIssuedWaivers || 0,
      fill: "#005C00",
    },
  ];
  return (
    <LoaderWrapper isLoading={isLoading} page={false}>
      <div className="w-full p-5 flex flex-col gap-5 shadow-[0_4px_20px_0px_#0000001A] rounded-xl">
        <div className="flex justify-between items-center">
          <h4 className="font-semibold text-primary-700 flex">
            Waiver Records
          </h4>
          <ExportComponentAsPDF componentRef={componentRef} />
          {/* <Select>
            <SelectTrigger className="w-fit">
              <div className="flex items-center gap-2 text-primary-700 font-semibold">
                <svg
                  width="18"
                  height="12"
                  viewBox="0 0 18 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4 6H14M1.5 1H16.5M6.5 11H11.5"
                    stroke="currentColor"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <SelectValue placeholder="Filters" />
              </div>
            </SelectTrigger>
            <SelectContent>
              {filters.map((filter) => (
                <SelectItem key={filter.value} value={filter.value}>
                  {filter.title}
                </SelectItem>
              ))}
            </SelectContent>
          </Select> */}
        </div>
        <div ref={componentRef} className="w-full grid grid-cols-2 gap-5 pb-5">
          <div className="">
            <ChartContainer
              config={chartConfig}
              className="mx-auto aspect-square max-h-[450px]"
            >
              <PieChart>
                <ChartTooltip content={<ChartTooltipContent />} />
                <Pie data={chartData} dataKey="value" nameKey="category" />
              </PieChart>
            </ChartContainer>
            <div className="flex flex-col gap-2 items-center w-full max-w-xs mx-auto">
              {[...chartData]
                .sort((a, b) => b.value - a.value)
                .map((data) => (
                  <div
                    key={`${data.category} ${data.value}`}
                    className="flex items-center gap-2 w-full justify-between"
                  >
                    <div className="flex items-center gap-2">
                      <div
                        className="w-[15px] aspect-square rounded-full"
                        style={{ backgroundColor: data.fill }}
                      ></div>
                      <p className="capitalize">
                        {data.category.split("-").join(" ")}
                      </p>
                    </div>
                    <p>
                      {isNaN(
                        Number(
                          getPercentage(
                            data.value,
                            chartData
                              .map((item) => item.value)
                              .reduce((prev, val) => val + prev)
                          )
                        )
                      )
                        ? 0
                        : getPercentage(
                            data.value,
                            chartData
                              .map((item) => item.value)
                              .reduce((prev, val) => val + prev)
                          )}
                      %
                    </p>
                  </div>
                ))}
            </div>
          </div>
          <div className="flex">
            <div className="flex flex-wrap gap-5 items-center justify-center my-auto h-fit">
              <div
                className={cn(
                  "flex flex-col gap-3 p-5 rounded-xl flex-1 min-w-[200px] max-w-[250px] h-fit justify-between bg-primary-50 text-primary-700"
                )}
              >
                <p className={cn("text-xs ")}>Total Waiver Value</p>
                <h4 className="font-bold text-2xl">
                  ₦{Number(data?.totalWaiverValue).toLocaleString()}
                </h4>
              </div>
              <div
                className={cn(
                  "flex flex-col gap-3 p-5 rounded-xl flex-1 min-w-[200px] max-w-[250px] h-fit justify-between bg-primary-50 text-primary-700"
                )}
              >
                <p className={cn("text-xs ")}>Total Number of Issued Waivers</p>
                <h4 className="font-bold text-2xl">
                  {Number(data?.totalIssuedWaivers).toLocaleString()}
                </h4>
              </div>
              <div
                className={cn(
                  "flex flex-col gap-3 p-5 rounded-xl flex-1 min-w-[200px] max-w-[250px] h-fit justify-between bg-primary-700 text-primary-foreground"
                )}
              >
                <p className={cn("text-xs ")}>
                  Total Number of Rejected Waivers
                </p>
                <h4 className="font-bold text-2xl">
                  {Number(data?.totalRejectedWaivers).toLocaleString()}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LoaderWrapper>
  );
}

const RenderChart = ({ data }: { data: any }) => {};
