import React from "react";
import { DataTable } from "./data-table";
import { ClearancePaymentsColumns } from "./columns";
import { useStore } from "@/contexts/agentStore";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { getBackendUrl } from "@/lib/utils";
import LoaderWrapper from "@/components/ui/loader-wrapper";
import { FilterData } from "@/components/ui/filter";
import { registrationPaymentsHistoryData } from "@/lib/dummy-data/registrationPayments";
import { RegistrationPaymentHistoryData } from "@/lib/interfaces";

interface ClearancePayment {
  id: number;
  clearance_id: string;
  nesrea_id: string;
  importer_email: string;
  importer_name: string;
  importer_category: string;
  item: string;
  document_url: string;
  document_name: string;
  amount: string;
  new_amount: string | null;
  reference: string | null;
  payment_id: string | null;
  payment_status: string | null;
  payment_date: string | null;
  clearance_status: string;
  status: string;
  waiver_usage_status: string | null;
  created_at: string;
  action_taken_clearance_application: string | null;
  clearance_approved_by: string | null;
  clearance_approval_date: string | null;
  clearance_approval_comment: string | null;
  action_taken_payment: string | null;
  clearance_payment_approved_by: string | null;
  clearance_payment_approval_date: string | null;
  clearance_payment_approval_comment: string | null;
  activities: string;
  created_by: string;
  updated_at: string;
  notification: string | null;
  notificaton_status: string | null;
}

export default function ClearancePaymentsTable() {
  const agentStore = useStore((state) => state);
  const { data, isLoading } = useQuery({
    queryKey: ["all-clearance-payments"],
    queryFn: async () => {
      try {
        const { data }: { data?: { allClearance: ClearancePayment[] } } =
          await axios.get(`${getBackendUrl()}/all-clearance-payments`);
        let payment_details: RegistrationPaymentHistoryData = [];

        data?.allClearance.map((item: any) => {
          payment_details.push({
            id: item.clearance_id,
            name: item.importer_name,
            amount: item.amount || "0",
            category: item.importer_category,
            paymentID: item.reference,
            paymentDate: item.payment_date || new Date().toISOString(),
            nesreaID: item.nesrea_id,
            status: item.payment_status,
          });
        });

        return payment_details;
      } catch (error) {}
    },
  });

  const [data_, setData_] = React.useState<FilterData>([]);
  const [filteredData, setFilteredData] = React.useState<FilterData>([]);
  const filteredIDs = filteredData.map((d) => d.id);

  const updatedData = React.useMemo(() => {
    return data?.filter((stat) => filteredIDs.includes(`${stat.id}`)) || [];
  }, [data, filteredIDs]);

  React.useEffect(() => {
    const newData = data?.map((item) => ({
      id: `${item.id}`,
      name: item.name,
      value: Number(item.amount || "0"),
      payment_id: item.paymentID,
      category: item.category,
      date: item.paymentDate,
      status: item.status,
    }));
    setData_(newData || []);
    if (!filteredData.length) {
      setFilteredData(newData || []);
    }
  }, [data]);
  return (
    <LoaderWrapper isLoading={isLoading}>
      <DataTable
        columns={ClearancePaymentsColumns}
        setData_={setData_}
        filteredData={filteredData}
        setFilteredData={setFilteredData}
        data_={data_}
        data={updatedData || []}
      />
    </LoaderWrapper>
  );
}
