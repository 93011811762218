import React from "react";
import { DataTable } from "./data-table";
import { RolesPermissionsColumns } from "./columns";
import { useStore } from "@/contexts/userStore";
import { useAdminAnalytics } from "@/hooks/analytics/useAdminAnalytics";
import LoaderWrapper from "@/components/ui/loader-wrapper";

export default function RolesPermissionsTable() {
  const { adminsData, isLoading } = useAdminAnalytics();
  return (
    <LoaderWrapper isLoading={isLoading}>
      <DataTable columns={RolesPermissionsColumns} data={adminsData || []} />
    </LoaderWrapper>
  );
}
