import { rootNavigationRoutes } from "@/lib/site-data";
import { cn } from "@/lib/utils";
import { ChevronRight, Menu } from "lucide-react";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Button, buttonVariants } from "./button";
import { HashLink } from "react-router-hash-link";

export default function Navbar() {
  const [showNav, setShowNav] = useState(false);
  const [scrollY, setScrollY] = useState(0);
  const isSticky = scrollY > 250;

  useEffect(() => {
    window.addEventListener("scroll", () => setScrollY(window.scrollY));
  });
  return (
    <div className="w-full min-h-[10vh] p-5">
      <a
        href="#root"
        className={cn(
          buttonVariants({ variant: "outline" }),
          "fixed -bottom-10 z-[999999999999999999999999] rounded-full aspect-square border-2 p-0 right-5 border-white text-white duration-500",
          isSticky && "border-primary-700 text-primary-700 bottom-10 bg-white"
        )}
      >
        <svg
          width="12"
          height="20"
          viewBox="0 0 12 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.26833 2.75172V2.36747L4.98535 2.62741L1.81774 5.53717C1.81757 5.53732 1.8174 5.53748 1.81723 5.53763C1.68446 5.65728 1.50187 5.72682 1.30861 5.72682C1.11528 5.72682 0.932638 5.65724 0.799862 5.53751C0.668665 5.41744 0.59848 5.25874 0.59848 5.09713C0.59848 4.93537 0.668794 4.77654 0.800221 4.65643L0.800328 4.65633L4.23323 1.51358C4.23333 1.51349 4.23342 1.5134 4.23352 1.51332C4.69737 1.09259 5.32923 0.853851 5.991 0.853851C6.65282 0.853851 7.28472 1.09261 7.74857 1.51339C7.74864 1.51345 7.74871 1.51351 7.74877 1.51358L11.1994 4.65643C11.1995 4.65645 11.1995 4.65646 11.1995 4.65648C11.3309 4.77659 11.4012 4.9354 11.4012 5.09713C11.4012 5.25889 11.3309 5.41773 11.1994 5.53784L11.1993 5.53797C11.1336 5.59816 11.0545 5.64671 10.9663 5.68009C10.8781 5.71348 10.783 5.73083 10.6866 5.73083C10.5903 5.73083 10.4952 5.71348 10.4069 5.68009C10.3187 5.64671 10.2397 5.59816 10.174 5.53797L10.1735 5.5375L6.97831 2.6348L6.69602 2.37835V2.75974V19.1255C6.69602 19.2879 6.62516 19.4473 6.4928 19.5676C6.35989 19.6883 6.17645 19.7586 5.98218 19.7586C5.7879 19.7586 5.60446 19.6883 5.47156 19.5676L5.35806 19.6925L5.47156 19.5676C5.33919 19.4473 5.26833 19.2879 5.26833 19.1255V2.75172Z"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="0.337584"
          />
        </svg>
      </a>
      <div
        className={cn(
          "w-full max-w-7xl h-[10vh] mx-auto flex gap-5 rounded-xl items-center justify-between p-5 z-10 relative bg-background",
          isSticky &&
            "animate-slide-down fixed top-0 right-0 z-[9999] max-w-full px-20 rounded-none bg-background/80 backdrop-blur-lg shadow-md"
        )}
      >
        <Link to={"/"} className="w-fit my-2">
          <img src="/images/Logo.png" width={100} alt="" />
        </Link>
        <div className="md:flex items-center hidden w-fit">
          <div className="w-fit flex items-center gap-[2.25vw]">
            {rootNavigationRoutes.map((route) => (
              <NavItem key={route.href} {...route} />
            ))}
          </div>
        </div>
        <a
          href={"/verify-certificate"}
          className={cn(
            buttonVariants({ variant: "outline" }),
            "rounded-full border-2"
          )}
        >
          Verify Certificate
        </a>
        <Menu
          className={cn(
            "cursor-pointer md:hidden flex ml-auto dark:text-white relative z-[9999999] duration-500",
            showNav && "text-primary"
          )}
          onClick={() => setShowNav(!showNav)}
        />
        <div
          className={cn(
            "w-full absolute top-0 right-0 bg-primary origin-top z-[900] scale-0 duration-300 h-screen translate-x-[100%]",
            showNav && "translate-x-0 scale-100"
          )}
        ></div>
        <div
          className={cn(
            "pt-[10vh] w-full flex justify-end bg-popover z-[999999] origin-top fixed duration-700  px-2 pb-2 top-0 right-0 h-screen translate-x-[100%]",
            showNav && "translate-x-0"
          )}
        >
          <div className=" w-full h-full flex flex-col gap-7 px-2">
            {rootNavigationRoutes.map(({ href, txt }) => (
              <div key={href} className="flex gap-3 items-center">
                <ChevronRight />
                <NavItem setShow={setShowNav} href={href} txt={txt} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

const NavItem = ({
  href,
  txt,
  setShow,
}: {
  txt: string;
  href: string;
  setShow?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { pathname } = useLocation();
  const isActive = href == pathname;
  return (
    <HashLink
      to={href}
      onClick={setShow ? () => setShow(false) : () => {}}
      className={cn(
        "p-2 text-[#787878]",
        isActive && "text-[#005C00] font-semibold"
      )}
    >
      {txt}
    </HashLink>
  );
};
