import { Button, buttonVariants } from "@/components/ui/button";
import { UserType, userTypes } from "@/lib/site-data";
import { cn } from "@/lib/utils";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import RegForm from "./RegForm";
import OTP from "./OTP";
import { Mail } from "lucide-react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

export type formStages = "register" | "otp" | "loading";

export default function SignupForm() {
  const [activeUserType, setActiveUserType] = useState<UserType | "">("");
  const [formStage, setFormStage] = useState<formStages>("register");

  const FormSchema = z
    .object({
      email: z.string().min(2, {
        message: "Username must be at least 2 characters.",
      }),
      password: z
        .string()
        .min(8, {
          message: "Password must be at least 8 characters.",
        })
        .regex(/[a-z]/, {
          message: "Password must contain at least one lowercase letter.",
        })
        .regex(/[A-Z]/, {
          message: "Password must contain at least one uppercase letter.",
        })
        .regex(/[0-9]/, {
          message: "Password must contain at least one number.",
        })
        .regex(/[^a-zA-Z0-9]/, {
          message: "Password must contain at least one special character.",
        }),
    })
    .refine(
      (data) => {
        const isGov = activeUserType == "government";
        if (isGov) {
          return data.email.includes(".gov.ng");
        }

        return true;
      },
      { message: "Invalid email for government account type", path: ["email"] }
    );

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const passwordErrorMessage = form.formState.errors?.password?.message || "";

  // useEffect(() => {
  //   const fetchRegStage = async () => {
  //     const stage = localStorage.getItem("nesrea_user_form_stage");
  //     if (stage) {
  //       setFormStage(stage as formStages);
  //     }
  //   };

  //   fetchRegStage();
  // }, []);

  return (
    <div className="w-full max-w-lg m-auto p-12 flex flex-col items-center gap-4 text-center">
      <img src="/images/Logo.png" className="w-[200px]" alt="" />
      {formStage == "register" && (
        <>
          <div className="space-y-2">
            <h5 className="text-sm text-neutral-600 font-light">
              Welcome back
            </h5>
            <h1 className="font-semibold text-xl">Choose your account Type</h1>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
            {userTypes.map((item: UserType) => {
              const isActive = activeUserType == item;
              return (
                <Button
                  key={item}
                  onClick={() => {
                    setActiveUserType(item);
                    form.reset();
                  }}
                  variant={"outline"}
                  className={cn(
                    "capitalize",
                    isActive &&
                      "bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground"
                  )}
                >
                  {item}
                </Button>
              );
            })}
          </div>

          {activeUserType == "" ? (
            <></>
          ) : (
            <>
              <RegForm
                setFormStage={setFormStage}
                activeUserType={activeUserType}
                form={form}
                passwordErrorMessage={passwordErrorMessage}
                formSchema={FormSchema}
              />
              {/* <p className="text-neutral-400 text-sm">
                Already have an account?{" "}
                <Link className="underline text-primary" to={"/user/sign-in"}>
                  Log in
                </Link>
              </p> */}
            </>
          )}
          <p className="text-neutral-400 text-sm">
            Already have an account?{" "}
            <Link className="underline text-primary" to={"/user/sign-in"}>
              Log in
            </Link>
          </p>
        </>
      )}
      {formStage == "otp" && (
        <>
          <h1>Verify Email to Proceed</h1>
          <hr className="w-full border border-neutral-200" />
          <OTP
            email_={
              form.getValues("email").length > 0
                ? form.getValues("email")
                : undefined
            }
            hasSent_={true}
          />
        </>
      )}
    </div>
  );
}
