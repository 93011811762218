import React, { useEffect } from "react";
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import Sidebar from "./Sidebar";
import DashboardHeader from "./components/DashboardHeader";
import { ScrollArea } from "@/components/ui/scroll-area";
import { userStoreInitialState, useStore } from "@/contexts/userStore";
import { useStore as useAgentStore } from "@/contexts/agentStore";
import DashboardAlert from "@/components/alerts/DashboardAlert";
import { clientsData } from "@/lib/dummy-data/clientsData";
import { applications } from "@/lib/dummy-data/applicationsData";
import UserSwitch from "@/components/ui/UserSwitch";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { generateBreadcrumbs, getBackendUrl } from "@/lib/utils";
import { isLoggedIn } from "@/lib/auth";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export const DashboardLayout = () => {
  const userStore = useStore((state) => state);
  const agentStore = useAgentStore((state) => state);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  let isAllowed = userStore.user.profile_updated || pathname == "/user/profile";
  let isAdmin = userStore.user.account_type == "admin";
  const breadCrumbs = generateBreadcrumbs(pathname);

  const isAgent = userStore.user.account_type == "agent";

  const { data, isLoading } = useQuery({
    queryKey: ["user-approved"],
    queryFn: async () => {
      const { data }: { data: { success: string; status: string } } =
        await axios.get(
          `${getBackendUrl()}/get-account-status/${userStore.user.email}`
        );

      if (data?.status !== "Not approved") {
        const userCacheString = localStorage.getItem("nesrea_user");
        if (!userCacheString) {
          return { status: "", success: "" };
        }
        const userCache = JSON.parse(userCacheString);
        userCache.registration_status = "verified";
        localStorage.setItem("nesrea_user", JSON.stringify(userCache));
        userStore.setUser(userCache);
      }

      return data;
    },
  });

  const { data: importerData } = isAgent
    ? useQuery({
        queryKey: ["user-importer-info"],
        queryFn: async () => {
          const { data }: { data: any } = await axios.get(
            `${getBackendUrl()}/get-agent-info/${userStore.user.email}`
          );

          // console.log("DATA", data);

          const userCacheString = localStorage.getItem("nesrea_user");

          if (userCacheString) {
            const userCache = JSON.parse(userCacheString);
            const Importer_info = data?.Agent_info;
            const newData: userStoreInitialState["user"] = {
              ...userCache,
              phone_number: Importer_info?.phone_number || "",
              state: Importer_info?.state || "",
              association: Importer_info?.association || "",
              name: Importer_info?.agent_name || "",
              created_at: Importer_info?.created_at || "",
              updated_at: Importer_info?.updated_at || "",
              registration_payment_date: Importer_info?.payment_date || "",
              street: Importer_info?.street_address || "",
              nin: Importer_info?.national_identity_number || "",
              nesrea_id:Importer_info?.agent_id || "",
            };

            console.log(newData);

            localStorage.setItem("nesrea_user", JSON.stringify(newData));
            userStore.setUser(newData);
          }

          return data;
        },
      })
    : useQuery({
        queryKey: ["importer-info"],
        queryFn: async () => {
          // TODO: [AGENT] UPDATE PROFILE

          const { data }: { data: any } = await axios.get(
            `${getBackendUrl()}/get-importer-info/${userStore.user.email}`
          );

          const userCacheString = localStorage.getItem("nesrea_user");

          if (userCacheString) {
            const userCache = JSON.parse(userCacheString);
            const Importer_info = data?.Importer_info;
            const newData: userStoreInitialState["user"] = {
              ...userCache,
              phone_number: Importer_info?.phone_number || "",
              state: Importer_info?.state || "",
              association: Importer_info?.association || "",
              name: Importer_info?.importer_name || "",
              created_at: Importer_info?.created_at || "",
              updated_at: Importer_info?.updated_at || "",
              registration_payment_date: Importer_info?.payment_date || "",
              street: Importer_info?.street_address || "",
              nin: Importer_info?.tin_nin_cac || "",
              nesrea_id:Importer_info?.nesrea_id || "",
            };

            localStorage.setItem("nesrea_user", JSON.stringify(newData));
            userStore.setUser(newData);
          }

          return data;
        },
      });

  useEffect(() => {
    if (isAdmin) {
      navigate("/admin/dashboard");
      return;
    }
  }, [userStore.user.account_type]);

  return (
    <ScrollArea className="h-screen">
      <div className="flex w-full relative">
        <div className="flex-[0.25] min-w-xs h-screen sticky top-0 left-0">
          <Sidebar />
        </div>
        <div className="px-12 py-7 flex-1 relative overflow-hidden">
          <div
            className="absolute top-0 right-0 bg-[70%_] bg-center bg-no-repeat w-full h-full opacity-5"
            style={{ backgroundImage: `url('/images/Logo.png')` }}
          ></div>
          <div className="w-full h-full z-10 relative flex flex-col gap-5">
            <DashboardHeader />
            <Breadcrumb>
              <BreadcrumbList>
                {breadCrumbs.slice(1).map((crumb, index) =>
                  index + 2 != breadCrumbs.length ? (
                    <React.Fragment key={crumb.href}>
                      <Link
                        to={crumb.href}
                        className="text-black/50 font-light capitalize"
                      >
                        {crumb.label}
                      </Link>
                      <BreadcrumbSeparator className="text-black/50" />
                    </React.Fragment>
                  ) : (
                    <BreadcrumbItem key={crumb.href}>
                      <BreadcrumbPage className="text-primary-500 font-medium">
                        {crumb.label}
                      </BreadcrumbPage>
                    </BreadcrumbItem>
                  )
                )}
              </BreadcrumbList>
            </Breadcrumb>

            {!isAllowed ? (
              <div className="flex items-center justify-center w-full h-[70vh]">
                <DashboardAlert
                  title="You Need to Update Your Profile"
                  cta={{ txt: "Go To Profile", href: "/user/profile" }}
                  description="Keep your profile current with accurate information for a seamless experience and better service."
                />
              </div>
            ) : (
              <>
                <Outlet />
              </>
            )}
          </div>
        </div>
        {/* <UserSwitch /> */}
      </div>
    </ScrollArea>
  );
};
