import React from "react";
import { DataTable } from "./data-table";
import { WaiverHistoryColumns } from "./columns";
import { useStore } from "@/contexts/agentStore";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { getBackendUrl } from "@/lib/utils";
import { RegistrationPaymentHistoryData } from "@/lib/interfaces";
import { FilterData } from "@/components/ui/filter";

interface WaiverRequest {
  id: number;
  waiver_id: string;
  clearance_id: string;
  nesrea_id: string;
  importer_name: string;
  importer_email: string;
  importer_category: string;
  amount: number | null;
  item: string;
  waiver_percentage: string;
  waiver_request_comment: string;
  waiver_application_letter: string;
  document_name: string;
  document_url: string;
  waiver_code: string;
  validity_period: string;
  status: string;
  approved_percentage: string;
  new_amount: string;
  action_taken_waiver: string;
  waiver_approved_by: string;
  waiver_approval_comment: string;
  waiver_approval_date: string;
  activities: string;
  created_by: string;
  updated_at: string;
  created_at: string;
}

export default function WaiverHistoryTable() {
  const agentStore = useStore((state) => state);

  const { data, isLoading } = useQuery<RegistrationPaymentHistoryData>({
    queryKey: ["waiver-history-table"],
    queryFn: async () => {
      const { data }: { data: { allWaiverrequest: WaiverRequest[] } } =
        await axios.get(`${getBackendUrl()}/all-waiver-application`);
      const records: RegistrationPaymentHistoryData = data.allWaiverrequest.map(
        (item) => ({
          id: item.id.toString(),
          name: item.importer_name,
          amount: item?.new_amount?.toString() || "",
          paymentID: item.waiver_id,
          paymentDate: item.waiver_approval_date,
          category: item.importer_category,
          nesreaID: item.nesrea_id,
          status: item.status?.toLowerCase(),
        })
      );
      return records;
    },
  });

  const [data_, setData_] = React.useState<FilterData>([]);
  const [filteredData, setFilteredData] = React.useState<FilterData>([]);
  const filteredIDs = filteredData.map((d) => d.id);

  const updatedData = React.useMemo(() => {
    return data?.filter((stat) => filteredIDs.includes(stat.id)) || [];
  }, [data, filteredIDs]);

  React.useEffect(() => {
    const newData = data?.map((item) => ({
      id: `${item.id}`,
      name: item.name,
      value: Number(item.amount || "0"),
      payment_id: item.paymentID,
      category: item.category,
      date: item.paymentDate,
      status: item.status,
    }));
    setData_(newData || []);
    if (!filteredData.length) {
      setFilteredData(newData || []);
    }
  }, [data]);
  return (
    <div>
      <DataTable
        columns={WaiverHistoryColumns}
        setData_={setData_}
        filteredData={filteredData}
        setFilteredData={setFilteredData}
        data_={data_}
        data={updatedData || []}
      />
    </div>
  );
}
