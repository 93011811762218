import React from "react";
import RegistrationPricingTable from "./_components/RegistrationPricingTable";

export default function PricingPage() {
  return (
    <div>
      <RegistrationPricingTable />
    </div>
  );
}
