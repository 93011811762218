import React from "react";

export default function Regulations() {
  return (
    <div
      id="services"
      className="py-20 w-full max-w-5xl mx-auto flex flex-col items-center text-center gap-14"
    >
      <h1 className="text-5xl font-bold">Our Regulations</h1>
      <div className="w-full max-w-7xl grid md:grid-cols-3 gap-16 lg:gap-20">
        <div className=" flex flex-col items-center text-center gap-4">
          <img src="/images/regulations/promote.svg" className="w-fit" alt="" />
          <h5>Promote</h5>
          <p className="font-light text-lg max-w-xs">
            Promoting practices that safeguards the environment for future
            generations
          </p>
        </div>
        <div className=" flex flex-col items-center text-center gap-4">
          <img src="/images/regulations/monitor.svg" className="w-fit" alt="" />
          <h5>Monitor</h5>
          <p className="font-light text-lg max-w-xs">
            Environmental monitoring, ensuring compliance with environmental
            standards standard
          </p>
        </div>
        <div className=" flex flex-col items-center text-center gap-4">
          <img src="/images/regulations/enforce.svg" className="w-fit" alt="" />
          <h5>Enforce</h5>
          <p className="font-light text-lg max-w-xs">
            Enforce environmental laws across sectors
          </p>
        </div>
      </div>
    </div>
  );
}
