import React from "react";
import ImportClearanceApplicationsReport from "../../clearance/_components/ImportClearanceApplicationsReport";
import ImportClearanceApplicationsByItemCategory from "../../clearance/_components/ClearancesByItemCategory";
import ImportClearanceApplicationsbByTopItemCategoryReport from "../../clearance/_components/ClearancesByTopItemCategory";
import ImportClearanceApplicationsByUserCategoryReport from "../../clearance/_components/ClearanceByUserCategory";

export default function ClearanceAnalyticsPage() {
  return (
    <div className="flex flex-col gap-10">
      <ImportClearanceApplicationsReport />
      <ImportClearanceApplicationsByItemCategory />
      <ImportClearanceApplicationsbByTopItemCategoryReport />
      <ImportClearanceApplicationsByUserCategoryReport />
    </div>
  );
}
